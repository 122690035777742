import { cleanObject } from 'helper';
import { apiWms } from './index';

function getCompanyInventory({ current = 1, pageSize = 10, sortField = 'id', sortOrder = 'ASC' }, filter) {
	filter.searchFilter = {
		searchOptionType: filter.searchOptionType,
		searchValue: filter.searchValue
	};
	delete filter.searchOptionType;
	delete filter.searchValue;
	return apiWms.post('/inventory/fetch', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
}

function getInventoryDetail(inventoryId) {
	return apiWms.get(`/inventory/trail/${inventoryId}`);
}

function updateInventoryStock({ quantity, remark }, inventoryId) {
	return apiWms.post(`/warehouse/add-items`, {
		quantity,
		inventoryId,
		reason: remark
	});
}

function getWarehouseInventory({ current = 1, pageSize = 10, sortField = 'id', sortOrder = 'ASC' }, filter, companyIds) {
	return apiWms.post('/warehouse/inventory', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		},
		companyIds
	});
}

function addInventory({ inboundAccepted, inboundRejected, remark, inventoryId }) {
	return apiWms.post(`/inventory/add/${inventoryId}`, {
		inboundAccepted,
		inboundRejected,
		remark
	});
}

function adjustInventory({ quantity, remark, inventoryId }) {
	return apiWms.post(`/inventory/adjust/${inventoryId}`, {
		quantity,
		remark
	});
}

export { getCompanyInventory, getInventoryDetail, updateInventoryStock, getWarehouseInventory, addInventory, adjustInventory };
