import { DownloadOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';

export const approvalStatusOptions = [
	{ value: 'PENDING', label: '3PL RESPONSE PENDING' },
	{ value: 'APPROVED', label: 'APPROVED' },
	{ value: 'DISAPPROVED', label: 'DISAPPROVED' }
];

export const formLinkStatusOptions = [
	{ value: 'SENT', label: 'SENT' },
	{ value: 'VISITED', label: 'VISITED' },
	{ value: 'FILLED', label: 'FILLED' }
];

export const requestTypeOptions = [
	{ value: 'UPDATE_DETAILS', label: 'UPDATE DETAILS' },
	{ value: 'CANCEL_ORDER', label: 'CANCEL ORDER' }
];

export const EApprovalStatus = Object.freeze({
	APPROVED: 'APPROVED',
	DISAPPROVED: 'DISAPPROVED',
	PROCESSED: 'PROCESSED',
	PENDING: 'PENDING'
});

export function topFilterUtil({
	filter,
	setFilter,
	onDownloadOrders,
	isAdmin = true,
	selectedRowKeys = [],
	handleNdrApprovalStatusChange
}) {
	const setDate = (startDate, endDate) => {
		setFilter((v) => ({ ...v, startDate, endDate }));
	};

	const handleStatusChange = (statusName) => (status) => {
		setFilter((prevFilter) => ({ ...prevFilter, [statusName]: status }));
	};

	return {
		row1: [
			{
				label: 'FILTER BY DATE',
				onChange: (_dates, dateStrings) => setDate(...dateStrings),
				type: 'range',
				allowClear: false,
				value: [dayjs(filter.startDate), dayjs(filter.endDate)],
				defaultValue: [dayjs(filter.startDate), dayjs(filter.endDate)],
				ranges: {
					Today: [dayjs(), dayjs()],
					'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
					'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
					'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
					'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
				}
			}
		],
		row1children: [
			<div className={styles.orderStatusFilter}>
				<label>Formlink Status</label>
				<Select
					mode="multiple"
					maxTagCount="responsive"
					placeholder="Select form Status"
					style={{ width: '100%' }}
					allowClear
					value={filter.formLinkStatus}
					onChange={handleStatusChange('formLinkStatus')}
				>
					{formLinkStatusOptions.map((aStatus) => {
						return <Select.Option key={aStatus.value}>{aStatus.label}</Select.Option>;
					})}
				</Select>
			</div>,
			<div className={styles.orderStatusFilter}>
				<label>Reqeust Type</label>
				<Select
					maxTagCount="responsive"
					placeholder="Select Request Type"
					style={{ width: '100%' }}
					allowClear
					value={filter.requestType}
					onChange={handleStatusChange('requestType')}
				>
					{requestTypeOptions.map((aStatus) => {
						return <Select.Option key={aStatus.value}>{aStatus.label}</Select.Option>;
					})}
				</Select>
			</div>,

			<div className={styles.orderStatusFilter}>
				<label>Approval Status</label>
				<Select
					mode="multiple"
					maxTagCount="responsive"
					placeholder="Select approval Status"
					style={{ width: '100%' }}
					allowClear
					value={filter.approvalStatus}
					onChange={handleStatusChange('approvalStatus')}
				>
					{approvalStatusOptions.map((aStatus) => {
						return <Select.Option key={aStatus.value}>{aStatus.label}</Select.Option>;
					})}
				</Select>
			</div>,
			<Tooltip placement="top" title={'Coming soon'}>
				<Button
					type="primary"
					className={styles.downloadButton}
					shape={'round'}
					icon={<DownloadOutlined />}
					onClick={onDownloadOrders}
					size="large"
					disabled
				/>
			</Tooltip>
		],
		row2: [],
		row4: isAdmin
			? [
					{
						key: '1',
						label: 'Mark Processed',
						onClick: () =>
							handleNdrApprovalStatusChange({
								customerRequestIds: selectedRowKeys,
								newApprovalStatus: EApprovalStatus.PROCESSED
							}),
						isActive: selectedRowKeys.length > 0
					},
					{
						key: '2',
						label: 'Mark Approved',
						onClick: () =>
							handleNdrApprovalStatusChange({
								customerRequestIds: selectedRowKeys,
								newApprovalStatus: EApprovalStatus.APPROVED
							}),
						isActive: selectedRowKeys.length > 0
					},
					{
						key: '3',
						label: 'Mark Disapproved',
						onClick: () =>
							handleNdrApprovalStatusChange({
								customerRequestIds: selectedRowKeys,
								newApprovalStatus: EApprovalStatus.DISAPPROVED
							}),
						isActive: selectedRowKeys.length > 0
					}
			  ]
			: []
	};
}
