import { AutoComplete, Col, Select } from 'antd';
import dayjs from 'dayjs';
import { getCompanies, getLightningOrdersSearchParams } from 'shared/api/lightning';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import errorHandler from 'shared/utils/errorHandler';
import debounce from 'utils/debounce';
import { PAYMENT_METHOD } from '../../../../constants';
import { apiHms } from '../../../../shared/api';
import { ORDER_STATUS_TYPES } from '../constants';
import { searchOptions } from './constants';
import styles from './index.module.scss';

export function topFilterUtil({
	filter,
	setFilter,
	setPageConfigDefault,
	companies,
	setCompanies,
	onDownloadOrders,
	lightningPanel = false
}) {
	async function getAllCompanies(searchValue) {
		try {
			const { data } = await getCompanies(searchValue);
			if (data.status) {
				setCompanies(
					data.companies.map((company) => ({
						key: company.entityId,
						value: company.entityId,
						label: company.name,
						...company
					}))
				);
			} else throw new Error(`Couldn't get companies`);
		} catch (error) {
			errorHandler(error);
		}
	}

	async function onDownloadDeliveryManifest(filters) {
		try {
			const searchParams = getLightningOrdersSearchParams({ filters });
			const { data } = await apiHms.get(`/admin/pickup-manifest`, {
				params: searchParams,
				responseType: 'blob'
			});
			const URL = window.URL || window.webkitURL;
			var downloadURL = URL.createObjectURL(data);
			window.open(downloadURL, '_blank');
		} catch (error) {
			errorHandler(error);
			URL.revokeObjectURL(downloadURL);
		}
	}

	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, startDate, endDate }));
	};

	const handleOrderStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};

	const handlePaymentModeChange = (paymentMethod) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, paymentMethod }));
	};

	const handleCompanyChange = (company) => {
		setPageConfigDefault();
		// When clicked on clear button
		if (!company) {
			setFilter((prevFilter) => ({ ...prevFilter, company, companyName: undefined }));
		}
		// When a company is selected
		else {
			const { id, name } = JSON.parse(company);
			setFilter((prevFilter) => ({ ...prevFilter, company: id, companyName: name }));
		}
	};

	const debouncedCompanySearchChange = debounce((company) => {
		// GET companies only when the search term is non-empty
		if (company.trim()) {
			getAllCompanies(company);
		} else {
			setCompanies([]);
		}
	}, 500);

	return {
		row1: [
			{
				label: 'FILTER BY DATE',
				onChange: (dates, dateStrings) => setDate(...dateStrings),
				type: 'range',
				allowClear: false,
				value: [dayjs(filter.startDate), dayjs(filter.endDate)],
				defaultValue: [dayjs(filter.startDate), dayjs(filter.endDate)],
				ranges: {
					Today: [dayjs(), dayjs()],
					'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
					'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
					'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
					'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
				}
			}
		],
		row1children: [
			<Col>
				<div className={styles.searchContainer}>
					<DebouncedCompactSearch
						defaultSearchOptionType={filter.searchOptionType}
						searchOptions={searchOptions}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
						selectStyle={{ minWidth: '100px' }}
					>
						<label>Search By:</label>
					</DebouncedCompactSearch>
				</div>
			</Col>,
			<div className={styles.orderStatusFilter}>
				<label>Payment Status:</label>
				<Select
					placeholder="Select Payment Method"
					value={filter.paymentMethod}
					defaultValue={null}
					onChange={handlePaymentModeChange}
					allowClear={true}
				>
					{Object.keys(PAYMENT_METHOD).map((paymentMethod) => {
						return <Select.Option key={paymentMethod}>{paymentMethod}</Select.Option>;
					})}
				</Select>
			</div>,
			<div className={styles.orderStatusFilter}>
				<label>Status:</label>
				<Select
					mode="multiple"
					maxTagCount="responsive"
					placeholder="Select Order Status"
					style={{ width: '100%' }}
					allowClear
					value={filter.status}
					onChange={handleOrderStatusChange}
				>
					{Object.keys(ORDER_STATUS_TYPES).map((orderStatus) => {
						return <Select.Option key={orderStatus}>{orderStatus}</Select.Option>;
					})}
				</Select>
			</div>,
			lightningPanel ? null : (
				<div className={styles.orderCompaniesFilter}>
					<label>Companies:</label>
					<AutoComplete
						placeholder="Search for a company"
						onSearch={debouncedCompanySearchChange}
						onClear={handleCompanyChange}
						onSelect={handleCompanyChange}
						style={{ width: '200px' }}
						allowClear
						value={filter.companyName}
						notFoundContent="No companies found"
					>
						{companies.map(({ key, value, label }) => {
							return (
								<AutoComplete.Option key={key} value={JSON.stringify({ id: value, name: label })}>
									{label}
								</AutoComplete.Option>
							);
						})}
					</AutoComplete>
				</div>
			)
		],
		row2: [],
		row4: [
			lightningPanel
				? null
				: {
						key: '1',
						label: 'Print Manifest',
						onClick: () => onDownloadDeliveryManifest(filter),
						isActive: true
				  },
			{
				key: '2',
				label: 'Export Orders',
				onClick: onDownloadOrders,
				isActive: true
			}
		]
	};
}
