import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { getJourney } from 'shared/api/lightning';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import errorHandler from 'shared/utils/errorHandler';
import JourneyInfo from '../../../../shared/components/JourneyInfo/JourneyInfo';
import { useColumns } from './columns';
import { defaultFilter, defaultPageConfig } from './constants';
import { topFilterUtil } from './utils';
import reactDom from 'react-dom';

export function Trips() {
	const [data, setData] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [currentCount, setCurrentCount] = useState(0);
	const [openJourneyInfo, setOpenJourneyInfo] = useState(false);
	const [loading, setLoading] = useState(false);
	const columns = useColumns({ clickTripId: setOpenJourneyInfo });

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault
			}),
		[filter, setFilter]
	);

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	function handleTableChange(_pagination, _filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	async function getAllJourneys({ pageConfiguration, filters }) {
		try {
			setLoading(true);
			const { data: journeyData } = await getJourney({ pageConfiguration, filters });

			if (journeyData.status) {
				reactDom.unstable_batchedUpdates(() => {
					setData(journeyData.data);
					setCurrentCount(journeyData.currentCount);
				});
			} else {
				throw new Error(journeyData.message || 'Could not get trips');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getAllJourneys({ pageConfiguration: pageConfig, filters: filter });
	}, [pageConfig, filter]);

	return (
		<Row className="h-100 p-0">
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={currentCount}
							onChange={handleTableChange}
							loading={loading}
							shouldRowSelection={false}
							{...pageConfig}
						/>
					</div>
				</div>
			</Col>
			{openJourneyInfo && <JourneyInfo journeyID={openJourneyInfo} showJourneyInfo={setOpenJourneyInfo} />}
		</Row>
	);
}
