import {
	customFieldValidatorForCatalogue,
	customFieldValidatorForInventory,
	customFieldValidatorForLightningOrder,
	customFieldValidatorForOrder,
	customFieldValidatorForWSINMapping,
	customFieldValidatorForWSINUpLoad
} from './validations';

/**
 * Routes constants
 */
export const bulkCataloguePreviewRoute = `/bulk/catalogues`;

export const bulkInventoryPreviewRoute = '/bulk/inventory';

export const bulkWSINUploadPreviewRoute = `/bulk/wsin-upload`;

export const bulkWSINMappingPreviewRoute = `/bulk/wsin-map`;

export const bulkOrderPreviewRoute = '/bulk/orders';

export const bulkLightningOrderPreviewRoute = '/bulk/lightning-orders';

/**
 * For Catalogue bulk constants
 */
export const fieldNameToColumnAlphabetMapperForCatalogue = {
	itemName: 'A',
	productId: 'B',
	sku: 'C',
	hsnCode: 'D',
	category: 'E',
	status: 'F',
	price: 'G',
	length: 'H',
	breadth: 'I',
	height: 'J',
	weight: 'K',
	sgst: 'L',
	cgst: 'M',
	igst: 'N',
	utgst: 'O'
};

export const excelCatalogueFieldNames = [
	'ItemName',
	'ProductId',
	'sku',
	'HsnCode',
	'Category',
	'Status',
	'Price(inclusive of GST)',
	'Length(cm)',
	'Breadth(cm)',
	'Height(cm)',
	'Weight(gm)',
	'Sgst(%)',
	'Cgst(%)',
	'Igst(%)',
	'Utgst(%)'
];

export const columnAlphabetToFieldNameMapperForCatalogue = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForCatalogue)) {
	columnAlphabetToFieldNameMapperForCatalogue[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_CATALOGUE = ['price', 'length', 'breadth', 'height', 'weight', 'sgst', 'cgst', 'igst', 'utgst'];

/**
 * For Orders Bulk Upload
 */
export const fieldNameToColumnAlphabetMapperForOrder = {
	id: 'A',
	length: 'B',
	breadth: 'C',
	height: 'D',
	weight: 'E',
	paymentMethod: 'F',
	itemName: 'G',
	itemQuantity: 'H',
	discount: 'I',
	// this fields is added later so key name is after w(shippingPrice), but would like to display after discount
	itemPrice: 'X',
	sku: 'J',
	name: 'K',
	phone: 'L',
	email: 'M',
	line1: 'N',
	line2: 'O',
	city: 'P',
	state: 'Q',
	pincode: 'R',
	awb: 'S',
	deliveryPartner: 'T',
	shippingLabelUrl: 'U',
	invoiceUrl: 'V',
	shippingPrice: 'W',
	ewayBill: 'Y',
	warehouseId: 'Z'
};

export const excelOrderFieldNames = [
	'id',
	'Length(cm)',
	'Width(cm)',
	'Height(cm)',
	'Weight(g)',
	'Payment Method',
	'Item Name',
	'Item Quantity',
	'discount',
	'Item Price',
	'sku',
	'Name',
	'Phone',
	'Email',
	'Line 1',
	'Line 2',
	'city',
	'state',
	'pincode',
	'awb',
	'delivery partner',
	'shipping label url',
	'invoice url',
	'Shipping Price',
	'Eway Bill',
	'Warehouse Id'
];

export const columnAlphabetToFieldNameMapperForOrder = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForOrder)) {
	columnAlphabetToFieldNameMapperForOrder[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_ORDERS = [
	'length',
	'breadth',
	'height',
	'weight',
	'itemQuantity',
	'discount',
	'phone',
	'pincode',
	'shippingPrice',
	'itemPrice'
];

/**
 * FOR Inventory Upload
 */
export const fieldNameToColumnAlphabetMapperForInventory = {
	wsin: 'A',
	inboundAccepted: 'B',
	inboundRejected: 'C',
	remarks: 'D'
};

export const excelInventoryFieldNames = ['WSIN', 'Inbound Accepted', 'Inbound Rejected', 'Remarks'];

export const columnAlphabetToFieldNameMapperForInventory = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForInventory)) {
	columnAlphabetToFieldNameMapperForInventory[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_INVENTORY = ['inboundAccepted', 'inboundRejected'];

/**
 * FOR WSIN Upload constants
 */
export const fieldNameToColumnAlphabetMapperForWSINUpload = {
	productName: 'A',
	wsin: 'B'
};

export const excelWSINUploadFieldNames = ['ProductName', 'WSIN'];

export const columnAlphabetToFieldNameMapperForWSINUpload = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForWSINUpload)) {
	columnAlphabetToFieldNameMapperForWSINUpload[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_WSIN_UPLOAD = [];

/**
 * FOR WSIN Map constants
 */
export const fieldNameToColumnAlphabetMapperForWSINMapping = {
	catalogueId: 'A',
	productTitle: 'B',
	productType: 'C',
	wsinMap: 'D'
};

export const excelWSINMappingFieldNames = ['Catalogue Id', 'Product Title', 'Product Type', 'Wsin Map'];

export const columnAlphabetToFieldNameMapperForWSINMapping = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForWSINMapping)) {
	columnAlphabetToFieldNameMapperForWSINMapping[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_WSIN_MAPPING = [];

/**
 * For Lightning Orders Bulk Upload
 */
export const fieldNameToColumnAlphabetMapperForLightningOrder = {
	referenceId: 'A',
	quantity: 'B',
	weight: 'C',
	totalPrice: 'D',
	paymentMethod: 'E',
	shippingPersonName: 'F',
	shippingPersonPhone: 'G',
	shippingPersonEmail: 'H',
	shippingLine1: 'I',
	shippingLine2: 'J',
	shippingCity: 'K',
	shippingState: 'L',
	shippingPincode: 'M',
	awb: 'N'
};

export const excelLightningOrderFieldNames = [
	'Reference Id',
	'Quantity',
	'Weight (g)',
	'Price',
	'Payment Method',
	'Customer Name',
	'Customer Phone',
	'Customer Email',
	'Address Line 1',
	'Address Line 2',
	'City',
	'State',
	'Pincode',
	'AWB'
];

export const columnAlphabetToFieldNameMapperForLightningOrder = {};
for (const [fieldName, columnAlphabet] of Object.entries(fieldNameToColumnAlphabetMapperForLightningOrder)) {
	columnAlphabetToFieldNameMapperForLightningOrder[columnAlphabet] = fieldName;
}

export const NUMERIC_FIELDS_LIGHTNING_ORDERS = ['quantity', 'weight', 'totalPrice'];

/**
 * Validation Constants
 */
export const MAX_ROWS_ALLOWED = 100;
export const MAX_ROWS_EXCEED_ERROR_MSG = `Maximum ${MAX_ROWS_ALLOWED} rows are allowed at a time`;

/**
 * Mapping constants
 */
export const fieldNamesMap = {
	catalogues: excelCatalogueFieldNames,
	orders: excelOrderFieldNames,
	inventory: excelInventoryFieldNames,
	'wsin-upload': excelWSINUploadFieldNames,
	'wsin-map': excelWSINMappingFieldNames,
	'lightning-orders': excelLightningOrderFieldNames
};

export const fieldNameToColumnAlphabetMap = {
	catalogues: fieldNameToColumnAlphabetMapperForCatalogue,
	orders: fieldNameToColumnAlphabetMapperForOrder,
	inventory: fieldNameToColumnAlphabetMapperForInventory,
	'wsin-upload': fieldNameToColumnAlphabetMapperForWSINUpload,
	'wsin-map': fieldNameToColumnAlphabetMapperForWSINMapping,
	'lightning-orders': fieldNameToColumnAlphabetMapperForLightningOrder
};

export const numericFieldsMap = {
	catalogues: NUMERIC_FIELDS_CATALOGUE,
	orders: NUMERIC_FIELDS_ORDERS,
	inventory: NUMERIC_FIELDS_INVENTORY,
	'wsin-upload': NUMERIC_FIELDS_WSIN_UPLOAD,
	'wsin-map': NUMERIC_FIELDS_WSIN_MAPPING,
	'lightning-orders': NUMERIC_FIELDS_LIGHTNING_ORDERS
};

export const validationMap = {
	catalogues: customFieldValidatorForCatalogue,
	orders: customFieldValidatorForOrder,
	inventory: customFieldValidatorForInventory,
	'wsin-upload': customFieldValidatorForWSINUpLoad,
	'wsin-map': customFieldValidatorForWSINMapping,
	'lightning-orders': customFieldValidatorForLightningOrder
};

/**
 * First index from where actual data will start,above this index instructions will be written in excel
 */
export const validationFirstIndexMap = {
	catalogues: 'itemname',
	orders: 'id',
	inventory: 'wsin',
	'wsin-upload': 'productname',
	'wsin-map': 'catalogue id',
	'lightning-orders': 'reference id'
};

export const columnAlphabetToFieldNameMap = {
	catalogues: columnAlphabetToFieldNameMapperForCatalogue,
	orders: columnAlphabetToFieldNameMapperForOrder,
	inventory: columnAlphabetToFieldNameMapperForInventory,
	'wsin-upload': columnAlphabetToFieldNameMapperForWSINUpload,
	'wsin-map': columnAlphabetToFieldNameMapperForWSINMapping,
	'lightning-orders': columnAlphabetToFieldNameMapperForLightningOrder
};
