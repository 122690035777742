const { checkIsEmailValid, isValidNumber: checkIsNumberValid, isValidPhoneNumber: checkIsPhoneNumberValid } = require('helper');

export default function Validator(value, fieldName) {
	this.value = value;
	this.fieldName = fieldName;
	this.errors = [];

	this.required = () => {
		const { value, fieldName } = this;

		if (value === null || value === undefined || value === '') {
			this.errors.push(`${fieldName} is required`);
		}
		return this;
	};

	this.minLength = (len) => {
		const value = this.value;

		if (value && value.length < len) {
			this.errors.push(`length should not be less than ${len}`);
		}
		return this;
	};
	this.maxLength = (len) => {
		const value = this.value;

		if (value && value.length > len) {
			this.errors.push(`length should not be more than ${len}`);
		}
		return this;
	};

	this.minVal = (minVal) => {
		const value = this.value;

		if (Number(value) < minVal) {
			this.errors.push(`Value should not be less than ${minVal}`);
		}
		return this;
	};

	this.maxVal = (maxVal) => {
		const value = this.value;

		if (Number(value) > maxVal) {
			this.errors.push(`Value should not be more than ${maxVal}`);
		}
		return this;
	};

	this.isValidEmail = () => {
		const value = this.value;
		const { isValid, reason } = checkIsEmailValid(value);

		if (!isValid) {
			this.errors.push(reason);
		}

		return this;
	};

	this.isValidNumber = () => {
		const value = this.value;
		const { isValid, reason } = checkIsNumberValid(value);

		if (!isValid) {
			this.errors.push(reason);
		}

		return this;
	};

	this.isValidPhoneNumber = () => {
		const value = this.value;
		const { isValid, reason } = checkIsPhoneNumberValid(value);

		if (!isValid) {
			this.errors.push(reason);
		}

		return this;
	};

	this.valid = (validValues) => {
		let value = this.value;

		value = value.toLowerCase();

		if (!validValues.includes(value)) {
			this.errors.push(`Accepts only ${validValues.join(', ')}`);
		}

		return this;
	};

	this.isValidSku = () => {
		const { value, fieldName } = this;

		if (/[\() ]/.test(value)) {
			this.errors.push(`Please provide valid ${fieldName}`);
		}

		return this;
	};
}
