import { supportImgUrl } from '../../../../constants';

import styles from './index.module.scss';

export function Support() {
	return (
		<div className={styles.headContainer}>
			<h2>Got Queries? Let's Talk</h2>

			<div className={styles.supportImgParentDiv}>
				<img alt="support person" className={styles.supportImg} src={supportImgUrl} />
			</div>
			<h6>
				Please reach out to us at <a href="mailto:support@wherehouse.io">support@wherehouse.io</a>
			</h6>
		</div>
	);
}
