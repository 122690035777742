import { Badge, Col, notification, Progress, Select, Button } from 'antd';
import { ORDER_STATUS } from 'constants/index';
import dayjs from 'dayjs';
import { downloadAsFile } from 'helper';
import { apiOms } from 'shared/api';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import errorHandler from 'shared/utils/errorHandler';
import { orderStatus } from 'shared/utils/orderStatus';
import amplitude from '../../../shared/utils/Amplitude';
import { defaultFilter, MAX_DOWNLOAD_LABEL_LIMIT, searchOptions, searchOptionsForMissedOrder } from './constants';
import styles from './index.module.scss';
import exportSvg from 'shared/svgs/export_svg';
const { Option } = Select;

const calculateAllCount = (counts) => {
	return Object.keys(ORDER_STATUS).reduce((allCount, field) => allCount + (!isNaN(counts[field]) ? +counts[field] : 0), 0);
};

const calculateUnserviceableCounts = (counts) => {
	let allCount = 0;

	const UNSERVICEABLE_COUNT_FIELDS = [ORDER_STATUS.UNSERVICEABLE];

	UNSERVICEABLE_COUNT_FIELDS.forEach((field) => (allCount += counts[field] || 0));

	return allCount;
};

const calculateNdrCount = (counts) => {
	let ndrCount = 0;
	const NDR_FIELDS = [ORDER_STATUS.UNDELIVERED, ORDER_STATUS.RTO_UNDELIVERED];

	NDR_FIELDS.forEach((field) => (ndrCount += +counts[field] || 0));
	return ndrCount;
};

const calculateReturnCount = (counts) => {
	let rtoCount = 0;
	const RTO_FIELDS = [
		ORDER_STATUS.RTO_DELIVERED,
		ORDER_STATUS.RTO_IN_TRANSIT,
		ORDER_STATUS.RTO_OUT_FOR_DELIVERY,
		ORDER_STATUS.RTO_PROCESSED,
		ORDER_STATUS.DTO_DELIVERED,
		ORDER_STATUS.DTO_IN_TRANSIT,
		ORDER_STATUS.DTO_OUT_FOR_DELIVERY,
		ORDER_STATUS.DTO_PROCESSED
	];

	RTO_FIELDS.forEach((field) => (rtoCount += +counts[field] || 0));
	return rtoCount;
};

export const sidebarUtil = ({ counts, currentCount, missedOrderCount }) => ({
	sideBarMenu: [
		{
			key: '1',
			label: `All  (${calculateAllCount(counts)})`,
			icon: 'allSvgIcon'
		},
		{
			key: '2',
			label: `Unserviceable (${calculateUnserviceableCounts(counts)})`,
			icon: 'flagSvgIcon'
		},
		// {
		// 	key: '3',
		// 	label: 'Flagged (0)',
		// 	icon: 'flagSvgIcon'
		// },
		{
			key: '3',
			label: `NDRs (${calculateNdrCount(counts)})`,
			icon: 'ndrSvgIcon'
		},
		{
			key: '4',
			label: `Returns (${calculateReturnCount(counts)})`,
			icon: 'returnSvgIcon'
		},
		{
			key: '5',
			label: `Missed Orders (${missedOrderCount})`,
			icon: 'returnSvgIcon',
			isLink: true
		},
		{
			key: '6',
			label: 'NDR Customer Requests',
			icon: 'ndrSvgIcon'
		}
	]
});

const showOrderStatusFilters = (sidebarMenuItem) => (sidebarMenuItem === '5' ? false : true);

export const topFilterUtil = ({
	onDownloadOrders,
	onDownloadOrderPickList,
	filter,
	setFilter,
	counts,
	setPageConfigDefault,
	sidebarMenuItem,
	selectedRowKeys,
	setIsLabelEmailModalVisible,
	// getAllOrders,

	trackBulkRTS,
	rtsProgress,
	isMissedOrder = false
}) => {
	const handleStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};

	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((v) => ({ ...v, startDate, endDate }));
	};

	const bulkDownload = async (type) => {
		amplitude.getInstance().logEvent(`Bulk ${type} clicked - orders page`);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			if (type === 'label' && selectedRowKeys.length > MAX_DOWNLOAD_LABEL_LIMIT) {
				setIsLabelEmailModalVisible(true);
			} else {
				const res = await apiOms.post(`/download/${type}`, { orderId: selectedRowKeys }, { responseType: 'blob' });
				downloadAsFile({ data: res.data, fileName: `${type}s`, fileType: 'pdf' });
			}
		} catch (error) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Unable to find ${type}`;
			}

			errorHandler(error);
		}
	};

	const bulkReadyToShip = async (ids) => {
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			const {
				data: { status, message, order }
			} = await apiOms.get(`/bulkreadytoship/?orderids=${selectedRowKeys.join(',')}`);

			trackBulkRTS(order);
			if (!status) {
				notification.error({
					message: 'Failed',
					description: message,
					placement: 'topRight'
				});
				return;
			}

			// getAllOrders(pageConfig, filter);
			notification.success({
				message: 'Success',
				description: message,
				placement: 'topRight'
			});
		} catch (error) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Something went wrong!`;
			}

			errorHandler(error);
		}
	};

	const onDownloadShippingManifest = async () => {
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');
			const { data } = await apiOms.get(`/download/shipping-manifest?orderIds=${selectedRowKeys.join(',')}`, {
				responseType: 'blob'
			});

			// create an object URL from the Blob
			var URL = window.URL || window.webkitURL;
			var downloadUrl = URL.createObjectURL(data);
			window.open(downloadUrl, '_blank');
		} catch (error) {
			errorHandler(error);
			URL.revokeObjectURL(downloadUrl);
		}
	};

	return {
		row1: [
			{
				label: 'FILTER BY DATE',
				onChange: (dates, dateStrings) => setDate(...dateStrings),
				type: 'range',
				allowClear: false,
				value: [dayjs(filter.startDate), dayjs(filter.endDate)],
				defaultValue: [dayjs(filter.startDate), dayjs(filter.endDate)],
				ranges: {
					Today: [dayjs(), dayjs()],
					'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
					'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
					'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
					'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
				}
			}
		],
		row1children: [
			<Col>
				<div className={styles.searchContainer}>
					<DebouncedCompactSearch
						defaultSearchOptionType={filter.searchOptionType}
						searchOptions={isMissedOrder ? searchOptionsForMissedOrder : searchOptions}
						debounceTime={700}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
						selectStyle={{ minWidth: '140px' }}
					>
						<label>Search By:</label>
					</DebouncedCompactSearch>
				</div>
			</Col>,
			// For Order status
			showOrderStatusFilters(sidebarMenuItem) && !isMissedOrder && (
				<div className={styles.orderStatusFilter}>
					<label>Status:</label>
					<Select
						mode="multiple"
						placeholder="Select status"
						maxTagCount="responsive"
						style={{ width: '100%' }}
						allowClear
						value={filter.status}
						onChange={handleStatusChange}
					>
						{Object.values(ORDER_STATUS).map((orderStatusVar) => {
							const count = counts[orderStatusVar] || 0;
							return (
								<Option key={orderStatusVar} disabled={!count}>
									{orderStatus[orderStatusVar]} ({count})
								</Option>
							);
						})}
					</Select>
				</div>
			),
			// For sync status
			isMissedOrder && (
				<div className={styles.orderStatusFilter}>
					<label>Status:</label>
					<Select
						placeholder="Select status"
						style={{ width: '100%' }}
						allowClear
						value={filter.status}
						onChange={handleStatusChange}
					>
						<Option key={''}>All</Option>
						<Option key={1}>Synced</Option>
						<Option key={0}>Not Synced</Option>
					</Select>
				</div>
			)
			// For Cluster
		],
		row2: [
			<Col>
				<Button style={{ marginTop: '1.5rem' }} onClick={() => onDownloadOrders(selectedRowKeys)}>
					<span>{exportSvg}</span>
					<span style={{ marginLeft: '.4rem', fontSize: '0.85rem' }}>Export</span>
				</Button>
			</Col>
		],
		row4: isMissedOrder
			? []
			: [
					{
						key: '1',
						label: 'Print Label',
						onClick: () => bulkDownload('label'),
						isActive: selectedRowKeys.length > 0
					},
					{
						key: '2',
						label: 'Print Invoice',
						onClick: () => bulkDownload('invoice'),
						isActive: selectedRowKeys.length > 0
					},
					{
						key: '3',
						label: 'Mark Ready To Ship',
						onClick: () => bulkReadyToShip(selectedRowKeys),
						isActive: selectedRowKeys.length > 0
					},
					{
						key: '4',
						label: 'Download PickList',
						onClick: () => onDownloadOrderPickList(),
						isActive: selectedRowKeys.length > 0
					},
					{
						key: '5',
						label: 'Download Shipping Manifest',
						onClick: () => onDownloadShippingManifest(),
						isActive: selectedRowKeys.length > 0
					},

					{
						key: '6',
						isNotButton: true,
						render: () =>
							selectedRowKeys.length !== 0 && (
								<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
							)
					},
					{
						key: '7',
						isNotButton: true,
						render: (progress) => (
							<>
								{rtsProgress ? (
									<>
										<strong>RTS</strong>
										<Progress percent={progress} status="active" style={{ width: '10vw' }} strokeColor="orange" />
									</>
								) : null}
							</>
						)
					}
			  ]
	};
};

export function getFilterFromSessionStorage() {
	let filter;
	const storedFilter = sessionStorage.getItem('rangeFilter');
	if (storedFilter !== null) {
		let filterObj = JSON.parse(storedFilter);
		// check if stored filter is expired, if no, set stored filter
		if (new Date(filterObj.expiresAt) > new Date()) {
			filter = filterObj.filter;
		}
		// if the stored filter is expired, delete the filter from session storage
		// and set the filter to default filter
		else {
			sessionStorage.removeItem('rangeFilter');
			filter = defaultFilter;
		}
	} else {
		filter = defaultFilter;
	}
	delete filter.searchValue;
	return filter;
}

export function removeFilterSessionStorage() {
	sessionStorage.removeItem('rangeFilter');
	return defaultFilter;
}

export function getMissedFilterFromSessionStorage() {
	let filter;
	const storedFilter = sessionStorage.getItem('rangeMissedFilter');
	if (storedFilter !== null) {
		let filterObj = JSON.parse(storedFilter);
		// check if stored filter is expired, if no, set stored filter
		if (new Date(filterObj.expiresAt) > new Date()) {
			filter = filterObj.filter;
		}
		// if the stored filter is expired, delete the filter from session storage
		// and set the filter to default filter
		else {
			sessionStorage.removeItem('rangeMissedFilter');
			filter = defaultFilter;
		}
	} else {
		filter = defaultFilter;
	}
	delete filter.searchValue;
	return filter;
}

export function getSidebarMenuItemFromSessionStorage() {
	const storedMenuItem = sessionStorage.getItem('sidebarMenuItem');
	if (storedMenuItem !== null) {
		return parseInt(storedMenuItem);
	}

	return 1;
}

export function setSidebarMenuItemFromSessionStorageToInitial() {
	sessionStorage.setItem('sidebarMenuItem', '1');
}
