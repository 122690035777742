export default (
	<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.7364 3.89029L12.4841 0.856551C12.2733 0.337565 11.7655 0 11.1931 0H3.38677C2.84024 0 2.33674 0.316457 2.11296 0.805908L0.718666 3.827C0.288327 3.99156 0 4.39663 0 4.86076V13.6371C0 14.2489 0.512103 14.751 1.1404 14.751H6.76493L7.42765 13.5907H1.18343V4.89873H13.1339V7.1308L14.3174 9.19829V4.86076C14.3174 4.4557 14.0979 4.0886 13.7364 3.89029ZM6.70899 3.7384H2.05702L3.18881 1.2827C3.22324 1.21097 3.3007 1.16034 3.38677 1.16034H6.70899V3.7384ZM7.89242 3.7384V1.16034H11.1931C11.2792 1.16034 11.3566 1.21098 11.3911 1.29115L12.3981 3.7384H7.89242Z"
			fill="#231F20"
		/>
		<path
			d="M17.0285 15.1352L12.5788 7.35884C12.4755 7.17741 12.2775 7.06348 12.0624 7.06348C11.8515 7.06348 11.6535 7.17741 11.546 7.35884L7.10055 15.1352C6.99727 15.3124 6.99727 15.536 7.10486 15.7132C7.21244 15.8905 7.40609 16.0002 7.61696 16.0002H16.5121C16.7186 16.0002 16.9166 15.8905 17.0199 15.7132C17.1274 15.536 17.1318 15.3124 17.0285 15.1352ZM8.62395 14.8398L12.0624 8.83141L15.5008 14.8398H8.62395Z"
			fill="#231F20"
		/>
		<path
			d="M11.5891 10.9366V12.6116C11.5891 12.9323 11.8042 13.1011 12.071 13.1011C12.3378 13.1011 12.553 12.9323 12.553 12.6116V10.9366C12.553 10.6159 12.3378 10.4429 12.071 10.4429C11.8042 10.4429 11.5891 10.6159 11.5891 10.9366Z"
			fill="#231F20"
		/>
		<path
			d="M12.0753 13.4473C11.8042 13.4473 11.5891 13.6625 11.5891 13.9241C11.5891 14.1899 11.8085 14.4009 12.0753 14.4009C12.3421 14.4009 12.5616 14.1857 12.5616 13.9241C12.5659 13.6625 12.3464 13.4473 12.0753 13.4473Z"
			fill="#231F20"
		/>
	</svg>
);
