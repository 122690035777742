import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Form, Space, Button, InputNumber } from 'antd';
import styles from './index.module.scss';

export const OrderDetails = ({ customStyle, catalogData }) => {
	return (
		<div className={`${styles.orderDetails} ${customStyle}`}>
			<h4 className={styles.heading}>Order Details</h4>

			<Form.List name="items">
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, fieldKey, ...restField }) => (
							<Space key={key} style={{ display: 'flex', marginBottom: 4, columnGap: '3rem' }} align="center">
								<Form.Item
									{...restField}
									name={[name, 'id']}
									label="Product"
									rules={[{ required: true, message: 'Please select product' }]}
								>
									<Select style={{ width: '400px' }} placeholder="Select product">
										{catalogData.map(({ id, productTitle }) => {
											return <Select.Option value={id}>{productTitle}</Select.Option>;
										})}
									</Select>
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'quantity']}
									label="Quantity"
									rules={[{ required: true, message: 'Please enter quantity' }]}
								>
									<InputNumber placeholder="3" min={1} />
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'gst']}
									label="GST (%)"
									initialValue={0}
									rules={[{ required: true, message: 'Please enter GST' }]}
								>
									<InputNumber min={0} max={100} />
								</Form.Item>
								<Button danger onClick={() => remove(name)} block icon={<DeleteOutlined />}>
									Delete
								</Button>
							</Space>
						))}
						<Form.Item>
							<Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: '200px' }}>
								Add Product
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</div>
	);
};
