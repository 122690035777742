import styles from './index.module.scss';
import WherehouseLogo from '../../shared/svgs/trackingPageSvg/wherehouse_logo_svg.svg';
import RiderLogo from '../../shared/svgs/trackingPageSvg/rider_logo.svg';
import bottomLogoWithId from '../../shared/svgs/trackingPageSvg/bottom_scooter_logo.svg';
import { useState } from 'react';
import { Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';

export const trackLandingRoute = '/track';

export const TrackLanding = () => {
	const [AWB, setAWB] = useState('');
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const pushAWB = () => {
		if (!AWB) {
			notification.error({
				message: 'Please Enter Tracking Number'
			});
			return;
		}
		if (!AWB || AWB.length < 3 || AWB.length > 20) {
			setLoading(false);
			throw new Error('Invalid AWB');
		} else {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				history.push(`/track/${AWB}`);
			}, 2000);
		}
	};
	const handleEnter = (event) => {
		if (event.key === 'Enter') {
			pushAWB();
		}
	};
	return (
		<div className={styles.mainParentDiv}>
			<img src={WherehouseLogo} alt="Logo" className={styles.wherehouseLogo} />
			<div className={styles.rightRiderLogo}>
				<img alt="rider logo" src={RiderLogo} />
			</div>
			<div className={styles.rightSideBox}>
				<div className={styles.headingTop}>
					<h1>
						CONNECTNG PEOPLE WITH WHEREHOUSE <span>LIGHTNING</span>
					</h1>
				</div>
				<div className={styles.searchBox} id={styles.searchBoxWithId}>
					<input
						onKeyDown={handleEnter}
						onChange={(e) => setAWB(e.target.value)}
						type="text"
						placeholder="Enter AWB Number Here"
					/>
					<Button loading={loading} onClick={pushAWB}>
						Track
					</Button>
				</div>
			</div>
			<div className={styles.bottomScooterLogo} id={styles.bottomLogoWithId}>
				<img alt="bottom logo" src={bottomLogoWithId} />
			</div>
		</div>
	);
};
