import { isSortField } from './table';

/**
 * @name renderKeyName
 * @description Is used to render dataIndex directly and you can also render dataIndex from dictionary
 * @param keyName (DataIndex) (KEy from data Object which needs to be render)
 * @param keepImageTag (Boolean) (If you want to render the key only or you want to render the key with image tag)
 * @param dictionary (Dictionary) (Dictionary Object) (IF you have list of key-value pair in dictionary then you can use it to render dataIndex(will treat as key and value assigned to that key will be used))
 *
 */
const renderKeyName = (keyName, keepImageTag, dictionary = {}) => {
	if (keepImageTag && dictionary && Object.keys(dictionary).length > 0) {
		return <img style={{ maxWidth: 56, maxHeight: 56 }} alt={keyName} src={dictionary[keyName]}></img>;
	}

	if (keepImageTag) {
		return <img style={{ maxWidth: 56, maxHeight: 56 }} alt={keyName} src={keyName}></img>;
	}

	if (dictionary && Object.keys(dictionary).length > 0) {
		return dictionary[keyName];
	}

	return keyName;
};

/**
 * @name commonColumns
 * @description This is a common columns which can be used in any table's columns
 *
 */
export const commonColumns = {
	createAt: (sortField, sortOrder) => ({
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
		width: 100,
		sorter: true,
		sortOrder: isSortField(sortField, sortOrder, 'createdAt')
	}),
	/**
	 *
	 * @param {*} title  ((String) Title of the column)
	 * @param {*} dataIndex ((String) DataIndex of the data) (Which key you want to render from the dataObject)
	 * @param {*} keepImageTag ((Boolean) (If you want to render the key only or you want to render the key with image tag))
	 * @param {*} dictionary ((Dictionary) (Dictionary Object) (IF you have list of key-value pair in dictionary then you can use it to render dataIndex(will treat as key and value assigned to that key will be used)))
	 * @param {*} width ((Number) Width of the column)
	 * @returns {Object}
	 */
	staticRenderField: (title, dataIndex, keepImageTag = false, dictionary = {}, width = 200) => ({
		title: title,
		dataIndex: dataIndex,
		render: (key) => <div className="text-center">{renderKeyName(key, keepImageTag, dictionary)}</div>,
		width: width
	}),
	/**
	 *
	 * @param {*} title  ((String) Title of the column)
	 * @param {*} dataIndex ((String) DataIndex of the data) (Which key you want to render from the dataObject)
	 * @param {*} sortField ((String) (If you want to sort the column then you can pass the sortField))
	 * @param {*} sortOrder ((String) (If you want to sort the column then you can pass the sortOrder))
	 * @param {*} keepImageTag ((Boolean) (If you want to render the key only or you want to render the key with image tag))
	 * @param {*} dictionary ((Dictionary) (Dictionary Object) (IF you have list of key-value pair in dictionary then you can use it to render dataIndex(will treat as key and value assigned to that key will be used)))
	 * @param {*} width ((Number) Width of the column)
	 * @returns {Object}
	 */
	staticRenderFieldWithSortOption: (title, dataIndex, sortField, sortOrder, keepImageTag = false, dictionary = {}, width = 200) => ({
		title: title,
		dataIndex: dataIndex,
		render: (key) => <div className="text-center">{renderKeyName(key, keepImageTag, dictionary)}</div>,
		width: width,
		sortOrder: isSortField(sortField, sortOrder, dataIndex)
	})
};
