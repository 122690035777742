import dayjs from 'dayjs';

export const defaultPageConfig = {
	current: 1,
	pageSize: 10,
	sortField: 'createdAt',
	sortOrder: 'DESC'
};

export const searchOptions = [
	{ value: 'driver', label: 'Driver' },
	{ value: 'phone', label: 'Driver Phone' }
];

export const defaultFilter = {
	startDate: dayjs().subtract(1, 'week'),
	endDate: dayjs(),
	searchOptionType: searchOptions[0].value
};
