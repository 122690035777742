import flagSvgIcon from 'shared/svgs/flag_svg';
import allSvgIcon from 'shared/svgs/all_svg';
import returnSvgIcon from 'shared/svgs/return_svg';
import ndrSvgIcon from 'shared/svgs/ndr_svg';
import activeSvgIcon from 'shared/svgs/active_svg';
import { insightsIconSvg } from '../../../../app/AppSeller/Insights/svgs/svgs';

export const ICON_MAPPER = {
	allSvgIcon: allSvgIcon,
	activeSvgIcon: activeSvgIcon,
	flagSvgIcon: flagSvgIcon,
	returnSvgIcon: returnSvgIcon,
	ndrSvgIcon: ndrSvgIcon,
	insightSvgIcon: insightsIconSvg
};
