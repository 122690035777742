export const sidebarUtil = {
	sideBar: [
		{
			key: '1',
			label: 'Profile',
			icon: 'allSvgIcon'
		},
		{
			key: '2',
			label: 'Integrations',
			icon: 'activeSvgIcon'
		},
		{
			key: '3',
			label: 'Invoicing',
			icon: 'flagSvgIcon'
		},
		// {
		// 	key: '4',
		// 	label: 'Pickup Address',
		// 	icon: 'returnSvgIcon'
		// },
		{
			key: '5',
			label: 'Wherehouses',
			icon: 'ndrSvgIcon'
		},
		{
			key: '6',
			label: 'Tracking Page',
			icon: 'allSvgIcon'
		},
		{
			key: '7',
			label: 'Preferences',
			icon: 'ndrSvgIcon'
		},
		{
			key: '8',
			label: 'Shipping Packages',
			icon: 'allSvgIcon'
		},
		{
			key: '9',
			label: 'Support',
			icon: 'ndrSvgIcon'
		}
	]
};
