import { ORDER_STATUS } from '../../../constants';

export const preferenceRowFields = [
	{
		label: 'printItemLabel',
		key: 'printItemLabel',
		description: 'Show item name and quantity in shipping label'
	},
	{
		label: 'fulfillRTS',
		key: 'fulfillRTS',
		description: 'Mark fulfilled at shopify on RTS'
	},
	{
		label: 'markCodPaidOnShopify',
		key: 'markCodPaidOnShopify',
		description: 'Mark COD order as paid at shopify when delivered'
	},
	{
		label: 'fulfillWLOnly',
		key: 'fulfillWLOnly',
		description: 'Fulfill Lightning orders only'
	},
	{
		label: 'printWSINLabel',
		key: 'printWSINLabel',
		description: 'Show WSIN in shipping label'
	},
	{
		label: 'flagDiscountedCOD',
		key: 'flagDiscountedCOD',
		description: 'Flag COD order without coupon'
	},
	{
		label: 'phoneOnReturnAddress',
		key: 'phoneOnReturnAddress',
		description: 'Hide phone number from return address(Shipping Label)'
	},
	{
		label: 'preferMicroOverPseudo',
		key: 'preferMicroOverPseudo',
		description: 'Prefer Micro Warehouse over Pseudo Warehouse'
	},
	{
		label: 'hidePhoneNumber',
		key: 'hidePhoneNumber',
		description: 'Hide phone number from forward address(Shipping Label)'
	},
	{
		label: 'rtoToOtherWarehouse',
		key: 'rtoToOtherWarehouse',
		description: 'RTOs to other warehouses for Clickpost'
	},
	{
		label: 'hsnInInvoicePdf',
		key: 'hsnInInvoicePdf',
		description: 'Show HSN in Order invoice'
	},
	{
		label: 'cancelShopifyOrders',
		key: 'cancelShopifyOrders',
		description: 'Cancel orders on Shopify when cancelled on Wherehouse.io'
	}
];

export const statusUpdateOptions = [
	{ label: 'Placed', value: ORDER_STATUS.PLACED },
	{ label: 'In Transit', value: ORDER_STATUS.IN_TRANSIT },
	{ label: 'Out For Delivery', value: ORDER_STATUS.OUT_FOR_DELIVERY },
	{ label: 'Delivered', value: ORDER_STATUS.DELIVERED }
];

export const EWaModule = Object.freeze({
	NDR: 'ndr',
	WA_NOTIFICATON: 'waNotif'
});

export const defaultWaModule = {
	[EWaModule.NDR]: { isActive: false, triggerStatus: ['UNDELIVERED'] },
	[EWaModule.WA_NOTIFICATON]: {
		isActive: false,
		triggerStatus: [ORDER_STATUS.PLACED, ORDER_STATUS.IN_TRANSIT, ORDER_STATUS.OUT_FOR_DELIVERY, ORDER_STATUS.DELIVERED]
	}
};
