import { Button, Col, Row, Form, notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { apiCms } from '../../../../shared/api';
import { AuthContext } from 'shared/contexts/Auth';
import { OrderDetails } from './Components/OrderDetails';
import { OtherDetails } from './Components/OtherDetails';
// import { PickupDetails } from './Components/PickupDetails';
import { ShippingAddress } from './Components/ShippingAddress';
import styles from './index.module.scss';
import { populateProductItems, fetchAddresses, createOrder } from './utils';
import { EVENT_TYPE, SHOP_TYPE } from '../../../../constants';
import errorHandler from 'shared/utils/errorHandler';
import { useHistory } from 'react-router-dom';

export const manualOrderRoute = '/orders/new';

export const ManualOrder = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [catalogData, setCatalogData] = useState([]);
	const history = useHistory();
	// const [warehouses, setWarehouses] = useState([]);
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const fetchProducts = async () => {
		try {
			const {
				data: { catalogue }
			} = await apiCms.get(`/catalogue/company/${profile.id}`);

			setCatalogData(catalogue);
		} catch (error) {
			console.log(error);
		}
	};

	// const fetchWarehouses = async () => {
	// 	try {
	// 		const {
	// 			data: { warehouses }
	// 		} = await apiWms.get(`/company/warehouses/${profile.id}`);

	// 		setWarehouses(warehouses);
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	const fetchDetails = async () => {
		try {
			setIsLoading(true);
			await fetchProducts();
			// await fetchWarehouses();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (profile?.id) fetchDetails();
	}, []);

	const onFinish = async (formValues) => {
		try {
			let { items, address, paymentMethod } = formValues;

			if (!items?.length) throw new Error('Please select atleast 1 product');

			items = populateProductItems(items, catalogData);
			const { shippingAddress, billingAddress, customer } = fetchAddresses(address);

			const formData = {
				data: {
					shopType: SHOP_TYPE.MANUAL,
					companyId: profile.id,
					paymentMethod,
					weightUnit: 'g',
					shippingAddress,
					billingAddress,
					customer,
					items,
					eventType: EVENT_TYPE.CREATE
				}
			};

			const {
				data: { status, message }
			} = await createOrder(formData);

			if (status) {
				notification.success({
					description: 'Order created successfully',
					position: 'topRight'
				});

				history.push('/orders');
			} else {
				throw new Error(message);
			}
		} catch (error) {
			console.log(error);
			errorHandler(error);
		}
	};

	return (
		<Row className={`h-100 p-0 ${styles.manualOrder}`}>
			<Col span={4} className={styles.sidebar}></Col>

			<Col span={20} className={styles.outerContainer}>
				<div className={styles.innerContainer}>
					<h3>Create new order</h3>
					<br />

					<Form layout="vertical" onFinish={onFinish}>
						<OrderDetails catalogData={catalogData} customStyle={styles.cardStyle} />

						<ShippingAddress customStyle={styles.cardStyle} />

						{/* <PickupDetails warehouses={warehouses} customStyle={styles.cardStyle} /> */}

						<OtherDetails customStyle={styles.cardStyle} />

						<Button type="primary" size="large" htmlType="submit">
							Submit
						</Button>
					</Form>
				</div>
			</Col>
		</Row>
	);
};
