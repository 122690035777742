import styles from './index.module.scss';
import { Form, Select, Input, Row, Col } from 'antd';
import { PAYMENT_METHOD } from '../../../../../../constants';
const { Option } = Select;

export const OtherDetails = ({ customStyle }) => {
	return (
		<div className={`${customStyle} ${styles.otherDetails}`}>
			<h4 className={styles.heading}>Other Details</h4>
			<Row gutter={30}>
				<Col span={10}>
					<Form.Item
						label="Mode of Payment"
						name="paymentMethod"
						rules={[{ required: true, message: 'Please select payment method' }]}
					>
						<Select style={{ width: '400px' }} size="medium">
							<Option value={PAYMENT_METHOD.COD}>COD</Option>
							<Option value={PAYMENT_METHOD.PREPAID}>Prepaid</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col span={10}>
					<Form.Item label="Eway Bill No." name="ewayBill">
						<Input placeholder="000000111111" />
					</Form.Item>
				</Col>

				<Col span={10}>
					<Form.Item label="Backdate (If invoice is being created for any backdate)" name="backdate">
						<Input type="datetime-local" />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};
