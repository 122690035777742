import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import exportSvg from 'shared/svgs/export_svg';
import amplitude from 'shared/utils/Amplitude';
import { EVENT_NAMES } from 'utils/analytics';
import { defaultPageConfig, SHOP_TYPE } from '../../../constants';
import { apiCms } from '../../../shared/api/';
import errorHandler from '../../utils/errorHandler';
import { DebouncedCompactSearch } from '../DebouncedCompactSearch/DebouncedCompactSearch';
import { searchOptions } from './constants';
import styles from './utils.module.scss';

export const useSidebarMenu = (isAdminView, companyId) => {
	const [sideBarMenu, setSidebarMenu] = useState([]);

	useEffect(() => {
		async function fetchSidebarMenu() {
			try {
				const {
					data: { catalogueShopTypes = [] }
				} = await apiCms.get(`/catalogue/shop-types/company/${isAdminView ? companyId : ''}`, {
					...(isAdminView && { headers: { 'x-source': 'ingress' } })
				});

				const manualShopExists = catalogueShopTypes.find(({ shopType }) => shopType === SHOP_TYPE.MANUAL);

				if (!manualShopExists) {
					catalogueShopTypes.push({ shopType: SHOP_TYPE.MANUAL, count: 0 });
				}

				const shopTypes = catalogueShopTypes.map(({ shopType, count = 0 }) => ({
					key: shopType,
					label: `${shopType} (${count})`,
					icon: 'allSvgIcon'
				}));

				setSidebarMenu(shopTypes);
			} catch (error) {
				errorHandler(error);
			}
		}
		fetchSidebarMenu();
	}, [companyId, isAdminView]);

	return sideBarMenu;
};

export const topBarConfig = ({
	shopType,
	setShopType,
	onSyncCatalogue,
	syncDisabledFlag,
	onDownloadCatalogues,
	setPageConfig,
	filter,
	setFilter,
	isAdminView,
	sideBarMenu
}) => {
	const renderAddNewPage = () => {
		amplitude.getInstance().logEvent(EVENT_NAMES.ADD_NEW_CATALOG);
		window.location.hash = 'add';
	};

	const handleActivityChange = (active) => {
		setPageConfig(defaultPageConfig);
		setFilter((prevFilter) => ({ ...prevFilter, active }));
	};

	const handleWSINChange = (wsinMapped) => {
		setFilter((prevFilter) => ({ ...prevFilter, wsinMapped }));
	};

	const handleShopTypeChange = (selectedShopType) => {
		setPageConfig(defaultPageConfig);
		setShopType(selectedShopType);
	};

	const activity = [
		{
			status: 'All',
			value: null
		},
		{
			status: 'Active',
			value: 1
		},
		{
			status: 'Inactive',
			value: 0
		}
	];

	const wsinMapFilter = [
		{
			status: 'All',
			value: null
		},
		{
			status: 'Yes',
			value: true
		},
		{
			status: 'No',
			value: false
		}
	];

	return {
		row5: [
			<div className={styles.topFilter}>
				<div className={styles.topLeftFilter}>
					<div className={styles.searchElement}>
						<DebouncedCompactSearch
							defaultSearchOptionType={filter.searchOptionType}
							searchOptions={searchOptions}
							debounceTime={700}
							setFilter={setFilter}
							setDefault={() => setPageConfig(defaultPageConfig)}
						>
							<label>Search By:</label>
						</DebouncedCompactSearch>
					</div>
					<div className={styles.searchElement}>
						<label>Active:</label> <br></br>
						<Select
							mode="single"
							placeholder="Select Activity"
							style={{ width: '150px' }}
							allowClear
							defaultValue={null}
							value={filter.active}
							optionFilterProp={'children'}
							filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
							onChange={handleActivityChange}
						>
							{activity.map((a) => {
								return (
									<Select.Option key={a.value} value={a.value}>
										{a.status}
									</Select.Option>
								);
							})}
						</Select>
					</div>
					{isAdminView ? (
						<>
							<div>
								<label>WSIN Mapped:</label> <br></br>
								<Select
									mode="single"
									placeholder="Select"
									style={{ width: '150px' }}
									allowClear
									value={filter.wsinMapped}
									defaultValue={null}
									optionFilterProp={'children'}
									filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
									onChange={handleWSINChange}
								>
									{wsinMapFilter.map((a) => {
										return (
											<Select.Option key={a.value} value={a.value}>
												{a.status}
											</Select.Option>
										);
									})}
								</Select>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<label>Shop Type</label>
								<Select
									style={{ width: '150px' }}
									mode="single"
									placeholder="Select"
									allowClear
									value={shopType}
									onChange={handleShopTypeChange}
								>
									{sideBarMenu.map((currentShopType) => (
										<Select.Option key={currentShopType.key} value={currentShopType.value}>
											{currentShopType.key}
										</Select.Option>
									))}
								</Select>
							</div>
						</>
					) : null}
				</div>
				{!isAdminView ? (
					<div className={styles.topRightFilter}>
						{shopType === SHOP_TYPE.MANUAL ? (
							<Tooltip placement="bottom" title={'Add New Item'}>
								<Button
									className={`${styles.buttonRightMargin} ${styles.flexCenter}`}
									type="primary"
									shape={'default'}
									icon={<PlusOutlined style={{ fontSize: '16px' }} />}
									disabled={syncDisabledFlag}
									onClick={renderAddNewPage}
								/>
							</Tooltip>
						) : shopType === SHOP_TYPE.SHOPIFY || shopType === SHOP_TYPE.WORDPRESS ? (
							<Tooltip placement="bottom" title={'Sync Catalogue'}>
								<Button
									className={`${styles.buttonRightMargin} ${styles.flexCenter}`}
									type="primary"
									shape={'default'}
									icon={<SyncOutlined />}
									disabled={syncDisabledFlag}
									onClick={onSyncCatalogue}
								/>
							</Tooltip>
						) : null}

						<Tooltip placement="bottom" title={'Download Table Data'}>
							<Button
								className={styles.flexCenter}
								type="primary"
								shape={'default'}
								onClick={() => onDownloadCatalogues(shopType)}
								style={{ width: '7rem', height: '2.2rem' }}
							>
								<span>{exportSvg}</span>
								<span style={{ marginLeft: '0.45rem' }}>Export</span>
							</Button>
						</Tooltip>
					</div>
				) : null}
			</div>
		],
		shouldShowRow5: true
	};
};
