import React, { useEffect, useReducer, useState } from 'react';
import { login, authenticateUser, logout, signUp, generateOmsCookie, getWarehouseCompanyData, logoutUms } from 'shared/api/auth';
import { obtainProfileData } from 'shared/api/profile';
import * as localForage from 'localforage';
import produce from 'immer';
import { envs } from 'shared/utils/env';
const { appType } = envs;

export const AuthContext = React.createContext();

const initialState = {
	state: envs.apiState,
	client_id: envs.clientId,
	redirect_url: envs.redirectUrl,
	oauthCode: '',
	xsrfToken: '',
	userRoles: null,
	authLoaded: false,
	isLogined: false,
	profile: null,
	profileLoaded: false,
	role: 'seller',
	companyData: [],
	selectedCompanyIds: []
};

const AUTH_AUTHENTICATE = 'AUTH_AUTHENTICATE';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const AUTH_LOAD_STORED_STATE = 'AUTH_LOAD_STORED_STATE';
const AUTH_LOGIN = 'AUTH_LOGIN';
const OBTAIN_PROFILE_DATA = 'OBTAIN_PROFILE_DATA';
const CHANGE_AUTH_ROLE = 'CHANGE_AUTH_ROLE';
const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
const UPDATE_WAREHOUSE_COMPANY_DATA = 'UPDATE_WAREHOUSE_COMPANY_DATA';
const UPDATE_WAREHOUSE_SELECTED_COMPANY_ID = 'UPDATE_WAREHOUSE_SELECTED_COMPANY_ID';

const reducer = produce((state, action) => {
	switch (action.type) {
		case AUTH_LOAD_STORED_STATE:
			return { ...state, ...action.payload };

		case UPDATE_WAREHOUSE_COMPANY_DATA:
			state.companyData = action.payload.companyData;
			break;

		case UPDATE_WAREHOUSE_SELECTED_COMPANY_ID:
			state.selectedCompanyIds = action.payload.selectedCompanyIds;
			break;

		case AUTH_AUTHENTICATE:
			if (action.payload.oauthCode) state.oauthCode = action.payload.oauthCode || '';
			if (action.payload.redirect_url) state.redirect_url = action.payload.redirect_url || '';
			if (action.payload.state) state.state = action.payload.state || '';
			if (action.payload.xsrfToken) state.xsrfToken = action.payload.xsrfToken || '';
			state.isLogined = action.payload.isLogined;
			state.authLoaded = true;
			break;

		case AUTH_LOGIN:
			state.oauthCode = action.payload.oauthCode;
			state.redirect_url = action.payload.redirect_url;
			state.state = action.payload.state;
			state.isLogined = true;
			break;

		case OBTAIN_PROFILE_DATA:
			state.profile = action.payload.responseBody;
			state.profileLoaded = true;
			break;
		case CHANGE_AUTH_ROLE:
			state.role = action.payload.newRole;
			break;
		case AUTH_LOGOUT:
			return initialState;
		case UPDATE_PROFILE_DATA:
			return {
				...state,
				profile: {
					...state.profile,
					...action.payload.updatedData
				}
			};
		default:
	}
});

export const AuthProvider = (props) => {
	const [authState, dispatch] = useReducer(reducer, initialState);
	const [localLoaded, setLocalLoaded] = useState(false);

	useEffect(() => {
		if (localLoaded) {
			localForage
				.setItem('authState', {
					role: authState.role
				})
				.then((value) => {
					// do nothing
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [authState]);

	useEffect(() => {
		localForage
			.ready()
			.then(() => {
				return localForage.getItem('authState');
			})
			.then((data) => {
				dispatch({ type: AUTH_LOAD_STORED_STATE, payload: data || {} });
			})
			.catch(function (e) {
				console.log(e);
			})
			.finally(() => setLocalLoaded(true));
	}, []);

	useEffect(() => {
		if (appType === 'AppWms') {
			// For Company data
			localForage
				.ready()
				.then(() => {
					return localForage.getItem('companyData');
				})
				.then((data) => {
					if (data && data.length > 0) {
						dispatch({ type: UPDATE_WAREHOUSE_COMPANY_DATA, payload: { companyData: data || [] } });
					} else {
						getCompanyData();
					}
				})
				.catch(function (e) {
					console.log(e);
				});

			// For selected Company Ids
			localForage
				.ready()
				.then(() => {
					return localForage.getItem('selectedCompanyIds');
				})
				.then((data) => {
					dispatch({
						type: UPDATE_WAREHOUSE_SELECTED_COMPANY_ID,
						payload: { selectedCompanyIds: data || [] }
					});
				})
				.catch(function (e) {
					console.log(e);
				});
		}
	}, []);

	// Get company Data list for login warehouse
	const getCompanyData = async () => {
		const companyData = await getWarehouseCompanyData();
		dispatch({
			type: UPDATE_WAREHOUSE_COMPANY_DATA,
			payload: { companyData: companyData || [] }
		});
		dispatch({
			type: UPDATE_WAREHOUSE_SELECTED_COMPANY_ID,
			payload: { selectedCompanyIds: [companyData[0].companyId] || [] }
		});

		// Set CompanyData To local storage
		localForage
			.setItem('companyData', companyData)
			.then((data) => {
				// do nothing
			})
			.catch((error) => {
				console.log(error);
			});

		// Set selected company IDs
		localForage
			.setItem('selectedCompanyIds', [companyData[0].companyId])
			.then((data) => {
				// do nothing
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const actions = {
		logout: async (...args) => {
			try {
				localForage.removeItem('companyData');
				localForage.removeItem('selectedCompanyIds');
				await logout();
				dispatch({ type: AUTH_LOGOUT });
			} catch (err) {
				throw err?.response?.data ? err.response.data.responseMessage || err.response.data : 'Please try after some time.';
			}
		},
		clearAuthState: async () => {
			try {
				dispatch({ type: AUTH_LOGOUT });
				await logoutUms();
			} catch (err) {
				throw err?.response?.data ? err.response.data.responseMessage || err.response.data : 'Please try after some time.';
			}
		},
		signUp: async (args, role) => {
			try {
				const {
					data: {
						responseBody: { xsrfToken }
					}
				} = await authenticateUser(authState.state, authState.client_id, authState.redirect_url);

				const {
					data,
					data: {
						responseBody: { oauthCode }
					}
				} = await signUp({ ...args, xsrfToken }, role);
				if (oauthCode) {
					await generateOmsCookie(oauthCode);
				}

				if (data.responseCode === '000027') return;
				else {
					throw Error(data.responseMessage);
				}
			} catch (err) {
				throw err?.response?.data ? err.response.data.responseMessage || err.response.data : 'Please try after some time.';
			}
		},
		login: async (...args) => {
			try {
				const {
					data: {
						responseBody: { xsrfToken, oauthCode }
					},
					data
				} = await authenticateUser(authState.state, authState.client_id, authState.redirect_url);
				if (oauthCode) {
					dispatch({ type: AUTH_LOGIN, payload: { ...data.responseBody } });
				} else {
					const { data } = await login(...args, xsrfToken);
					if (data.responseCode === '000012' || data.responseCode === '000006') {
						dispatch({ type: AUTH_LOGIN, payload: { ...data.responseBody } });
						if (appType === 'AppWms') {
							await getCompanyData();
						}
					} else {
						throw Error(data.responseMessage);
					}
				}
			} catch (err) {
				throw err?.response?.data?.responseMessage || 'Please try after some time.';
			}
		},
		selectedCompanyId: async (companyIds) => {
			dispatch({ type: UPDATE_WAREHOUSE_SELECTED_COMPANY_ID, payload: { selectedCompanyIds: companyIds || [] } });
			// Set selectedCompanyIds To local storage
			localForage
				.setItem('selectedCompanyIds', companyIds)
				.then((value) => {})
				.catch((error) => {
					console.log(error);
				});
		},
		authenticateUser: async () => {
			try {
				const { data } = await authenticateUser(authState.state, authState.client_id, authState.redirect_url);
				dispatch({
					type: AUTH_AUTHENTICATE,
					payload: { ...data.responseBody, isLogined: ['000012', '000006'].includes(data.responseCode) }
				});
			} catch (err) {
				dispatch({ type: AUTH_AUTHENTICATE, payload: {} });
				throw err;
			}
		},
		obtainProfileData: async () => {
			try {
				const { data } = await obtainProfileData();
				dispatch({ type: OBTAIN_PROFILE_DATA, payload: data });
			} catch (err) {
				throw err;
			}
		},
		changeRole: (newRole) =>
			dispatch({
				type: CHANGE_AUTH_ROLE,
				payload: {
					newRole
				}
			}),
		updateProfileData: (updatedData) => {
			dispatch({ type: UPDATE_PROFILE_DATA, payload: { updatedData } });
		}
	};

	return (
		<AuthContext.Provider
			value={{
				authState: authState,
				authActions: actions
			}}
		>
			{localLoaded && props.children}{' '}
		</AuthContext.Provider>
	);
};
