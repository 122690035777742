import { Col, Row } from 'antd';
import { useState } from 'react';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { SalesChannels } from './SalesChannels';
import { ThirdPartyLogisticPreferences } from './ThirdPartyLogisticPreferences';

const INTEGRATION_TABS = {
	1: { label: 'Sales Channels', component: <SalesChannels /> },
	2: { label: 'Delivery Partners ', component: <ThirdPartyLogisticPreferences /> }
};

export function Integrations() {
	const [currentTab, setCurrentTab] = useState('1');

	const fetchRow3 = () => {
		const row3 = [];

		for (const [key, value] of Object.entries(INTEGRATION_TABS)) {
			row3.push({
				key,
				label: value.label,
				selected: currentTab === key,
				onClick: setCurrentTab
			});
		}

		return row3;
	};

	return (
		<Row className="h-100 p-0">
			<Col className="w-100">
				<TopFilterComponent row3={fetchRow3()} />
				{INTEGRATION_TABS[currentTab].component}
			</Col>
		</Row>
	);
}
