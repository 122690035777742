import styles from './index.module.scss';
import arrow from 'shared/svgs/trackingPageSvg/arrow.svg';
import WherehouseLogo from 'shared/svgs/trackingPageSvg/wherehouse_logo_svg.svg';
import { Timeline, Typography } from 'antd';
import { uuid4 } from '@sentry/utils';
import dayjs from 'dayjs';
import { orderStatus } from '../../../../../shared/utils/orderStatus';
import { commonIconHandler } from '../../../../Track/utils';
import { TrailData } from './DummyData';
import { FulfillmentProviderLogos, FulfillmentProviders } from '../../../../../constants';
export const TrackDetails = ({ primaryColor, background, headerLogo, secondaryColor, trackingLink, headerFont }) => {
	return (
		<div id={styles.rootParentOfTrackingPage} style={{ background: `${background}` }} className="scrollable">
			<nav
				className={styles.navbar}
				style={{
					background: `${primaryColor || '#03045e'}`,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<div>
					<img alt="wherehouse logo" src={headerLogo?.data || WherehouseLogo} />
				</div>
				{trackingLink?.length > 0 && (
					<div style={{ display: 'flex', gap: '20px' }}>
						{trackingLink.map((e) => {
							return <p style={{ color: `${headerFont || '#fff'}` }}>{e.linkName}</p>;
						})}
					</div>
				)}
			</nav>
			<div className={styles.parentDivOfTrackingPage}>
				<section className={styles.gridSection}>
					<div className={styles.leftSection}>
						<div className={styles.leftTravelDetails}>
							<div>
								<h1 style={{ color: `${primaryColor}` }}>Here</h1>
								<img alt="arrow" src={arrow} />
								<h1 style={{ color: `${secondaryColor}` }}>There</h1>
							</div>
							<div>
								<div>
									<h1>{dayjs().add(1, 'day').format('DD')}</h1>
									<h3>
										{dayjs().add(1, 'day').format('MMMM')} {dayjs().add(1, 'day').format('YYYY')}
									</h3>
								</div>
								<div>
									<h5>Estimated Delivery Date</h5>
								</div>
							</div>
							<div>
								<h5>Order Status</h5>
								<b style={{ color: `${secondaryColor}` }}>Delivered</b>
							</div>
						</div>
						<div className={styles.trailSection}>
							<Timeline className="custom-timeline">
								{TrailData?.map((t) => (
									<>
										<Timeline.Item
											dot={<img src={commonIconHandler(t.status)} alt="trail logo" style={{ width: '28px' }} />}
											key={uuid4()}
										>
											<b>{`${orderStatus[t?.status].toUpperCase()}`}</b>
											<div style={{ fontSize: '12px' }}>
												<div>{dayjs(t?.createdAt).format('DD MMM, hh:mm A')}</div>
												<Typography.Text type="secondary">{t.description}</Typography.Text>
												<Typography.Paragraph type="secondary">
													{t.city ? `City: ${t.city}` : null}
												</Typography.Paragraph>
											</div>
										</Timeline.Item>
									</>
								))}
							</Timeline>
						</div>
					</div>
					<div className={styles.rightSection}>
						<div className={styles.inputField} style={{ border: `1px solid ${primaryColor}` }}>
							<input placeholder="Enter Your AWB Number here" type={'text'} />
							<button style={{ background: `${primaryColor}` }}>Track</button>
						</div>
						<div className={styles.orderDetails}>
							<div className={styles.topImageSectioninTravelDetails}>
								<div className={styles.brandLogoImage}>
									<img
										alt="fullfillment provider logo"
										src={FulfillmentProviderLogos[FulfillmentProviders.WHEREHOUSE_LIGHTNING]}
									/>
								</div>
								<div className={styles.orderDetailsRightSection}>
									<h1 id={styles.headingForResponsivness}>COURIER</h1>
									<p style={{ color: `${secondaryColor}` }} id={styles.paraForResponsivness}>
										{FulfillmentProviders.WHEREHOUSE_LIGHTNING}
									</p>
									<div></div>
									<h1 id={styles.headingForResponsivness}>ORDER ID</h1>
									<p style={{ color: `${secondaryColor}` }} id={styles.paraForResponsivness}>
										20221347212
									</p>
								</div>
							</div>
							<div className={styles.orderDetailsBottomDashedDiv}></div>
							<div>
								<h1>AWB NO</h1>
								<p style={{ color: `${secondaryColor}` }}>{'abcdefghijkl'}</p>
							</div>
							<p style={{ color: `${primaryColor}` }} className={styles.commonBoxHeading}>
								Order Details
							</p>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};
