export const EVENT_NAMES = {
	DOWNLOAD_ORDER_EXCEL: 'Download order button click',
	DOWNLOAD_SHIPPING_LABEL: 'Download Shipping Label button click',
	ORDERS_BUTTON: 'Orders button Clicked',
	CATALOG_BUTTON: 'Catalog button Clicked',
	INVENTRY_BUTTON: 'Inventory button Clicked',
	INSIGHTS_BUTTON: 'Inventory button Clicked',
	SETTINGS_BUTTON: 'Settings button Clicked',
	LOGOUT_BUTTON: 'Logout button Clicked',
	WORDPRESS_CONNECT: 'Clicked on Wordpress Connect button',
	UNICOMMERCE: 'Clicked on UniCommerce Connect button',
	EASYECOM: 'Clicked on EasyCommerce Connect button',
	EDIT_USER_PROFILE: 'Clicked on Edit User Profile button',
	EDIT_USER_PASSWORD: 'Clicked on Edit User Password button',
	ADD_NEW_CATALOG: 'Clicked on Add New Category button Manual page',
	UPLOAD_BULK_CATALOG: 'Clicked on Upload Bulk Catalog',
	SYNC_CATALOG_BUTTON: 'Sync Catalog button Clicked',
	MARK_READY_SHIP: 'Clicked on Mark Ready To Ship',
	APPSELLER_CREATE_ORDER: 'Clicked on Create Order in Appseller Orders',
	APPSELLER_UPLOAD_BULK_ORDER: 'Clicked on Upload Bulk Orders in Appseller Orders'
};
