import { Button, Col, Form, notification, Select } from 'antd';
import { useState } from 'react';
import { cloneOrder, regenerateInvoiceAndLabel } from '../../../../api/orders';
import errorHandler from '../../../../utils/errorHandler';

export default function OrderActions({
	orderId,
	setReloadOrders
}: {
	orderId: string;
	setReloadOrders: React.Dispatch<React.SetStateAction<number>>;
}) {
	const [form] = Form.useForm();
	const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false);

	const actions = {
		'Clone Order': async function (orderID: string) {
			try {
				setSubmitButtonLoading(true);
				const { data } = await cloneOrder(orderID);
				notification.success({
					message: 'Clone Order',
					description: data.message,
					placement: 'topRight'
				});
			} catch (error: Error | any) {
				errorHandler(error);
			} finally {
				setReloadOrders((v: number) => v + 1);
				setSubmitButtonLoading(false);
			}
		},
		'Regenerate Invoice and Shipping Label': async function (orderID: string) {
			try {
				setSubmitButtonLoading(true);
				const { data } = await regenerateInvoiceAndLabel(orderID);
				if (data.status) {
					notification.success({
						message: 'Successfully regenerated Invoice and Label',
						description: data.message,
						placement: 'topRight'
					});
				}
			} catch (error: Error | any) {
				errorHandler(error);
			} finally {
				setReloadOrders((v: number) => v + 1);
				setSubmitButtonLoading(false);
			}
		}
	};

	return (
		<>
			<Col span={24}>
				<Form form={form}>
					<Form.Item name="actions" rules={[{ required: true, message: 'Order actions' }]}>
						<Select placeholder="Order actions" allowClear style={{ width: '100%' }}>
							{Object.keys(actions).map((action) => {
								return (
									<Select.Option value={action} key={action}>
										{action}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
					<Form.Item noStyle shouldUpdate={(previousValue, currentValue) => previousValue.actions !== currentValue.actions}>
						{({ getFieldValue }) =>
							getFieldValue('actions') !== undefined ? (
								<Button
									style={{ width: '100%' }}
									type="primary"
									size="middle"
									shape="round"
									htmlType="button"
									loading={submitButtonLoading}
									onClick={() => actions[getFieldValue('actions') as keyof typeof actions](orderId)}
								>
									Submit
								</Button>
							) : null
						}
					</Form.Item>
				</Form>
			</Col>
		</>
	);
}
