import { Button, Col, Row, Form, notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { apiCms } from '../../../../shared/api';
import { AuthContext } from 'shared/contexts/Auth';
import { OrderDetails } from './Components/OrderDetails';
import { OtherDetails } from './Components/OtherDetails';
import { ManualOrderAddressForm } from './Components/ShippingAddress';
import styles from './index.module.scss';
import { populateProductItems, fetchAddresses, IManualOrderFormName, createOrder } from './utils';
import { SHOP_TYPE } from '../../../../constants';
import errorHandler from 'shared/utils/errorHandler';
import { useHistory } from 'react-router-dom';
import { FulfilllmentDetails } from './Components/FulfilllmentDetails';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const manualOrderRoute = '/orders/new';

export const ManualOrder = () => {
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [catalogData, setCatalogData] = useState([]);
	const history = useHistory();
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
	const [showBillingForm, setShowBillingForm] = useState(false);
	const {
		authState: { profile }
	} = useContext(AuthContext);

	const editShippingAddress = (updatedAddress) => {
		form.setFieldsValue({
			shippingAddress: {
				...form.getFieldsValue(IManualOrderFormName.SHIPPING_ADDRESS),
				...updatedAddress
			}
		});
	};

	const editBillingAddress = (updatedAddress) => {
		form.setFieldsValue({
			billingAddress: {
				...form.getFieldsValue(IManualOrderFormName.BILLING_ADDRESS),
				...updatedAddress
			}
		});
	};

	const redirectToHomePage = () => history.push('/');

	const fetchProducts = async () => {
		try {
			const {
				data: { catalogue }
			} = await apiCms.get(`/catalogue/company/${profile.id}`);

			setCatalogData(catalogue);
		} catch (error) {
			console.log(error);
		}
	};

	const fetchDetails = async () => {
		try {
			setIsLoading(true);
			await fetchProducts();
			// await fetchWarehouses();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (profile?.id) fetchDetails();
	}, []);

	const handleBillingAddressForm = (event) => {
		setShowBillingForm(!event.target.checked);
	};
	const onFinish = async (formValues) => {
		try {
			setIsSubmitButtonLoading(true);
			let {
				items,
				address,
				billingAddress: billing,
				shippingAddress: shipping,
				paymentMethod,
				awb,
				awbPartner,
				shippingLabelUrl,
				invoiceUrl,
				id,
				shippingPrice,
				ewayBill,
				backdate,
				purchaseOrderNumber
			} = formValues;

			if (!items?.length) throw new Error('Please select atleast 1 product');
			items = populateProductItems(items, catalogData);
			const { shippingAddress, billingAddress, customer } = fetchAddresses(shipping, billing);

			const formData = {
				data: {
					shopType: formValues.awb?.length ? SHOP_TYPE.MANUAL_FBW : SHOP_TYPE.MANUAL,
					items,
					companyId: profile.id,
					paymentMethod,
					weightUnit: 'g',
					shippingAddress,
					billingAddress,
					customer,
					eventType: 'create',
					awb,
					awbPartner,
					shippingLabelUrl,
					invoiceUrl,
					id,
					shippingPrice,
					ewayBill,
					backdate,
					purchaseOrderNumber
				}
			};
			const {
				data: { status, message }
			} = await createOrder(formData);

			if (status) {
				notification.success({
					description: 'Order created successfully',
					position: 'topRight'
				});

				// history.push('/orders');
				window.location.replace('/orders');
			} else {
				throw new Error(message);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsSubmitButtonLoading(false);
		}
	};

	return (
		<Row className={`h-100 p-0 ${styles.manualOrder}`}>
			<Col span={4} className={styles.sidebar}>
				<Button className={styles.backButton} icon={<ArrowLeftOutlined />} onClick={redirectToHomePage}>
					Go back
				</Button>
			</Col>

			<Col span={20} className={styles.outerContainer}>
				<div className={styles.innerContainer}>
					<h3>Create new order</h3>
					<br />

					<Form form={form} layout="vertical" onFinish={onFinish} scrollToFirstError>
						<OrderDetails catalogData={catalogData} customStyle={styles.cardStyle} />

						<ManualOrderAddressForm
							formName={IManualOrderFormName.SHIPPING_ADDRESS}
							customStyle={styles.cardStyle}
							editAddress={editShippingAddress}
							handleBillingAddressForm={handleBillingAddressForm}
							formHeading={IManualOrderFormName.SHIPPING_DETAILS}
						/>
						{showBillingForm && (
							<ManualOrderAddressForm
								formName={IManualOrderFormName.BILLING_ADDRESS}
								customStyle={styles.cardStyle}
								editAddress={editBillingAddress}
								formHeading={IManualOrderFormName.BILLING_ADDRESS_HEADING}
							/>
						)}
						{/* <PickupDetails warehouses={warehouses} customStyle={styles.cardStyle} /> */}

						<OtherDetails customStyle={styles.cardStyle} />

						<FulfilllmentDetails customStyle={styles.cardStyle} />

						<Button type="primary" size="large" htmlType="submit" loading={isSubmitButtonLoading}>
							Submit
						</Button>
					</Form>
				</div>
			</Col>
		</Row>
	);
};
