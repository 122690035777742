// Delivery Partner Body should be all lower case
export const deliveryPartnersWithBody = [
	'goswift',
	'pickrr',
	'shiprocket',
	'shipway',
	'shyplitelite',
	'shyplitesurface',
	'shypliteair',
	'clickpost'
];

export const deliveryPartnerFields = {
	goswift: [
		{ label: 'Client ID', name: 'clientId' },
		{ label: 'Username', name: 'username' },
		{ label: 'Password', name: 'password' }
	],
	pickrr: [{ label: 'Auth Token', name: 'password' }],
	shipRocket: [
		{ label: 'Email', name: 'email' },
		{ label: 'Password', name: 'password' }
	],
	shipway: [
		{ label: 'Email', name: 'email' },
		{ label: 'Licence key', name: 'licenceKey' }
	],
	shypliteLite: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	shypliteSurface: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	shypliteAir: [
		{ label: 'Seller ID', name: 'clientId' },
		{ label: 'App ID', name: 'appId' },
		{ label: 'Public Key', name: 'username' },
		{ label: 'App Secret', name: 'password' }
	],
	clickPost: [
		{ label: 'UserName', name: 'username' },
		{ label: 'Seller ID', name: 'clientId' }
	]
};
