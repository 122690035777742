import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import errorHandler from 'shared/utils/errorHandler';
import styles from './index.module.scss';

/**
 *
 * @param modalTitle (Title of the modal)
 * @param instructionMessage (Instruction for bulk upload)
 * @param templatePath (Path for downloading sample file)
 * @param previewPath (Path for preview uploaded File)
 * @param isModalVisible (Flag for modal should visible or not)
 * @param setIsModalVisible (Function to set visible flag)
 *
 * @returns
 */
export const UploadBulkModal = ({
	modalTitle,
	instructionMessage,
	templatePath,
	previewPath,
	isModalVisible,
	setIsModalVisible,
	companyId
}) => {
	const [excelFile, setExcelFile] = useState(null);
	const history = useHistory();

	const handleCloseModal = () => setIsModalVisible(false);

	const handleDownloadSample = () => {
		window.open(templatePath, 'blank');
	};

	const deleteFile = () => {
		setExcelFile(null);
	};

	/**
	 * Called When user upload ean excel file
	 */
	const handleFileChange = (e) => {
		try {
			const file = e.target?.files[0];

			if (!file) {
				throw new Error('No file selected');
			}

			setExcelFile(file);
		} catch (error) {
			errorHandler(error);
		}
	};

	/**
	 * To Preview excel file
	 */
	const previewFile = async () => {
		try {
			if (!excelFile) {
				throw new Error('Please select a file to continue');
			}
			history.push({
				pathname: previewPath,
				state: { file: excelFile, ...(companyId && { companyId }) }
			});
		} catch (error) {
			errorHandler(error);
		}
	};

	return (
		<Modal
			title={modalTitle}
			visible={isModalVisible}
			onCancel={handleCloseModal}
			// okText="Submit"
			// confirmLoading={isLoading}
			// onOk={submitForm}
			okText="Preview File"
			onOk={previewFile}
			centered
			className={styles.uploadBulkModal}
		>
			<h5 className={styles.heading}>Instructions to upload</h5>

			{/* Instruction Part */}
			{instructionMessage}

			<div className={styles.buttonsContainer}>
				<Button onClick={handleDownloadSample}>Download Sample</Button>

				{excelFile?.name ? (
					<div className={styles.fileDetails}>
						<p className={styles.fileName}>{excelFile.name}</p>
						<Button danger icon={<DeleteOutlined />} onClick={deleteFile} />
					</div>
				) : (
					<label htmlFor="bulk-upload" className={styles.uploadLabel}>
						Upload Excel
						<input
							id="bulk-upload"
							className={styles.uploadButton}
							size="60"
							accept=".xlsx"
							type="file"
							onChange={handleFileChange}
							value={excelFile ? excelFile : ''}
						/>
					</label>
				)}
			</div>
		</Modal>
	);
};
