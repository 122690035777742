export default (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.5556 13.8031L11.331 12.0276C11.6737 11.685 12.2127 11.6373 12.6102 11.9145L14.9583 13.5522C15.4159 13.8714 15.4738 14.5265 15.0794 14.921C14.4428 15.5576 13.5287 15.834 12.6757 15.5463C11.0902 15.0116 8.4243 13.8556 6.19632 11.6276C3.96833 9.3996 2.81234 6.73375 2.27762 5.14823C1.98994 4.29523 2.26635 3.38109 2.90289 2.74455C3.29738 2.35006 3.95255 2.40799 4.27169 2.86558L5.90939 5.21371C6.1866 5.61118 6.13894 6.15021 5.79628 6.49287L4.02085 8.2683"
			fill="#55BA4D"
		/>
		<path
			d="M9.5556 13.8031L11.331 12.0276C11.6737 11.685 12.2127 11.6373 12.6102 11.9145L14.9583 13.5522C15.4159 13.8714 15.4738 14.5265 15.0794 14.921V14.921C14.4428 15.5576 13.5287 15.834 12.6757 15.5463C11.0902 15.0116 8.4243 13.8556 6.19631 11.6276C3.96833 9.3996 2.81234 6.73375 2.27762 5.14823C1.98994 4.29523 2.26635 3.38109 2.90289 2.74455V2.74455C3.29738 2.35006 3.95255 2.40799 4.27169 2.86558L5.90939 5.21371C6.1866 5.61118 6.13894 6.15021 5.79628 6.49287L4.02085 8.2683"
			stroke="#55BA4D"
			stroke-linecap="round"
		/>
	</svg>
);
