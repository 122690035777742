export default (
	<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.26292 5.22325H14.0161V8.03299H15.279V5.18273C15.279 4.75046 15.0448 4.35871 14.659 4.14707L13.3226 0.909561C13.093 0.355716 12.5511 0 11.9403 0H3.61424C3.031 0 2.49828 0.337712 2.25488 0.860036L0.771527 4.07954C0.3031 4.25514 0 4.68741 0 5.17822V14.544C0 15.2014 0.546499 15.7328 1.21699 15.7328H7.50862V14.4945H1.26292V5.22325ZM2.19518 3.98498L3.40299 1.36435C3.43973 1.2878 3.52239 1.23376 3.61424 1.23376H7.16418V3.98498H2.19518ZM8.42251 3.98498V1.23376H11.9449C12.0367 1.23376 12.1194 1.2878 12.1561 1.37335L13.2308 3.98498H8.42251Z"
			fill="#231F20"
		/>
		<path
			d="M11.3387 10.8788H10.3926L11.3938 9.8296C11.5086 9.70802 11.5683 9.55043 11.5637 9.38833C11.5591 9.22173 11.4856 9.06862 11.3662 8.95605C11.1136 8.72191 10.7141 8.73543 10.4753 8.98308L8.47758 11.0769C8.24337 11.32 8.24796 11.6983 8.49136 11.9369L10.4891 13.9091C10.7279 14.1433 11.1412 14.1433 11.38 13.9091C11.628 13.6705 11.628 13.2742 11.3846 13.0356L10.4569 12.1215H11.3433C13.2124 12.1215 14.7325 13.6119 14.7325 15.4446V16.4352C14.7325 16.7774 15.0172 17.0566 15.3662 17.0566C15.7152 17.0566 16 16.7774 16 16.4352V15.4446C15.9908 12.923 13.9058 10.8788 11.3387 10.8788Z"
			fill="#231F20"
		/>
	</svg>
);
