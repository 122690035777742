import { ErrorBoundary } from '@sentry/react';
import { AppRoutes as AppAdminRoutes } from 'app/AppAdmin/index.js';
import { AppRoutes as AppLightningRoutes } from 'app/AppLightning/index.js';
import { AppRoutes as AppSellerRoutes } from 'app/AppSeller/index.js';
import { AppRoutes as AppWmsRoutes } from 'app/AppWms/index.js';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Loading } from 'shared/components/Loading';
import { PrivateAppRoute, PrivateAuthRoute } from 'shared/components/PrivateRoute';
import { AuthContext } from 'shared/contexts/Auth';
import { envs } from 'shared/utils/env';
import { Fallback } from './AppSeller/ErrorBoundaryFallback';
import { CustomerRequest } from './CustomerRequest';
import { VerifyOtp, verifyOtpRoute } from './AppSeller/VerifyOtp';
import { Auth } from './Auth';
import { forgotPasswordRoute } from './Auth/ForgotPassword';
import { loginRoute } from './Auth/Login';
import { signupRoute } from './Auth/SignUp';
import { TrackDetails, trackRoute } from './Track/TrackDetails/TrackDetails';
import { TrackLanding, trackLandingRoute } from './Track/TrackLanding';
import { useNavigatorOnLine } from '../shared/hooks/useNavigator';
import { Alert } from 'antd';

const AppRoutes = { AppSeller: AppSellerRoutes, AppWms: AppWmsRoutes, AppAdmin: AppAdminRoutes, AppLightning: AppLightningRoutes }[
	envs.appType
];
const AppComponent = {
	AppSeller: lazy(() => import('./AppSeller')),
	AppWms: lazy(() => import('./AppWms')),
	AppAdmin: lazy(() => import('./AppAdmin')),
	AppLightning: lazy(() => import('./AppLightning'))
}[envs.appType];

export function App() {
	const [loading, setLoading] = useState(false);
	const { authActions, authState } = useContext(AuthContext);
	const isOnline = useNavigatorOnLine();
	useEffect(() => {
		if (!authState.authLoaded) {
			setLoading(true);
			authActions.authenticateUser().finally(() => setLoading(false));
		}
	}, [authState.authLoaded]);

	return (
		<>
			<Loading loading={loading} />
			{!isOnline && (
				<Alert
					style={{ textAlign: 'center', fontWeight: 'bold', background: 'tomato', border: 'none' }}
					message="It seems like you are offline"
					type="warning"
					closable
				/>
			)}
			{authState.authLoaded && (
				<Router>
					<Switch>
						<Route exact path={trackLandingRoute}>
							<TrackLanding />
						</Route>
						<Route exact path={'/feedback/:token'}>
							<CustomerRequest />
						</Route>
						<Route exact path={trackRoute}>
							<TrackDetails setLoading={setLoading} loading={loading} />
						</Route>
						<Route exact path={verifyOtpRoute}>
							<VerifyOtp />
						</Route>

						<PrivateAuthRoute exact path={[signupRoute, loginRoute, forgotPasswordRoute, '/']}>
							<Auth />
						</PrivateAuthRoute>

						<PrivateAppRoute exact path={AppRoutes}>
							<Suspense fallback={<Loading />}>
								<ErrorBoundary fallback={<Fallback />}>
									<AppComponent />
								</ErrorBoundary>
							</Suspense>
						</PrivateAppRoute>
						<Redirect from="*" to="/" />
					</Switch>
				</Router>
			)}
		</>
	);
}
