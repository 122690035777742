import { Button } from 'antd';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { envs } from 'shared/utils/env';
import { searchOptions } from './constants';
import exportSvg from 'shared/svgs/export_svg';
const { cmsBaseUrl } = envs;

export const sidebarUtil = (warehouses = []) => {
	return {
		sideBarMenu: warehouses.map(({ warehouseId, profile: { name } }) => ({ key: warehouseId, label: name, icon: 'allSvgIcon' }))
	};
};

export const topFilterUtil = ({ sidebarMenuItem, filter, setFilter, setPageConfigDefault, setBulkInventoryUploadModal, isWMS }) => {
	const onDownloadOrders = () => window.open(`${cmsBaseUrl}/inventory/bulk/csv/${sidebarMenuItem}?appType=${envs.appType}`, '_blank');
	return {
		row1: [], // required to render row1Children
		row1children: [
			<div>
				<DebouncedCompactSearch
					defaultSearchOptionType={filter.searchOptionType}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
				>
					<label>Search By:</label>
				</DebouncedCompactSearch>
			</div>
		],
		row2: [
			<div style={{ display: 'flex', width: '100%' }}>
				{isWMS ? (
					<Button type="primary" onClick={() => setBulkInventoryUploadModal(true)} style={{ marginRight: '.4rem' }}>
						<span>Add Inventory</span>
					</Button>
				) : null}

				<Button type="primary" onClick={onDownloadOrders}>
					<span>{exportSvg}</span>
					<span style={{ marginLeft: '.4rem', fontSize: '0.9rem' }}>Export</span>
				</Button>
			</div>
		]
	};
};
