import Icon, { CaretRightOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import styles from './index.module.scss';
import { ICON_MAPPER } from './utils';
import amplitude from '../../../../shared/utils/Amplitude';

const { SubMenu } = Menu;

// Render sub menu if section name and component list is provided else render normal menu
export const Sidebar = (props) => {
	const {
		sideBarMenu = [],
		bottomButtons = null,
		selectedMenu,
		isTrackingLayout,
		setIsTrackingLayout,
		onMenuItemSelect,
		topComponent = null
	} = props;
	const collapseSideDrawer = () => {
		setIsTrackingLayout((e) => (e === 1 ? 4 : 1));
	};

	return (
		<>
			<div className={styles.mainSlideBarContainer}>
				{/* <Input.Search placeholder="Search Dashboard" className={styles.searchInput} /> */}
				{topComponent && <div>{topComponent}</div>}
				<Menu mode="inline" className={styles.menu} selectedKeys={[String(selectedMenu)]}>
					{isTrackingLayout && (
						<div onClick={collapseSideDrawer} className={styles.rotatableIcon}>
							<CaretRightOutlined className={isTrackingLayout === 4 && styles.rotateIt} />
						</div>
					)}
					{sideBarMenu.map(({ key, icon, label, sectionName, componentList }) => {
						if (sectionName && componentList) {
							return (
								<SubMenu
									key={sectionName}
									title={
										<span>
											<Icon type="home" />
											{sectionName}
										</span>
									}
								>
									{componentList.map(({ key: childKey, icon: childIcon, label: childLabel }) => (
										<Menu.Item
											key={childKey}
											icon={<Icon component={() => ICON_MAPPER[childIcon]} />}
											className={styles.menuItem}
											onClick={() => {
												amplitude.getInstance().logEvent(`Clicked on ${childLabel} in Orders`);
												onMenuItemSelect(childKey);
											}}
										>
											{childLabel}
										</Menu.Item>
									))}
								</SubMenu>
							);
						}
						return (
							<Menu.Item
								key={key}
								icon={<Icon component={() => ICON_MAPPER[icon]} />}
								className={styles.menuItem}
								onClick={() => {
									amplitude.getInstance().logEvent(`Clicked on ${label} in Orders`);
									onMenuItemSelect(key);
								}}
							>
								{label}
							</Menu.Item>
						);
					})}
				</Menu>
				<div className={styles.bottomButtons}>{bottomButtons}</div>
			</div>
		</>
	);
};
