import React, { useEffect, useState } from 'react';
import { Button, Tag, Row, Tooltip } from 'antd';
import { images as channelImages } from 'shared/utils/channel';
import { orderStatus, orderStatusColor } from 'shared/utils/orderStatus';
import { isSortField } from 'shared/utils/table';
import styles from './index.module.scss';
import { getTrackingUrl } from 'utils/delivery/getTrackingUrl';
import { FulfillmentProviders, ORDER_STATUS } from '../../../constants/index';
import RTOModal from '../RTOApproval/components/RTOModal';
import { obtainProfileData } from 'shared/api/profile';
import { commonColumns } from 'shared/utils/columns';

const getDeliveryPartnerColor = (deliveryPartner) => {
	deliveryPartner = deliveryPartner?.toLowerCase();
	let color;

	switch (deliveryPartner) {
		case 'dtdc':
			color = 'orange';
			break;

		case 'xpressbees':
			color = 'cyan';
			break;

		default:
			color = 'gold';
			break;
	}
	return color;
};

export const useColumns = ({
	clickOrderId,
	sortField,
	sortOrder,
	clickProductId = () => {
		return;
	}
}) => {
	const [companyProfileData, setCompanyProfileData] = useState();

	useEffect(() => {
		const fetchProfileData = async () => {
			const data = await obtainProfileData();
			return data;
		};

		fetchProfileData().then((data) => {
			setCompanyProfileData(data.data.responseBody);
		});
	}, []);

	const getModalApprove = (status, order) => {
		if (status === ORDER_STATUS.RTO_DELIVERED || status === ORDER_STATUS.DTO_DELIVERED) {
			return (
				<RTOModal
					orderData={order}
					button={status === ORDER_STATUS.RTO_DELIVERED ? 'Approve RTO' : 'Approve DTO'}
					companyProfileData={companyProfileData}
				/>
			);
		}
	};

	const getModalDetails = (status, order) => {
		if (status === ORDER_STATUS.RTO_PROCESSED || status === ORDER_STATUS.DTO_PROCESSED) {
			return (
				<RTOModal
					orderData={order}
					button={status === ORDER_STATUS.RTO_PROCESSED ? 'RTO Details' : 'DTO Details'}
					companyProfileData={companyProfileData}
				/>
			);
		}
	};

	return [
		commonColumns.createAt(sortField, sortOrder),
		{
			title: 'Order Id',
			dataIndex: 'shopOrderId',
			render: (v, order) => (
				<>
					<Row justify="center">
						<Button
							type="link"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								clickOrderId(order.id);
							}}
							className={styles.wFull}
						>
							<Tooltip placement="top" title={order.shopOrderId || order.externalOrderId || order.id}>
								<span className={styles.ellipsis}>{order.shopOrderId || order.externalOrderId || order.id}</span>
							</Tooltip>
						</Button>
					</Row>
					<Row justify="center">
						<Tag color="geekblue">{order.paymentMode}</Tag>
					</Row>
				</>
			),
			width: 150,
			sorter: true,
			sortOrder: isSortField(sortField, sortOrder, 'shopOrderId')
		},
		commonColumns.staticRenderFieldWithSortOption('Channel', 'shopType', sortField, sortOrder, true, channelImages, 100),
		{
			title: 'Product Info',
			dataIndex: 'items',
			render: (obj) => (
				<div>
					{obj?.length
						? obj.map((v, order) => (
								<div className="w-100 p-relative float-left" key={v.id}>
									<div className="text-left">{v.title}</div>
									<div>
										<Button
											type="link"
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												clickProductId(v.catalogueId);
											}}
											className="float-left"
										>
											{v.sku}
										</Button>
										<span className="float-right">{v.quantity}</span>
									</div>
								</div>
						  ))
						: '-'}
				</div>
			),
			width: 150
		},
		{
			title: 'Pickup Address',
			key: 'pickupAddress',
			render: (order, status) => (
				<div className={styles.pickupAddress}>
					{
						<>
							<div
								style={{ margin: '0 auto', overflowWrap: 'anywhere' }}
							>{`${order.pickupAddress?.firstName}, ${order.pickupAddress?.city}, ${order.pickupAddress?.pincode}`}</div>
							<div className="w-100">
								{order.deliveryAwb && status != ORDER_STATUS.RTS_FAILED && (
									<Button
										type="link"
										onClick={() => {
											window.open(
												getTrackingUrl(FulfillmentProviders.WHEREHOUSE_LIGHTNING, order.deliveryAwb),
												'_blank'
											);
										}}
									>
										AWB: {order.deliveryAwb}
									</Button>
								)}
								{order.deliveryPartnerId && (
									<Tag className={`${styles.tag} mt-1`} color={getDeliveryPartnerColor(order.deliveryPartnerId)}>
										{order.deliveryPartnerId}
									</Tag>
								)}
								<br />
								{order.shipper && (
									<Tag className={`${styles.tag} mt-1`} color={getDeliveryPartnerColor(order.deliveryPartnerId)}>
										{order.shipper}
									</Tag>
								)}
							</div>
						</>
					}
				</div>
			),
			width: 150
		},
		{
			title: 'Customer Address',
			dataIndex: 'customerAddress',
			width: 150,
			render: (obj) => (obj ? `${obj.firstName} ${obj.lastName}, ${obj.city}, ${obj.state}, ${obj.pincode}` : '-')
		},
		{
			title: 'Status',
			dataIndex: 'status',
			render: (status, order) => (
				<>
					{status ? (
						<div
							style={{
								color: orderStatusColor[status]
							}}
							className="text-center"
						>
							{orderStatus[status] || status}
							{getModalDetails(status, order)}
							{getModalApprove(status, order)}
						</div>
					) : (
						<div className="text-center">-</div>
					)}
				</>
			),
			width: 100,
			sorter: true,
			sortOrder: isSortField(sortField, sortOrder, 'status')
		}
	];
};
