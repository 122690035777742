import processedIcon from '../../shared/svgs/trackingPageSvg/processed_icon.svg';
import rtsIcon from '../../shared/svgs/trackingPageSvg/rts_icon.svg';
import shippedIcon from '../../shared/svgs/trackingPageSvg/shipped.svg';
import outForDeliveryIcon from '../../shared/svgs/trackingPageSvg/ofd.svg';
import undeliveredIcon from '../../shared/svgs/trackingPageSvg/undelivered.svg';
import inTransitIcon from '../../shared/svgs/trackingPageSvg/in_transit.svg';
import deliveredIcon from '../../shared/svgs/trackingPageSvg/delivered_icon.svg';
import cancelledIcon from '../../shared/svgs/trackingPageSvg/canceled_icon.svg';
import commonIcon from '../../shared/svgs/trackingPageSvg/common_trail_svg.svg';
import dayjs from 'dayjs';
import { FulfillmentProviders, ORDER_STATUS } from '../../constants';

const TrackingTrailLogo = Object.freeze({
	[ORDER_STATUS.PLACED]: processedIcon,
	[ORDER_STATUS.READY_TO_SHIP]: rtsIcon,
	[ORDER_STATUS.SHIPPED]: shippedIcon,
	[ORDER_STATUS.OUT_FOR_DELIVERY]: outForDeliveryIcon,
	[ORDER_STATUS.IN_TRANSIT]: inTransitIcon,
	[ORDER_STATUS.UNDELIVERED]: undeliveredIcon,
	[ORDER_STATUS.DELIVERED]: deliveredIcon,
	[ORDER_STATUS.CANCELLED]: cancelledIcon,
	[ORDER_STATUS.LOST]: undeliveredIcon
});

export function estimatedDeliveryDateHandler(data, deliveryService) {
	let deliveredDate = data?.timeline.filter((e) => e.status === 'DELIVERED')[0]?.createdAt;
	const placedDate = data?.timeline.filter((e) => e.status === 'PLACED')[0]?.createdAt;
	if (deliveredDate) {
		deliveredDate = dayjs(deliveredDate);
		return {
			day: deliveredDate.date(),
			month: deliveredDate.format('MMMM'),
			year: deliveredDate.year()
		};
	}
	let result;
	if (deliveryService === FulfillmentProviders.WHEREHOUSE_LIGHTNING) {
		if (dayjs(placedDate).add(1, 'day').day() === 0) {
			result = dayjs(placedDate).add(2, 'days').format();
		} else {
			result = dayjs(placedDate).add(1, 'day').format();
		}
	} else {
		result = dayjs(placedDate).add(5, 'days').format();
	}
	let finalResult = dayjs(result);
	return {
		day: finalResult.date(),
		month: finalResult.format('MMMM'),
		year: finalResult.year()
	};
}

export function commonIconHandler(status) {
	return TrackingTrailLogo[status] ? TrackingTrailLogo[status] : commonIcon;
}
