import { Result, Button, Row, Col, Divider } from 'antd';

export const ErrorComponent = ({ previewConfigName, routeName, errorsArray, successArray }) => {
	return (
		<Result
			status="error"
			title={`Errors happened while executing in some of the ${previewConfigName}!`}
			extra={[
				<>
					<Row style={{ marginBottom: '40px' }}>
						<Col span={24}>
							<Col span={24}>
								<b style={{ color: 'red' }}>Failed Orders: </b>
							</Col>
							<div style={{ display: 'inline-block', width: '100%', maxWidth: '1000px', marginTop: '1rem' }}>
								{errorsArray.map((data) => {
									return (
										<Row>
											<Col span={12}>
												<b>ID:</b> {data.orderId}
											</Col>
											<Col span={12}>
												<b>Error:</b> {data.message}
											</Col>
										</Row>
									);
								})}
							</div>
						</Col>
						<Divider />
						<Col span={24}>
							<Col span={24}>
								<b style={{ color: 'green' }}>{successArray && successArray.length ? 'Successful Orders:' : ''} </b>
							</Col>
							{successArray.map((data) => {
								return (
									<>
										<Col span={24}>
											<b>ID:</b> {data.orderId}
										</Col>
									</>
								);
							})}
						</Col>
					</Row>

					<Button type="primary" key="console" href={routeName}>
						{`Go to ${previewConfigName} page`}
					</Button>
				</>
			]}
		/>
	);
};
