import { PAYMENT_METHOD, STATUS } from 'constants/index';
import { v4 as uuidv4 } from 'uuid';
import { fieldNamesMap, fieldNameToColumnAlphabetMap, numericFieldsMap, validationMap, validationFirstIndexMap } from './constants';

/**
 * This function will return rowsData for table
 * In this function previewConfigName is for which type of bulk upload is this ( orders / catalogues ) (So This will be fetched from route URL)
 * @param {*} previewConfigName ( Config name is which type of bulk upload is this ) ( orders / catalogues)
 * @returns
 */
const getUpdatedRowData = (row, tableColumns) => {
	const updatedRowData = { key: uuidv4() };

	tableColumns.forEach(({ dataIndex }, index) => {
		updatedRowData[dataIndex] = { value: row[index], errorMessage: null };
	});

	return updatedRowData;
};

/**
 * This Function is used to fetch index of that row from where we have to perform any action ( above that index all rows is used for instructions)
 * In this function previewConfigName is for which type of bulk upload is this ( orders / catalogues ) (So This will be fetched from route URL)
 * @param {*} excelData ( Excel Data after reading excel file )
 * @param {*} previewConfigName ( Config name is which type of bulk upload is this ) ( orders / catalogues)
 * @returns
 */
const getFieldNamesRowIndex = (excelData, previewConfigName) => {
	try {
		for (let rowIndex = 0; rowIndex < excelData.length; rowIndex++) {
			const rowData = excelData[rowIndex];
			const shouldCheckCurrentRow = String(rowData[0])?.toLowerCase() === validationFirstIndexMap[previewConfigName];
			if (!shouldCheckCurrentRow) continue;

			for (let colIndex = 0; colIndex < rowData.length; colIndex++) {
				const element = rowData[colIndex];
				if (element?.toLowerCase() !== fieldNamesMap[previewConfigName][colIndex]?.toLowerCase()) {
					throw new Error(`Excel upload failed. Missing column name ${fieldNamesMap[previewConfigName][colIndex]}!`);
				}
			}

			return rowIndex;
		}
		return null;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

/**
 * This function will return columnData and rowsData for table
 * In this function previewConfigName is for which type of bulk upload is this ( orders / catalogues ) (So This will be fetched from route URL)
 * @param {*} excelData ( Excel Data after reading excel file )
 * @param {*} previewConfigName ( Config name is which type of bulk upload is this ) ( orders / catalogues)
 * @returns
 */
export const sanitizeExcelData = (excelData, previewConfigName) => {
	try {
		const fieldNamesRowIndex = getFieldNamesRowIndex(excelData, previewConfigName);

		if (fieldNamesRowIndex === null) throw new Error('Excel upload failed. Missing column names!');

		const tableColumns = generateTableColumns(previewConfigName);
		const tableRows = excelData.slice(fieldNamesRowIndex + 1).map((row) => getUpdatedRowData(row, tableColumns));

		return { tableRows, tableColumns };
	} catch (error) {
		console.log(error);
		throw error;
	}
};

/**
 * This function will return columnData for table
 * In this function previewConfigName is for which type of bulk upload is this ( orders / catalogues ) (So This will be fetched from route URL)
 * @param {*} previewConfigName ( Config name is which type of bulk upload is this ) ( orders / catalogues)
 * @returns
 */
export const generateTableColumns = (previewConfigName) => {
	const fieldNames = Object.keys(fieldNameToColumnAlphabetMap[previewConfigName]);

	const tableColumns = fieldNames.map((fieldName) => ({
		title: <h6 style={{ textTransform: 'capitalize', margin: '0.5rem 1rem' }}>{fieldName}</h6>,
		dataIndex: fieldName
	}));

	return tableColumns;
};

export const shouldConvertToNumber = (fieldName, previewConfigName) => numericFieldsMap[previewConfigName].includes(fieldName);

export const sanitizeData = (currentPreviewItem, previewConfigName) => {
	const updatedPreviewData = currentPreviewItem.map((currentPreviewItem) => {
		const updatedCurrentPreviewItem = {};

		for (const [fieldName, fieldData] of Object.entries(currentPreviewItem)) {
			const updatedKeyName = fieldNameToColumnAlphabetMap[previewConfigName][fieldName];
			if (updatedKeyName) {
				const { value } = fieldData;
				if (fieldName === 'status') {
					updatedCurrentPreviewItem[updatedKeyName] = STATUS[value.toUpperCase()];
				} else if (fieldName === 'paymentMethod') {
					updatedCurrentPreviewItem[updatedKeyName] = PAYMENT_METHOD[value.toUpperCase()];
				} else if (fieldName === 'itemPrice') {
					updatedCurrentPreviewItem[updatedKeyName] = isNaN(parseInt(value)) ? null : Number(value);
				} else {
					updatedCurrentPreviewItem[updatedKeyName] = shouldConvertToNumber(fieldName, previewConfigName) ? Number(value) : value;
				}
			}
		}

		return updatedCurrentPreviewItem;
	});

	return updatedPreviewData;
};

/**
 * This function will use for validation of each rows and each fields
 * In this function previewConfigName is for which type of bulk upload is this ( orders / catalogues ) (So This will be fetched from route URL)
 * @param {*} previewConfigName ( Config name is which type of bulk upload is this ) ( orders / catalogues)
 * @param {*} previewData ( previewData contains all rows )
 * @returns
 */
export const validateData = (previewData, previewConfigName) => {
	let doesErrorExists = false;

	const validateField = (previewItem) => {
		for (const [fieldName, fieldData] of Object.entries(previewItem)) {
			if (fieldName === 'key') continue; // no validation required for "key"

			let { value, errorMessage } = fieldData;
			if (!errorMessage) errorMessage = validationMap[previewConfigName](value, fieldName);

			if (errorMessage) doesErrorExists = true;

			previewItem[fieldName] = { value, errorMessage };
		}
		return previewItem;
	};

	const validatedData = previewData.map(validateField);

	return { validatedData, doesErrorExists };
};

export const getFirstErrorField = (previewData) => {
	for (let row = 0; row < previewData.length; row++) {
		const rowData = previewData[row];

		for (const [columnName, columnData] of Object.entries(rowData)) {
			if (columnData.errorMessage) {
				return { errorRowKey: rowData.key, errorColumnName: columnName };
			}
		}
	}

	return {};
};
