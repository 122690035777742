import { Select, Input, Col } from 'antd';
import { defaultPageConfig } from '../../../constants';
import reactDom from 'react-dom';
import debounce from 'utils/debounce';
import { RFM_BADGE } from './constants';
import { searchOptions } from './constants';
import errorHandler from 'shared/utils/errorHandler';
import { envs } from 'shared/utils/env';
const { Option } = Select;
const { omsBaseUrl } = envs;

export const getChartConfig = (categories, series_orders, series_revenue) => {
	return {
		options: {
			theme: {
				mode: 'light',
				palette: 'palette1',
				monochrome: {
					enabled: false,
					color: '#008FFB',
					shadeTo: 'light',
					shadeIntensity: 0.65
				}
			},
			grid: {
				show: false
			},
			title: {
				text: 'Monthly Orders & Revenue',
				align: 'left',
				margin: 10,
				offsetX: 0,
				offsetY: 0,
				floating: false,
				style: {
					fontSize: '14px',
					fontWeight: 'bold',
					fontFamily: undefined,
					color: '#263238'
				}
			},
			chart: {
				animations: {
					enabled: true,
					easing: 'easeinout',
					speed: 800,
					animateGradually: {
						enabled: true,
						delay: 150
					},
					dynamicAnimation: {
						enabled: true,
						speed: 350
					}
				}
			},

			yaxis: [
				{
					title: {
						text: 'Orders'
					}
				},
				{
					opposite: true,
					title: {
						text: 'Revenue'
					}
				}
			],
			xaxis: {
				categories
			}
		},
		series: [
			{
				name: 'Orders',
				data: series_orders
			},
			{
				name: 'Revenue (₹)',
				data: series_revenue
			}
		]
	};
};

export const badgeData = (count, setFilter, filter) => [
	{ color: '#ffd700', count: count?.Gold, badge: RFM_BADGE.GOLD, setFilter, filter },
	{ color: '#BBC2CC', count: count?.Silver, badge: RFM_BADGE.SILVER, setFilter, filter },
	{ color: '#cd7f32', count: count?.Bronze, badge: RFM_BADGE.BRONZE, setFilter, filter }
];

export const downloadCustomersCSV = async (params) => {
	try {
		for (let key in params) {
			if (!params[key]) delete params[key];
		}
		const url = new URL(`${omsBaseUrl}/customers/csv`);
		url.search = new URLSearchParams(params);

		window.open(url);
	} catch (error) {
		errorHandler(error);
	}
};

export const sidebarUtil = () => ({
	sideBarMenu: [
		{
			key: '1',
			label: 'Insights',
			icon: 'insightSvgIcon'
		},
		{
			key: '2',
			label: `RFM (Recency, Frequency & Monetary)`,
			icon: 'allSvgIcon'
		}
	]
});

export const revenueCard = (totalRevenue, revenueThisMonth) => [
	{
		title: `₹ ${totalRevenue ? totalRevenue?.toFixed(2) : 0}`,
		description: 'Total Revenue'
	},
	{
		title: `₹ ${revenueThisMonth ? revenueThisMonth?.toFixed(2) : 0}`,
		description: 'Total Revenue (Last 30 days)'
	}
];

export const salesCard = (totalSales, salesThisMonth) => [
	{
		title: `${totalSales ? totalSales : 0}`,
		description: 'Total Sales'
	},
	{
		title: `${salesThisMonth ? salesThisMonth : 0}`,
		description: 'Total Sales (Last 30 days)'
	}
];

export const topFilterUtil = ({
	count,
	filter,
	setFilter,
	search,
	setSearch,
	pageConfig,
	selectedRowKeys,
	setPageConfig,
	setPageConfigDefault
}) => {
	const handleStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};
	const debouncedSearchChange = debounce((value) => {
		reactDom.unstable_batchedUpdates(() => {
			setPageConfig(defaultPageConfig);
			setFilter((prevFilter) => ({ ...prevFilter, searchValue: value }));
		});
	}, 500);

	const onSearchChange = ({ target: { value } }) => {
		setSearch(value);
		debouncedSearchChange(value);
	};
	const handleSearchOptionChange = (updatedSearchOptionType) => {
		setPageConfigDefault();
		setSearch('');
		setFilter((prevFilter) => ({ ...prevFilter, searchOptionType: updatedSearchOptionType, searchValue: '' }));
	};
	return {
		row1children: [
			<Col>
				<div>
					<label>Search By:</label>
					<Input.Group compact>
						<Select defaultValue={filter?.searchOptionType} onChange={handleSearchOptionChange} style={{ minWidth: '150px' }}>
							{searchOptions.map(({ label, value }) => (
								<Option value={value}>{label}</Option>
							))}
						</Select>
						<Input style={{ width: '150px' }} value={search} allowClear onChange={onSearchChange} placeholder="Enter value" />
					</Input.Group>
				</div>
			</Col>,

			<div>
				<label>Status:</label>
				<Select
					mode="multiple"
					placeholder="Select status"
					maxTagCount="responsive"
					style={{ width: '100%' }}
					allowClear
					value={filter?.status}
					onChange={handleStatusChange}
				>
					{Object.values(RFM_BADGE).map((badge) => {
						//const count = counts[orderStatus] || 0;
						return <Option key={badge}>{badge}</Option>;
					})}
				</Select>
			</div>
		]
	};
};
