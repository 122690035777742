import React from 'react';
import { Card } from 'antd';
import styles from '../index.module.scss';
import amplitude from '../../../../shared/utils/Amplitude';
const BadgeCard = ({ badgeData }) => {
	return badgeData.map(({ color, count, badge, setFilter, filter }) => {
		let boxShadow = filter === badge ? `0px 20px 30px 0px ${color}` : '';
		return (
			<Card
				hoverable
				onClick={() => {
					amplitude.getInstance().logEvent(`Clicked on ${badge} Badge.`);
					setFilter(badge);
				}}
				style={{
					background: color,
					boxShadow: boxShadow
				}}
				className={styles.badgeCard}
			>
				<div style={{ textAlign: 'center' }}>
					<h4>{`${count ? count : 0}`}</h4>
					<p>{`${badge} Customers`}</p>
				</div>
			</Card>
		);
	});
};

export default BadgeCard;
