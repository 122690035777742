import { MenuOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { useState } from 'react';
import styles from './index.module.scss';
export const TrackingSideDrawer = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleSideDrawer = () => {
		setIsModalOpen((e) => !e);
	};
	return (
		<>
			<div id={styles.sideDrawer}>
				<MenuOutlined onClick={handleSideDrawer} style={{ color: 'white', fontSize: '25px' }} />
				<Drawer
					title="Wherehouse.io"
					placement="right"
					closable={true}
					onClose={handleSideDrawer}
					visible={isModalOpen}
					bodyStyle={{ padding: '10px' }}
				>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '0.7rem' }}>
						{data.map((e) => {
							const link = e?.linkURL?.includes('http') ? e?.linkURL : `https://${e.linkURL}`;

							return (
								<Button
									size="large"
									style={{ width: '100%' }}
									onClick={() => {
										window.location.href = link;
									}}
								>
									{e.linkName}
								</Button>
							);
						})}
					</div>
				</Drawer>
			</div>
		</>
	);
};
