import { Col, Row } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { downloadMoreOrdersAndMail, downloadOrdersCSV, getOrders } from 'shared/api/lightning';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { RequestEmailModal } from 'shared/components/EmailRequestModal';
import { HMSOrderInfoSlider } from 'shared/components/OrderInfoSlider/HMSOrderInfoSlider';
import { AuthContext } from 'shared/contexts/Auth';
import amplitude from 'shared/utils/Amplitude';
import { envs } from 'shared/utils/env';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { defaultPageConfig } from '../../../../constants';
import { useColumns } from './columns';
import { defaultFilter } from './constants';
import { topFilterUtil } from './utils';

export function Orders({ lightningPanel = false }) {
	const [data, setData] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [openOrderInfo, setOpenOrderInfo] = useState(false);
	const [reloadList, setReloadList] = useState(0);
	const [currentCount, setCurrentCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const columns = useColumns({ clickOrderId: setOpenOrderInfo });
	const [companies, setCompanies] = useState([]);
	const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
	const { authState } = useContext(AuthContext);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				setPageConfigDefault,
				companies,
				setCompanies,
				onDownloadOrders,
				lightningPanel
			}),
		[filter, setFilter, companies, setCompanies, lightningPanel]
	);

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	function handleTableChange(pagination, filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	async function getAllOrders({ pageConfiguration, filters, isLightningPanel }) {
		try {
			setLoading(true);
			const response = await getOrders({ pageConfiguration, filters, isLightningPanel });
			if (response.data.status) {
				setData(response.data.data);
				setCurrentCount(response.data.currentCount);
			} else {
				throw new Error('Couln not get orders');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getAllOrders({ pageConfiguration: pageConfig, filters: filter, isLightningPanel: lightningPanel });
	}, [pageConfig, filter, reloadList, lightningPanel]);

	async function onDownloadOrders() {
		amplitude.getInstance().logEvent(EVENT_NAMES.DOWNLOAD_ORDER_EXCEL);
		try {
			const csvFilter = { ...filter, appType: envs.appType };
			const shouldEmailOpen = await downloadOrdersCSV(csvFilter, lightningPanel && authState.profile.id);
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			errorHandler(err);
		}
	}

	const onOrdersFormSubmit = async (values) => {
		try {
			setIsDownloadButtonLoading(true);
			const csvFilter = { ...filter, appType: envs.appType };
			await downloadMoreOrdersAndMail(csvFilter, values.email, lightningPanel && authState.profile.id);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	return (
		<Row className="h-100 p-0">
			<RequestEmailModal
				title="Download Orders"
				visible={isEmailModalVisible}
				onCancel={() => {
					setIsEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onOrdersFormSubmit}
				instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
				loading={isDownloadButtonLoading}
			/>
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={data}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={currentCount}
							onChange={handleTableChange}
							loading={loading}
							shouldRowSelection={false}
							{...pageConfig}
						/>
					</div>
				</div>
			</Col>
			{openOrderInfo && (
				<HMSOrderInfoSlider
					awbNumber={openOrderInfo}
					onClose={() => setOpenOrderInfo(null)}
					reloadList={() => setReloadList((number) => number + 1)}
					lightningPanel={lightningPanel}
				/>
			)}
		</Row>
	);
}
