import { Result, Button } from 'antd';

export const SuccessComponent = ({ previewConfigName, routeName }) => {
	return (
		<Result
			status="success"
			title={`All ${previewConfigName} executed successfully!`}
			extra={[
				<Button type="primary" key="console" href={routeName}>
					{`Go to ${previewConfigName} page`}
				</Button>
			]}
		/>
	);
};
