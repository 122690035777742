import React from 'react';
import { Pagination, Table } from 'antd';
import styles from './index.module.scss';

export function CustomTablePagination({
	columns,
	data,
	current = 1,
	total = 0,
	pageSize = 10,
	onChangePage = () => {},
	isCatalog,
	selectedItem,
	catalog,
	selectedRowKeys,
	setSelectedRowKeys,
	setSelectedItem,
	emptyTableMessage = 'Nothing to do here',
	shouldRowSelection = true,
	...tableProps
}) {
	return (
		<div className={styles.main}>
			{data?.length || tableProps.loading ? (
				<>
					<Table
						// tableLayout={`${catalog ? "auto" : "fixed"}`}
						rowSelection={
							shouldRowSelection
								? {
										type: 'checkbox',
										selectedRowKeys,
										preserveSelectedRowKeys: true,
										onChange:
											setSelectedRowKeys ||
											((_, selectedRow) => (setSelectedItem ? setSelectedItem(selectedRow) : null))
								  }
								: null
						}
						columns={columns}
						dataSource={data}
						pagination={false}
						rowKey={(record) => record.id}
						className={styles.table}
						scroll={{ y: 'calc(100% - 3.5rem)', x: 'calc(100% - 3.5rem)' }}
						{...tableProps}
					/>
					{/* {isCatalog ? (
						<div className="ml-5 mt-3">
							<Button
								type="primary"
								size="large"
								onClick={() => (window.location.hash = '#update')}
								disabled={selectedItem?.length > 1 || !selectedItem}
							>
								Edit
							</Button>
						</div>
					) : null} */}
					<Pagination
						current={current}
						total={total}
						pageSize={pageSize}
						showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
						className={styles.pagination}
						id="CustomTablePagination-page"
						onChange={onChangePage}
						showSizeChanger
					/>
				</>
			) : (
				<div className="noDataDefaultComponent">{emptyTableMessage}</div>
			)}
		</div>
	);
}
