import { apiOms } from '../../../../shared/api';

export const populateProductItems = (items, catalogData) => {
	const updateProductObject = ({ id, gst, quantity, price }) => {
		const catalog = catalogData.find((catalog) => catalog.id === id);

		const updatedProductObject = {
			name: catalog.productTitle,
			variantId: catalog.variantId,
			productId: catalog.productId,
			quantity,
			weight: catalog.weight,
			sku: catalog.sku,
			price: price ?? catalog.price,
			gstRate: gst,
			discount: 0,
			catalogueId: id
		};

		return updatedProductObject;
	};

	const populatedProductItems = items.map(updateProductObject);
	return populatedProductItems;
};

export const fetchAddresses = (shipping, billing) => {
	const { firstName, lastName, phone, address1, address2, city, state, zip, email, GSTIN } = shipping;

	const shippingAddress = {
		GSTIN,
		firstName: firstName || '',
		email,
		address1,
		phone,
		city,
		pincode: zip,
		province: state,
		country: 'India',
		lastName: lastName || '',
		address2,
		company: '',
		latitude: 0,
		longitude: 0,
		countryCode: 'IN',
		provinceCode: ''
	};

	const billingAddressFormat = {
		...billing,
		firstName: billing?.firstName || '',
		pincode: billing?.zip,
		province: billing?.state,
		country: 'India',
		lastName: billing?.lastName || '',
		company: '',
		latitude: 0,
		longitude: 0,
		countryCode: 'IN',
		provinceCode: ''
	};

	const billingAddress = billing ? billingAddressFormat : { ...shippingAddress };

	const customer = {
		firstName: firstName || '',
		lastName: lastName || '',
		email,
		phone,
		address: {
			firstName: firstName || '',
			lastName: lastName || '',
			phone,
			address1,
			address2,
			city,
			state,
			country: 'India',
			pincode: zip,
			company: ''
		}
	};

	return { customer, shippingAddress, billingAddress };
};

export const createOrder = (formData) => {
	return apiOms.post('/order', formData);
};

export const IManualOrderFormName = Object.freeze({
	SHIPPING_ADDRESS: 'shippingAddress',
	BILLING_ADDRESS: 'billingAddress',
	SHIPPING_DETAILS: 'Shipping details',
	BILLING_ADDRESS_HEADING: 'Billing address'
});
