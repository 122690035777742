let handler = {
	get: function (target, name) {
		return target.hasOwnProperty(name) ? target[name] : name;
	}
};

let status = {
	PLACED: 'Placed',
	PROCESSED: 'Processed',
	READY_TO_SHIP: 'Ready to Ship',
	PICKED_UP: 'Picked Up',
	SHIPPED: 'Shipped',
	OUT_FOR_DELIVERY: 'Out for Delivery',
	IN_TRANSIT: 'In Transit',
	UNDELIVERED: 'Undelivered',
	DELIVERED: 'Delivered',
	CANCELLED: 'Cancelled',
	RTO_AVAILABLE: 'Available for Return (RTO)',
	RTO_IN_TRANSIT: 'RTO In Transit',
	RTO_OUT_FOR_DELIVERY: 'RTO Out for Delivery',
	RTO_DELIVERED: 'Returned To Origin',
	RTO_UNDELIVERED: 'RTO Undelivered',
	RTS_PROCCESSING: 'RTS Processing',
	RTS_FAILED: 'RTS Failed',
	RTO_PROCESSED: 'RTO Processed',
	DTO_PLACED: 'DTO Placed',
	DTO_IN_TRANSIT: 'DTO In Transit',
	DTO_OUT_FOR_DELIVERY: 'DTO Out for Delivery',
	DTO_DELIVERED: 'Returned To Origin',
	DTO_UNDELIVERED: 'DTO Undelivered',
	DTO_PROCESSED: 'DTO Processed',
	DTO_CANCELLED: 'DTO Cancelled',
	UNSERVICEABLE: 'Unserviceable',
	LOST: 'Lost'
};

export const orderStatus = new Proxy(status, handler);

export const orderStatusColor = {
	PLACED: 'rgba(149, 191, 70, 1)',
	READY_TO_SHIP: 'rgba(149, 191, 70, 1)',
	SHIPPED: 'rgba(149, 191, 70, 1)',
	OUT_FOR_DELIVERY: 'rgba(149, 191, 70, 1)',
	IN_TRANSIT: 'rgba(149, 191, 70, 1)',
	DELIVERED: 'rgba(149, 191, 70, 1)',
	UNDELIVERED: 'rgba(233, 71, 2, 1)',
	CANCELLED: 'rgba(233, 71, 2, 1)',
	RTO_DELIVERED: 'rgba(233, 71, 2, 1)',
	RTO_UNDELIVERED: 'rgba(233, 71, 2, 1)',
	RTO_PROCESSED: 'rgba(149, 191, 70, 1)',
	UNSERVICEABLE: 'rgba(233, 71, 2, 1)',
	DTO_PLACED: 'rgba(149, 191, 70, 1)',
	DTO_IN_TRANSIT: 'rgba(149, 191, 70, 1)',
	DTO_RECEIVED: 'rgba(233, 71, 2, 1)',
	DTO_DELIVERED: 'rgba(233, 71, 2, 1)',
	DTO_CANCELLED: 'rgba(233, 71, 2, 1)',
	DTO_OUT_FOR_DELIVERY: 'rgba(149, 191, 70, 1)',
	DTO_UNDELIVERED: 'rgba(233, 71, 2, 1)',
	DTO_PROCESSED: 'rgba(149, 191, 70, 1)',
	RTS_PROCCESSING: 'rgba(149, 191, 70, 1)',
	RTS_FAILED: 'rgba(233, 71, 2, 1)',
	RTO_AVAILABLE: 'rgba(149, 191, 70, 1)',
	RTO_IN_TRANSIT: 'rgba(149, 191, 70, 1)',
	RTO_OUT_FOR_DELIVERY: 'rgba(149, 191, 70, 1)',
	LOST: 'rgba(233, 71, 2, 1)'
};
