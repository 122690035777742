import { Button, Col, DatePicker, Form, Input, notification, Popconfirm, Row, Select, Timeline } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getOrders, markAsCanceled, markAsDelivered, markAsRTODelivered, markAsUndelivered } from 'shared/api/lightning';
import { InfoSlider } from 'shared/components/InfoSlider/InfoSlider';
import { sortByDate } from 'shared/utils/date';
import errorHandler from 'shared/utils/errorHandler';
import { orderStatusColor } from 'shared/utils/orderStatus';
import { ORDER_STATUS_TYPES, REVERSE_ORDER_STATUS_TYPES } from '../../../app/AppAdmin/Lightning/constants';
import { undeliverReasons } from '../../../app/AppAdmin/Lightning/Orders/constants';
import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { envs } from '../../utils/env';
import { HMSTrail } from './components/OrderTrail/HMSOrderTrail';
import styles from './index.module.scss';

const driverInfoStatusCodes = [ORDER_STATUS_TYPES['ASSIGNED'], ORDER_STATUS_TYPES['OUT_FOR_DELIVERY'], ORDER_STATUS_TYPES['DELIVERED']];

function deliveryActionButtonsStatusCodes(orderStatus, orderAttempt) {
	if (envs.appType !== 'AppAdmin') return false;
	if (
		[
			ORDER_STATUS_TYPES['PLACED'],
			ORDER_STATUS_TYPES['RECEIVED'],
			ORDER_STATUS_TYPES['ASSIGNED'],
			ORDER_STATUS_TYPES['OUT_FOR_DELIVERY'],
			ORDER_STATUS_TYPES['UNATTEMPTED'],
			ORDER_STATUS_TYPES['UNDELIVERED'],
			ORDER_STATUS_TYPES['RTO_IN_TRANSIT']
		].includes(orderStatus) &&
		orderAttempt <= 3
	) {
		return true;
	}
	return false;
}

export function HMSOrderInfoSlider({ awbNumber, onClose, reloadList, lightningPanel = false }) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [isMarkAsDeliveredButtonLoading, setIsMarkAsDeliveredButtonLoading] = useState(false);
	const [isMarkAsUndeliveredButtonLoading, setIsMarkAsUndeliveredButtonLoading] = useState(false);
	const [order, setOrder] = useState(null);
	const [forwardTrail, setForwardTrail] = useState([]);

	const [timeOfDelivery, setTimeOfDelivery] = useState(null);

	const prettyStatus = (
		<span style={{ color: `${orderStatusColor[REVERSE_ORDER_STATUS_TYPES[order?.orderStatus]]}`, fontWeight: 'bold' }}>
			{REVERSE_ORDER_STATUS_TYPES[order?.orderStatus]}
		</span>
	);
	const orderStatus = <p>Order Status: {prettyStatus}</p>;

	const showDeliveryActionButtons = deliveryActionButtonsStatusCodes(order?.orderStatus, order?.attempt);

	const getSingleOrder = useCallback(async () => {
		try {
			setLoading(true);
			const {
				data: { data: orderDetail }
			} = await getOrders({ awb: awbNumber, trail: true, isLightningPanel: lightningPanel });
			if (orderDetail) {
				setOrder(orderDetail);
				setForwardTrail(orderDetail?.trails);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}, [awbNumber, lightningPanel]);

	async function onClickMarkAsDelivered({ driverId, awb, timeStamp }) {
		try {
			setIsMarkAsDeliveredButtonLoading(true);
			const { data } = await markAsDelivered({ driverId, awb, timeStamp });
			if (data.status) {
				setOrder((orderDetail) => ({ ...orderDetail, orderStatus: ORDER_STATUS_TYPES['DELIVERED'], orderNumber: 0 }));
				notification.success({
					message: 'Marked Delivered',
					description: data.message,
					placement: 'topRight'
				});
				reloadList();
			} else {
				throw new Error('could not mark order as delivered');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsMarkAsDeliveredButtonLoading(false);
		}
	}

	async function onClickMarkAsUndelivered() {
		try {
			setIsMarkAsUndeliveredButtonLoading(true);
			await form.validateFields();
			const driverId = order?.driver?.id;
			const awb = order?.awb;
			const { reason, customReason } = form.getFieldsValue();
			const undeliverReason = reason === 'Other' ? customReason : reason;

			const { data } = await markAsUndelivered({ driverId, awb, reason: undeliverReason });
			if (data.status) {
				setOrder((orderDetail) => ({ ...orderDetail, orderStatus: ORDER_STATUS_TYPES['UNDELIVERED'], orderNumber: 0 }));
				notification.success({
					message: 'Order mark undelivered',
					description: data.message,
					placement: 'topRight'
				});
				reloadList();
			} else {
				throw new Error(data?.message || 'could not mark order as undelivered');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsMarkAsUndeliveredButtonLoading(false);
		}
	}

	async function onClickCancelOrder() {
		try {
			setIsMarkAsUndeliveredButtonLoading(true);
			await form.validateFields();
			const awb = order?.awb;
			const { reason, customReason } = form.getFieldsValue();
			const cancelReason = reason === 'Other' ? customReason : reason;

			const { data } = await markAsCanceled({ awb, reason: cancelReason });
			if (data.status) {
				setOrder((orderDetail) => ({ ...orderDetail, orderStatus: ORDER_STATUS_TYPES.RTO_IN_TRANSIT, orderNumber: 0 }));
				notification.success({
					message: 'Order mark cancelled',
					description: data.message,
					placement: 'topRight'
				});
				reloadList();
			} else {
				throw new Error(data?.message || 'could not mark order as cancelled');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsMarkAsUndeliveredButtonLoading(false);
		}
	}

	async function onClickMarkAsRTODelivered() {
		try {
			setIsMarkAsUndeliveredButtonLoading(true);
			const awb = order?.awb;
			const { data } = await markAsRTODelivered({ awb });
			if (data.status) {
				setOrder((orderDetail) => ({ ...orderDetail, orderStatus: ORDER_STATUS_TYPES.RTO_DELIVERED, orderNumber: 0 }));
				notification.success({
					message: 'Order mark RTO DELIVERED',
					description: data.message,
					placement: 'topRight'
				});
				reloadList();
			} else {
				throw new Error(data?.message || 'could not mark order as RTO Delivered');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsMarkAsUndeliveredButtonLoading(false);
		}
	}

	useEffect(() => {
		getSingleOrder();
	}, [awbNumber, isMarkAsDeliveredButtonLoading, isMarkAsUndeliveredButtonLoading, getSingleOrder]);

	return (
		<InfoSlider onClose={onClose}>
			<div className={`${styles.main} scrollable`} style={{ width: '24rem' }}>
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />
				{order ? (
					<Col className={styles.orderInfoContainer}>
						<Row className={styles.paddedRow}>
							<Col span={24}>
								<Row className={styles.row1}>
									<Col span={24}>
										<span className="title">{`AWB ${order?.awb}`}</span>
									</Col>
								</Row>

								<Row className={styles.row2}>
									<Col>
										{sortByDate({ data: forwardTrail, fieldName: 'createdAt' }).map((trailDetails) => (
											<Timeline>
												<HMSTrail trailDetails={trailDetails} />
											</Timeline>
										))}
									</Col>
								</Row>

								<Col span={24} className="sub">
									{showDeliveryActionButtons && (
										<Row span={24} className="mt-3">
											<DatePicker
												showTime
												style={{ width: '100%' }}
												onChange={(e) => {
													setTimeOfDelivery(e?.format());
												}}
											/>
											<Popconfirm
												title="Are you sure you want to mark this order delivered?"
												okText="Yes"
												cancelText="No"
												onConfirm={() =>
													onClickMarkAsDelivered({
														driverId: order?.driver?.id,
														awb: order.awb,
														timeStamp: timeOfDelivery
													})
												}
											>
												<Button
													style={{ width: '100%' }}
													className="mt-3"
													type="primary"
													size="middle"
													shape="round"
													loading={isMarkAsDeliveredButtonLoading}
												>
													Mark as delivered
												</Button>
											</Popconfirm>
										</Row>
									)}

									{showDeliveryActionButtons && (
										<>
											<hr />
											<Col span={24} className="mt-3">
												{/* <Form onFinish={onClickMarkAsUndelivered}> */}
												<Form form={form}>
													<Form.Item
														name="reason"
														rules={[{ required: true, message: 'Please select a reason' }]}
													>
														<Select placeholder="Select a reason" allowClear>
															{undeliverReasons.map((reason) => {
																return (
																	<Select.Option value={reason} key={reason}>
																		{reason}
																	</Select.Option>
																);
															})}
														</Select>
													</Form.Item>
													<Form.Item
														noStyle
														shouldUpdate={(previousValue, currentValue) =>
															previousValue.reason !== currentValue.reason
														}
													>
														{({ getFieldValue }) =>
															getFieldValue('reason') === 'Other' ? (
																<Form.Item name="customReason" rules={[{ required: true }]}>
																	<Input placeholder="Enter reason" />
																</Form.Item>
															) : null
														}
													</Form.Item>
													<Form.Item>
														<div
															style={{
																display: 'flex',
																flexDirection: 'row',
																justifyContent: 'space-between'
															}}
														>
															<Button
																style={{ width: '100%', marginRight: '5px' }}
																type="primary"
																size="middle"
																shape="round"
																htmlType="button"
																onClick={onClickMarkAsUndelivered}
																loading={isMarkAsUndeliveredButtonLoading}
																danger
															>
																Mark as Undelivered
															</Button>

															<Button
																style={{ width: '100%', marginLeft: '5px' }}
																type="primary"
																size="middle"
																shape="round"
																htmlType="button"
																onClick={onClickCancelOrder}
																loading={isMarkAsUndeliveredButtonLoading}
																danger
															>
																Cancel Order
															</Button>
														</div>
													</Form.Item>
												</Form>
											</Col>
										</>
									)}

									{showDeliveryActionButtons && (
										<Row span={24}>
											<Button
												style={{ width: '100%', margin: '0' }}
												type="primary"
												size="middle"
												shape="round"
												onClick={onClickMarkAsRTODelivered}
												danger
												loading={isMarkAsUndeliveredButtonLoading}
											>
												RTO DELIVERED
											</Button>
										</Row>
									)}

									<div className="mt-5 mb-3">
										{orderStatus}
										<p>{`Customer name: ${order?.customerAddress?.name}`}</p>
										<p>{`Customer number: ${order?.customerAddress?.phone}`}</p>
										<p>{`Address: ${order?.customerAddress?.addressLine1}, ${order?.customerAddress?.addressLine2}, ${order?.customerAddress?.city}, ${order?.customerAddress?.state} - ${order?.customerAddress?.pincode}`}</p>
										<p>{`Payment Method: ${order?.paymentMethod}`}</p>
										<p>{`Total Price: Rs ${parseFloat(order.totalPrice).toFixed(2)}`}</p>
										<p>{`OTP: ${order?.otp}`}</p>
										<p>{`Attempt: ${order?.attempt}`}</p>

										{driverInfoStatusCodes.includes(order?.orderStatus) && order.driver && (
											<p>{`Driver: ${order?.driver?.name} - ${order?.driver?.phone}`}</p>
										)}
									</div>
								</Col>
							</Col>
						</Row>
						{order?.shippingLabelURL && (
							<Row className={styles.row5}>
								<Col span={24}>
									<Button type="primary" size="large" onClick={() => window.open(order?.shippingLabelURL, '_blank')}>
										Print Label
									</Button>
								</Col>
							</Row>
						)}
					</Col>
				) : (
					!loading && <div className="noDataDefaultComponent">Nothing to do here</div>
				)}
			</div>
		</InfoSlider>
	);
}
