import { Col } from 'antd';
import errorHandler from '../../../shared/utils/errorHandler';
import { IInsightCardName, IInsightHeading, IInsightDaysCount, InsightAPIarray } from './utils/Interface';
import styles from './index.module.scss';
import { DataCard } from './components/DataCard';
import { MostSellingProductChart } from './components/MostSellingProductChart';
import { PieChart } from './components/PieChart';
import { apiOms } from '../../../shared/api';
import { IndiaMap } from './components/IndiaMap';
import { cancelOrderSvg, ndrSvg, revenueSvg, totalOrdersSvg, totalOrderSvg, totalRevenue } from './svgs/svgs';
import { convertToInternationalCurrencySystem, formatInPercentage } from './utils/PercentageLogic';
import { useQuery } from 'react-query';
import { Loading } from '../../../shared/components/Loading';

export const Insights = () => {
	const { isLoading, error, data } = useQuery('getData', () =>
		Promise.all(
			InsightAPIarray.map(async (e: string) => {
				try {
					const { data: details } = await apiOms(e);
					return details;
				} catch (err) {
					errorHandler(err);
					return err;
				}
			})
		).then((responseData) => {
			return responseData.reduce((acc, curr) => {
				acc[curr.apiType] = { ...curr };
				return acc;
			}, {});
		})
	);

	if (isLoading) {
		return <Loading loading={isLoading} />;
	}
	if (error) {
		errorHandler(error);
	}

	return (
		<Col lg={20}>
			<div className={styles.topButton}>
				<div className={styles.gridAreaParentDiv}>
					{data?.getRevenue && (
						<DataCard
							svg={totalRevenue}
							gridAreaClass={styles?.two}
							data={convertToInternationalCurrencySystem(data?.getRevenue?.totalRevenue?.revenue?.toFixed(2) || 0)}
							cardName={IInsightCardName.REVENUE}
							days={IInsightDaysCount.LAST_THIRTY_DAYS}
							percentage={formatInPercentage(
								Number(data?.getRevenue?.lastMontheRevenue?.revenue),
								Number(data.getRevenue?.totalRevenue?.revenue)
							)}
						/>
					)}
					<DataCard
						gridAreaClass={styles.one}
						svg={cancelOrderSvg}
						days={IInsightDaysCount.LAST_THIRTY_DAYS}
						data={data?.cancelledOrders?.cancelledOrdersCount}
						cardName={IInsightCardName.NUMBER_OF_ORDERS_CANCELED}
						percentage={formatInPercentage(
							Number(data?.cancelledOrders?.lastMonthCancelOrdersCount),
							Number(data?.cancelledOrders?.cancelledOrdersCount)
						)}
					/>
					<DataCard
						gridAreaClass={styles.three}
						svg={totalOrderSvg}
						data={data?.getOrders?.todaysOrders}
						cardName={IInsightCardName.TODAY_ORDERS}
						days={IInsightDaysCount.YESTERDAY}
						percentage={formatInPercentage(Number(data?.getOrders?.yesterdayOrders), Number(data?.getOrders?.todaysOrders))}
					/>
					<DataCard
						svg={revenueSvg}
						days={IInsightDaysCount.YESTERDAY}
						gridAreaClass={styles.four}
						data={data?.getRevenue?.todaysRevenue?.revenue?.toFixed(2)}
						cardName={IInsightCardName.TODAY_REVENUE}
						percentage={formatInPercentage(
							Number(data?.getRevenue?.yesterdaysRevenue.revenue),
							Number(data?.getRevenue?.todaysRevenue.revenue)
						)}
					/>
					<DataCard
						gridAreaClass={styles.five}
						days={IInsightDaysCount.LAST_THIRTY_DAYS}
						svg={totalOrdersSvg}
						data={data?.getOrders?.totalOrders}
						cardName={IInsightCardName.TOTAL_ORDERS}
						percentage={formatInPercentage(Number(data?.getOrders?.lastMonthOrders), Number(data?.getOrders?.totalOrders))}
					/>
					<DataCard
						gridAreaClass={styles.six}
						svg={ndrSvg}
						days={IInsightDaysCount.LAST_THIRTY_DAYS}
						cardName={IInsightCardName.UNDELIVERED_ORDERS}
						percentage={formatInPercentage(
							Number(data?.undeliveredCount?.last30DaysUndeliveredOrders),
							Number(data?.undeliveredCount?.undeliveredOrdersCount)
						)}
						data={data?.undeliveredCount?.undeliveredOrdersCount}
					/>
					{data?.topProducts && <MostSellingProductChart data={data?.topProducts?.formatedData} />}
					{data?.stateWiseCount && <IndiaMap mapData={data?.stateWiseCount?.stateWiseCount} />}
				</div>
				<div className={styles.pieChartDiv}>
					{data?.courierSplit && <PieChart data={data?.courierSplit?.formatedData} heading={IInsightHeading.COURIER_SPLIT} />}
					{data?.warehoueSplit && (
						<PieChart data={data?.warehoueSplit?.formatedData} heading={IInsightHeading.ORDER_DELIVERED_FROM_WAREHOUSE} />
					)}
					{data?.topRtoReasons && <PieChart data={data?.topRtoReasons?.formatedData} heading={IInsightHeading.TOP_RTO_REASON} />}
				</div>
			</div>
		</Col>
	);
};
