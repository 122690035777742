import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { defaultPageConfig } from '../../../constants';
import { apiWms } from '../../../shared/api';
import { Sidebar } from '../../../shared/components/AppLayout/Sidebar';
import { TopFilterComponent } from '../../../shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from '../../../shared/components/CustomTablePagination';
import { InventoryInfoSlider } from '../../../shared/components/InventoryInfoSlider';
import { SideDrawer } from '../../../shared/components/SideDrawer/SideDrawer';
import useInventory from '../../../shared/hooks/useInventory';
import errorHandler from '../../../shared/utils/errorHandler';
import { inventoryColumns } from '../../columns/Inventory/inventoryColumn';
import { UploadBulkModal } from '../UploadBulkModal';
import { bulkInventoryPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from '../UploadBulkPreview/constants';
import { defaultFilter } from './constants';
import { sidebarUtil, topFilterUtil } from './utils';

export function Inventory({ isWMS = false, companyID = '' }) {
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [warehouses, setWarehouses] = useState([]);

	const [openInventoryInfo, setOpenInventoryInfo] = useState(false);
	const [reloadInventoryList, setReloadInventoryList] = useState(0);
	const [bulkInventoryUploadModal, setBulkInventoryUploadModal] = useState(false);

	const sidebarConfig = useMemo(() => sidebarUtil(warehouses), [warehouses]);

	const history = useHistory();

	const historyLocation = useLocation();

	let queryValue = historyLocation['search'].split('=')[1];
	if (queryValue == 'undefined') {
		if (sidebarConfig.sideBarMenu[0]) {
			queryValue = sidebarConfig.sideBarMenu[0]['key'];
		}
	}
	const [sidebarMenuItem, setSidebarMenuItem] = useState(queryValue);

	const { inventory, inventoryCount, loading } = useInventory({
		pageConfig,
		filter,
		warehouseID: sidebarMenuItem,
		companyID,
		reloadInventoryList
	});

	const columnsConfig = useMemo(
		() =>
			inventoryColumns({ sortField: pageConfig.sortField, sortOrder: pageConfig.sortOrder, clickInventoryId: setOpenInventoryInfo }),
		[pageConfig.sortField, pageConfig.sortOrder, setOpenInventoryInfo]
	);

	const topFilterConfig = useMemo(
		() => topFilterUtil({ sidebarMenuItem, filter, setFilter, setPageConfigDefault, setBulkInventoryUploadModal, isWMS }),
		[sidebarMenuItem, filter, isWMS]
	);

	function setPageConfigDefault() {
		setPageConfig(defaultPageConfig);
	}

	function handleTableChange(pagination, filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: !sorter.order || sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	useEffect(() => {
		async function fetchWarehouses() {
			try {
				let {
					data: { warehouses: fetchedWarehouses }
				} = await apiWms.get('/company/warehouses');
				fetchedWarehouses = fetchedWarehouses.filter(({ active }) => active);
				setWarehouses(fetchedWarehouses);
			} catch (error) {
				errorHandler(error);
			}
		}
		fetchWarehouses();
	}, []);

	useEffect(() => {
		setSidebarMenuItem(queryValue);
	}, [queryValue]);

	useEffect(() => {
		history.push({
			pathname: '/inventory',
			search: `?tab=${sidebarMenuItem}`
		});
	}, [sidebarMenuItem, history]);

	return (
		<>
			{isWMS ? (
				<UploadBulkModal
					instructionMessage={
						<ul className="instructions">
							<li>Download the excel template by clicking "Download Sample" button below</li>
							<li>Edit file with inventory data and upload </li>
							<br />
							Note: {MAX_ROWS_EXCEED_ERROR_MSG}
						</ul>
					}
					templatePath={process.env.REACT_APP_EXCEL_INVENTORY_TEMPLATE}
					previewPath={bulkInventoryPreviewRoute}
					modalTitle="Bulk Inventory Inbound"
					isModalVisible={bulkInventoryUploadModal}
					setIsModalVisible={setBulkInventoryUploadModal}
					companyId={companyID}
				/>
			) : null}
			<Row className="h-100 p-0">
				{!isWMS && (
					<SideDrawer placement="left">
						{
							<Sidebar
								sideBarMenu={sidebarConfig.sideBarMenu}
								bottomButtons={null}
								selectedMenu={sidebarMenuItem}
								onMenuItemSelect={setSidebarMenuItem}
							/>
						}
					</SideDrawer>
				)}
				<Col sm={24} lg={isWMS ? 24 : 20} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								data={inventory}
								showSorterTooltip={true}
								{...pageConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								total={inventoryCount}
								onChange={handleTableChange}
								loading={loading}
								emptyTableMessage="Inventory not found!"
								bordered
							/>
						</div>
						{openInventoryInfo && (
							<InventoryInfoSlider
								reloadList={() => setReloadInventoryList((prevValue) => prevValue + 1)}
								inventoryId={openInventoryInfo}
								onClose={() => setOpenInventoryInfo(null)}
							/>
						)}
					</div>
				</Col>
			</Row>
		</>
	);
}
