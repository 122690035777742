import { CaretRightOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip, Timeline, Typography, Collapse } from 'antd';
import { ORDER_STATUS } from '../../../../../constants';
import { formatDate, formatTime } from 'shared/utils/date';
import { checkOrderServiceablity } from 'shared/api/orders';
import errorHandler from 'shared/utils/errorHandler';
import { orderStatus, orderStatusColor } from 'shared/utils/orderStatus';
import { TrailDetails } from './TrailDetails';
import { useState } from 'react';
import dayjs from 'dayjs';
const { Panel } = Collapse;

export const OrderTrail = ({ trailDetails, data, fetchOrderDetails, showInfoIcon = true }) => {
	const [disableCheckServiceability, setDisableCheckServiceability] = useState(false);
	const checkServiceabilityHandler = async () => {
		try {
			setDisableCheckServiceability(true);
			const { data } = await checkOrderServiceablity(trailDetails.splitOrderId);

			if (data.status) {
				notification.success({
					message: 'Order is serviceable now! It has been moved to placed orders.',
					placement: 'topRight'
				});
				fetchOrderDetails();
			}
		} catch (error) {
			errorHandler(error);
			setDisableCheckServiceability(false);
		}
	};
	return (
		<Timeline.Item color={orderStatusColor[trailDetails.orderStatus]}>
			<p style={{ display: 'flex', alignItems: 'center', marginBottom: 0, fontWeight: 'normal' }}>
				{orderStatus[trailDetails.orderStatus]}
				{showInfoIcon && (
					<TrailDetails
						trailDetails={trailDetails}
						showTrailCondition={
							trailDetails.orderStatus === ORDER_STATUS.RTO_UNDELIVERED ||
							trailDetails.orderStatus === ORDER_STATUS.RTS_FAILED
						}
					/>
				)}
				{/* Show recheck serviceability button */}
				<span>
					{trailDetails.orderStatus === ORDER_STATUS.UNSERVICEABLE ? (
						<Tooltip title="Re check serviceability of the order" placement="bottom">
							<Button
								type="text"
								icon={<SyncOutlined />}
								onClick={checkServiceabilityHandler}
								disabled={disableCheckServiceability}
								style={{ marginBlockStart: '-2rem' }}
							/>
						</Tooltip>
					) : null}
				</span>
			</p>
			<div style={{ fontSize: '0.7rem', fontWeight: 300 }}>
				<p>
					{formatDate(trailDetails.createdAt)} | {formatTime(trailDetails.createdAt)}
				</p>
				{data.length === 1 && <Typography.Paragraph>{trailDetails.description}</Typography.Paragraph>}
				{data.length === 1 && (
					<Typography.Paragraph>{trailDetails.city ? `Location: ${trailDetails.city}` : null}</Typography.Paragraph>
				)}
				<Typography.Paragraph>
					<Collapse
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						style={{
							borderRadius: '8px',
							border: 'none',
							overflow: 'hidden',
							marginTop: '1rem',
							width: '330px',
							background: 'white'
						}}
					>
						{data.length > 1 && (
							<Panel
								style={{
									fontSize: '0.8rem',
									border: '0px',
									transform: 'translateX(-20px) translateY(-15px)',
									fontWeight: 'normal'
								}}
								header={`Show more (${data.length})`}
								key="0"
								className="site-collapse-custom-panel"
							>
								<table class="table table-striped table-hover" style={{ fontSize: '0.6rem', width: '300px' }}>
									<thead>
										<tr>
											<th style={{ width: '80px' }} scope="col">
												Time
											</th>
											<th style={{ width: '110px' }} scope="col">
												City
											</th>
											<th scope="col">Status</th>
										</tr>
									</thead>
									<tbody>
										{data.map((e) => {
											return (
												<tr>
													<td>{dayjs(e?.createdAt).format('DD MMM, hh:mm A')}</td>
													<td>{e?.city || 'N/A'}</td>
													<td>{e?.description}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</Panel>
						)}
					</Collapse>
				</Typography.Paragraph>
			</div>
		</Timeline.Item>
	);
};
