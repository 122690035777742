import React from 'react';
import { Button, Tag, Row } from 'antd';
import { images as channelImages } from 'shared/utils/channel';
import { orderStatus, orderStatusColor } from 'shared/utils/orderStatus';
import { isSortField } from 'shared/utils/table';

export const columns = ({ clickOrderId, sortField, sortOrder, showApproveModal, showDetailsModal }) => [
	{
		title: 'Date',
		dataIndex: 'createdAt',
		render: (text) => <div className="text-center">{new Date(text).toLocaleDateString()}</div>,
		width: 150,
		sorter: true,
		sortOrder: isSortField(sortField, sortOrder, 'createdAt')
	},
	{
		title: 'Order Id',
		dataIndex: 'shopOrderId',
		render: (v, order) => (
			<>
				<Row justify="center">
					<Button
						type="link"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							clickOrderId({
								orderId: order.id,
								companyId: order.companyId
							});
						}}
					>
						{order.shopOrderId || order.externalOrderId || order.id}
					</Button>
				</Row>
				<Row justify="center">
					<Tag color="geekblue">{order.paymentMode}</Tag>
				</Row>
			</>
		),
		width: 120,
		sorter: true,
		sortOrder: isSortField(sortField, sortOrder, 'shopOrderId')
	},
	{
		title: 'Channel',
		dataIndex: 'shopType',
		render: (key) => (
			<div className="text-center">
				<img style={{ maxWidth: 56, maxHeight: 56 }} src={channelImages[key]} alt="shopType" />
			</div>
		),
		width: 100,
		sortOrder: isSortField(sortField, sortOrder, 'shopType')
	},
	{
		title: 'Product Info',
		dataIndex: 'items',
		render: (obj) => (
			<div>
				{obj?.length
					? obj.map((v) => (
							<div className="w-100 p-relative float-left" key={v.id}>
								<div className="text-left">{v.title}</div>
								<div>
									<a href="#" className="float-left">
										{v.sku}
									</a>
									<span className="float-right">{v.quantity}</span>
								</div>
							</div>
					  ))
					: '-'}
			</div>
		),
		width: 200
	},
	{
		title: 'Status',
		dataIndex: 'status',
		render: (status, order) => (
			<>
				{status ? (
					<div
						style={{
							color: orderStatusColor[status]
						}}
						className="text-center"
					>
						{orderStatus[status] || status}
						{showDetailsModal(status, order)}
						{showApproveModal(status, order)}
					</div>
				) : (
					<div className="text-center">-</div>
				)}
			</>
		),
		width: 150,
		sorter: true,
		sortOrder: isSortField(sortField, sortOrder, 'status')
	}
];
