import { Col, Row } from 'antd';
import { useColumns } from 'app/AppSeller/Orders/columns';
import { useEffect, useMemo, useRef, useState } from 'react';
import reactDom from 'react-dom';
import { downloadMoreOrdersAndMail, downloadOrdersCSV, getCompanyOrders } from 'shared/api/orders';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { OrderInfoSlider } from 'shared/components/OrderInfoSlider';
import useWarehouse from 'shared/hooks/useWarehouse';
import { envs } from 'shared/utils/env';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { defaultFilter } from './constants';
import { defaultPageConfig } from '../../../../constants';
import { topFilterUtil } from './utils';
import amplitude from '../../../../shared/utils/Amplitude';
import { RequestEmailModal } from 'shared/components/EmailRequestModal';

export function Orders({ brand }) {
	const [orders, setOrders] = useState([]);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState({ ...defaultFilter, companyId: brand.id });
	const [loading, setLoading] = useState(false);
	const [currentCount, setCurrentCount] = useState(0);
	const [openOrderInfo, setOpenOrderInfo] = useState();
	const [reloadList, setReloadList] = useState(0);
	const abortControllerRef = useRef(null);
	const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
	const columns = useColumns({
		clickOrderId: setOpenOrderInfo,
		sortField: pageConfig.sortField,
		sortOrder: pageConfig.sortOrder
	});

	const { warehouses } = useWarehouse({ companyID: brand.id });

	const topFilterConfig = useMemo(
		function () {
			return topFilterUtil({
				filter: { ...filter, companyId: brand.id },
				setFilter,
				setPageConfigDefault,
				onDownloadOrders
			});
		},
		[filter, setFilter, brand.id]
	);

	function setPageConfigDefault() {
		setPageConfig((previousConfig) => ({ ...previousConfig, ...defaultPageConfig }));
	}

	function handleTableChange(pagination, filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	async function getAllOrders({ pageConfiguration, filters, abortControllerRef }) {
		try {
			setLoading(true);
			const { data } = await getCompanyOrders(pageConfiguration, filters, abortControllerRef);
			if (data.status) {
				reactDom.unstable_batchedUpdates(() => {
					setOrders(data.order);
					setCurrentCount(data.currentCount || 0);
					setLoading(false);
				});
			} else {
				throw new Error(data.message);
			}
		} catch (error) {
			if (error.message === 'canceled') return;
			setLoading(false);
			errorHandler(error);
		}
	}

	useEffect(
		function getOrders() {
			getAllOrders({ pageConfiguration: pageConfig, filters: { ...filter, companyId: brand.id }, abortControllerRef });
		},
		[brand.id, filter, pageConfig, reloadList]
	);

	async function onDownloadOrders() {
		amplitude.getInstance().logEvent(EVENT_NAMES.DOWNLOAD_ORDER_EXCEL);
		try {
			const csvFilter = { ...filter, companyId: brand.id, appType: envs.appType };
			const shouldEmailOpen = await downloadOrdersCSV(csvFilter);
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			errorHandler(err);
		}
	}

	const onOrdersFormSubmit = async (values) => {
		try {
			setIsDownloadButtonLoading(true);
			const csvFilter = { ...filter, companyId: brand.id, appType: envs.appType };
			await downloadMoreOrdersAndMail(csvFilter, values.email);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	return (
		<>
			<Row className="h-100 p-0">
				<RequestEmailModal
					title="Download Orders"
					visible={isEmailModalVisible}
					onCancel={() => {
						setIsEmailModalVisible(false);
					}}
					footer={[]}
					width={500}
					onFinish={onOrdersFormSubmit}
					instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
					loading={isDownloadButtonLoading}
				/>
				<Col span={24} className="main">
					<TopFilterComponent {...topFilterConfig} />
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columns}
								data={orders}
								showSorterTooltop={true}
								{...pageConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								total={currentCount}
								onChange={handleTableChange}
								loading={loading}
							/>
							{openOrderInfo && (
								<OrderInfoSlider
									orderId={openOrderInfo}
									onClose={() => setOpenOrderInfo(null)}
									companyId={brand.id}
									showCancelButton={true}
									reloadList={() => setReloadList((number) => number + 1)}
									warehouses={warehouses.filter((warehouse) => warehouse.active)}
									showReassignWarehouse={true}
									showUpdateWeight={true}
									isAdminPanel={true}
								/>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
