import { Col, notification, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import errorHandler from 'shared/utils/errorHandler';
import NoCompany from '../../../app/AppAdmin/NoCompany/NoCompany';
import amplitude from '../../utils/Amplitude';
import { Loading } from '../Loading';
import { preferenceRowFields } from './constants';
import styles from './index.module.scss';
import { useSellerProfile } from './useSellerProfile';
import { WarehouseModal } from './components/WarehouseModal';
import { WhatsappPreference } from './WhatsappPreference';
import { apiUms, apiWms } from '../../api';
import { FulfillmentProviders } from '../../../constants';

export const IPreferenceConstant = Object.freeze({
	RTO_TO_OTHER_WAREHOUSE: 'rtoToOtherWarehouse'
});

export function Preferences({ isAdmin = false, companyId = undefined }) {
	const { profile, revalidate, updatePreference } = useSellerProfile({ isAdmin, companyId });
	const [loading, setLoading] = useState(false);
	const [preferenceFields, setPreferenceFields] = useState({});
	const [isRtoToOtherWh, setIsRtoToOtherWh] = useState(false);
	const [warehouse, setWarehouse] = useState([]);
	const [deliveryPartners, setDelivedyPartners] = useState([]);

	const createPreferenceFieldObject = (profileData) => {
		const prefernceFieldObject = {};
		preferenceRowFields.forEach((field) => {
			if (field.key === 'printItemLabel') {
				prefernceFieldObject[field.key] = !profileData[field.key] ? true : Boolean(profileData.printItemLabel === '1');
				return;
			}
			prefernceFieldObject[field.key] = Boolean(profileData[field.key] === '1');
		});
		return prefernceFieldObject;
	};

	const filterDeliveryPartners = (data) => {
		data = data
			.filter((e) => {
				return e.status === true;
			})
			?.map((e) => e.name);
		return data;
	};

	const fetchDeliveryPartners = async () => {
		try {
			const {
				data: { responseBody }
			} = await apiUms.get('/ums/profile/deliveryPartners');
			const { deliveryPartners } = responseBody;
			if (deliveryPartners.length > 0) {
				const filteredData = filterDeliveryPartners(deliveryPartners);
				setDelivedyPartners(filteredData);
			}
		} catch (error) {
			errorHandler(error);
		}
	};

	const onChangePreference = async (updatedValue, fieldName, rtoOtherWh = false) => {
		console.log(updatedValue, fieldName);
		if (updatedValue && !rtoOtherWh && fieldName === IPreferenceConstant.RTO_TO_OTHER_WAREHOUSE) {
			setIsRtoToOtherWh(true);
			return;
		}
		if (rtoOtherWh) {
			setIsRtoToOtherWh(false);
		}
		amplitude.getInstance().logEvent(`Clicked on ${fieldName} and status in ${updatedValue}`);
		setLoading(true);
		try {
			const updatedPreferenceFields = { ...preferenceFields };
			updatedPreferenceFields[fieldName] = updatedValue;

			setPreferenceFields(updatedPreferenceFields);

			await updatePreference(preferenceFields, fieldName, updatedValue);

			notification.success({
				message: 'Success',
				description: 'Preferences updated successfully',
				placement: 'topRight'
			});
			await revalidate();
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (profile) {
			const updatedFields = createPreferenceFieldObject(profile);
			setPreferenceFields(updatedFields);
		}
	}, [profile, loading]);

	const getWarehouses = async () => {
		try {
			const {
				data: { warehouses }
			} = await apiWms.get('/company/warehouses');
			if (warehouse) {
				let updatedWarehouse = warehouses.filter((e) => e.active === true);
				setWarehouse(updatedWarehouse);
			}
		} catch (err) {
			errorHandler(err);
		}
	};

	useEffect(() => {
		fetchDeliveryPartners();
		getWarehouses();
	}, []);

	const isSwitchDisabledForPreferenceField = (field) => {
		if (loading) {
			return true;
		}
		return preferenceFields[field] === '1';
	};

	if (isAdmin && !companyId) {
		return <NoCompany />;
	}

	return (
		<div className={styles.profilePage}>
			{isRtoToOtherWh && <WarehouseModal onOk={onChangePreference} warehouse={warehouse} setIsModalVisible={setIsRtoToOtherWh} />}
			<Typography.Title level={3}>Preference Details</Typography.Title>
			<Loading loading={loading} />
			<Row className={styles.row1}>
				<Col span={24}>
					<Row>
						<Col span={12}>
							<Typography.Title level={4}>Fulfillment Preferences</Typography.Title>
						</Col>
						<Col span={12}>
							<Typography.Title level={4}>Action</Typography.Title>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row className={styles.rowData}>
						{preferenceRowFields.map((item) => {
							if (item.key === 'rtoToOtherWarehouse') {
								return (
									<Col span={24} key={item.key}>
										<Row className={styles.preferenceRow}>
											<Col span={12}>
												<span className="description">{item.description}</span>
											</Col>
											<Col span={12}>
												<Switch
													checkedChildren="ON"
													disabled={!deliveryPartners.includes(FulfillmentProviders.CLICKPOST)}
													unCheckedChildren="OFF"
													checked={preferenceFields[item.key]}
													onChange={(updatedStatus) => onChangePreference(updatedStatus, item.key)}
												/>
											</Col>
										</Row>
									</Col>
								);
							}
							return (
								<Col span={24} key={item.key}>
									<Row className={styles.preferenceRow}>
										<Col span={12}>
											<span className="description">{item.description}</span>
										</Col>
										<Col span={12}>
											<Switch
												checkedChildren="ON"
												disabled={isSwitchDisabledForPreferenceField(item)}
												unCheckedChildren="OFF"
												checked={preferenceFields[item.key]}
												onChange={(updatedStatus) => onChangePreference(updatedStatus, item.key)}
											/>
										</Col>
									</Row>
								</Col>
							);
						})}

						<WhatsappPreference
							profile={profile}
							isAdmin={isAdmin}
							revalidate={revalidate}
							companyId={companyId}
							setLoading={setLoading}
						/>
					</Row>
				</Col>
			</Row>
		</div>
	);
}
