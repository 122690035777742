import { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AppLayout } from 'shared/components/AppLayout';
import { dashboardRoute, Dashboard } from './Dashboard';
import { SettingsPage, settingsRoute } from './SettingsPage';
import { Orders, orderRoute, ManualOrder, manualOrderRoute } from './Orders';
import { Inventory, inventoryRoute } from './Inventory';
import { Catalog, catalogRoute } from './Catalog';
import { AwaitingPickup, awaitingPickupRoute } from '../../shared/components/wms/AwaitingPickup/AwaitingPickup';
import { AuthContext } from 'shared/contexts/Auth';
import { Loading } from 'shared/components/Loading';
import { ShopifyRedirect, shopifyRedirectRoute } from './ShopifyRedirect';
import { topBar } from './utils';
import { BulkPreview } from 'shared/components/UploadBulkPreview/Preview';
import { bulkCataloguePreviewRoute, bulkOrderPreviewRoute } from 'shared/components/UploadBulkPreview/constants';
import Overview, { overviewRoute } from './Analytics/Overview';

export const AppRoutes = [
	'/',
	'/app',
	dashboardRoute,
	orderRoute,
	inventoryRoute,
	settingsRoute,
	shopifyRedirectRoute,
	catalogRoute,
	manualOrderRoute,
	awaitingPickupRoute,
	bulkOrderPreviewRoute,
	bulkCataloguePreviewRoute,
	overviewRoute
];

export function App() {
	const currentRoute = get(useLocation(), 'pathname');

	const [loading, setLoading] = useState(false);
	const { authActions, authState } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);
		authActions.obtainProfileData().finally(() => setLoading(false));
	}, []);
	return (
		<>
			<Loading loading={loading} />
			{authState.profileLoaded && (
				<AppLayout currentRoute={currentRoute} topBar={topBar}>
					<Switch>
						<Route exact path={['/app', '/']} render={() => <Redirect to={orderRoute} />} />
						<Route exact path={dashboardRoute} component={Dashboard} />
						<Route exact path={manualOrderRoute} component={ManualOrder} />
						<Route exact path={orderRoute} component={Orders} />
						<Route exact path={overviewRoute} component={Overview} />
						<Route exact path={settingsRoute} render={() => <SettingsPage currentSideBarSelection={1} />} />
						<Route exact path={inventoryRoute} component={Inventory} />
						<Route exact path={shopifyRedirectRoute} component={ShopifyRedirect} />
						<Route exact path={catalogRoute} component={Catalog} />
						<Route exact path={awaitingPickupRoute} component={AwaitingPickup} />
						<Route exact path={bulkOrderPreviewRoute} component={BulkPreview} />
						<Route exact path={bulkCataloguePreviewRoute} component={BulkPreview} />
						<Redirect from="*" to="/" />
					</Switch>
				</AppLayout>
			)}
		</>
	);
}
