import dayjs from 'dayjs';

export const searchOptions = [
	{ value: 'shopOrderId', label: 'Order ID' },
	{ value: 'deliveryAwb', label: 'AWB' },
	{ value: 'deliveryPartnerId', label: 'Delivery Partner' },
	{ value: 'paymentMode', label: 'Payment Mode' },
	{ value: 'shopType', label: 'Shop Type' },
	{ value: 'shippingFirstName', label: 'Customer name' },
	{ value: 'shippingPhone', label: 'Customer phone' },
	{ value: 'shippingState', label: 'Delivery State' },
	{ value: 'shippingPincode', label: 'Delivery Pincode' },
	{ value: 'pickupPincode', label: 'Pickup Pincode' },
	{ value: 'pickupState', label: 'Pickup State' }
];

export const defaultFilter = {
	startDate: dayjs().subtract(30, 'day'),
	endDate: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const orderCancelReasons = ["Order Cancelled on seller's request", 'Order not found on easy ecom', 'Other'];
