import { DownloadOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { searchOptions } from './constants';
import { ORDER_STATUS } from '../../../../constants';
import { DebouncedCompactSearch } from '../../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';

export function topFilterUtil({ filter, setFilter, setPageConfigDefault, onDownloadOrders }) {
	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((v) => ({ ...v, startDate, endDate }));
	};

	const handleOrderStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};

	return {
		row1: [
			{
				label: 'FILTER BY DATE',
				onChange: (dates, dateStrings) => setDate(...dateStrings),
				type: 'range',
				allowClear: false,
				value: [dayjs(filter.startDate), dayjs(filter.endDate)],
				defaultValue: [dayjs(filter.startDate), dayjs(filter.endDate)],
				ranges: {
					Today: [dayjs(), dayjs()],
					'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
					'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
					'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
					'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
				}
			}
		],
		row1children: [
			<div className={styles.searchContainer}>
				<DebouncedCompactSearch
					defaultSearchOptionType={searchOptions[0].label}
					searchOptions={searchOptions}
					setFilter={setFilter}
					setDefault={setPageConfigDefault}
				>
					<label>Search By:</label>
				</DebouncedCompactSearch>
			</div>,
			<div className={styles.orderStatusFilter}>
				<label>Status:</label>
				<Select
					mode="multiple"
					maxTagCount="responsive"
					placeholder="Select Order Status"
					style={{ width: '100%' }}
					allowClear
					value={filter.status}
					onChange={handleOrderStatusChange}
				>
					{Object.keys(ORDER_STATUS).map((orderStatus) => {
						return <Select.Option key={orderStatus}>{orderStatus}</Select.Option>;
					})}
				</Select>
			</div>,
			<Tooltip placement="left" title={'Download Brands CSV'}>
				<Button
					type="primary"
					className={styles.downloadButton}
					shape={'round'}
					icon={<DownloadOutlined />}
					onClick={onDownloadOrders}
					size="large"
				/>
			</Tooltip>
		],
		row2: []
	};
}
