import { Button, Select } from 'antd';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import PackageModal from './components/PackageModal';
import { searchOptions } from './constants';
import { defaultPageConfig } from '../../../../constants';
import styles from './index.module.scss';

export const topBarConfig = ({
	setPageConfig,
	filter,
	setFilter,
	isModalVisible,
	setIsModalVisible,
	getShippingPackages,
	editPackageData,
	setPageConfigDefault
}) => {
	const handleActivityChange = (active) => {
		setPageConfig(defaultPageConfig);
		setFilter((prevFilter) => ({ ...prevFilter, active }));
	};

	const activity = [
		{
			status: 'Active',
			value: 1
		},
		{
			status: 'Inactive',
			value: 0
		}
	];

	const openCreatePackageModal = () => {
		setIsModalVisible(true);
	};

	return {
		row5: [
			<div className={styles.topFilter}>
				{isModalVisible && Object.keys(editPackageData).length !== 0 ? (
					<PackageModal
						setIsModalVisible={setIsModalVisible}
						isModalVisible={isModalVisible}
						getShippingPackages={getShippingPackages}
					/>
				) : null}
				<div className={styles.topLeftFilter}>
					<DebouncedCompactSearch
						defaultSearchOptionType={searchOptions[0].label}
						searchOptions={searchOptions}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
					>
						<label>Search By:</label>
					</DebouncedCompactSearch>
					<div className={styles.searchElement}>
						<label>Is Active:</label> <br></br>
						<Select
							mode="single"
							placeholder="Select Activity"
							style={{ width: '150px' }}
							allowClear
							defaultValue={activity[0].value}
							value={filter.active}
							optionFilterProp={'children'}
							filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}
							onChange={handleActivityChange}
						>
							{activity.map((a) => {
								return (
									<Select.Option key={a.value} value={a.value}>
										{a.status}
									</Select.Option>
								);
							})}
						</Select>
					</div>
					<Button size="small" type="primary" className={styles.addButton} onClick={openCreatePackageModal}>
						Create New Package
					</Button>
				</div>
			</div>
		],
		shouldShowRow5: true
	};
};
