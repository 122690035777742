import { Col, Popover, Button, Timeline } from 'antd';
import { useState } from 'react';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

export const InventoryTrail = ({ trailDetails }) => {
	const [showRemarks, setShowRemarks] = useState(false);
	let descriptionComponent;
	const description = `${String(trailDetails.remarks).split('ID:')[0]} ${
		trailDetails.createdAt && `on ${new Date(trailDetails?.createdAt).toLocaleDateString()}`
	}`;

	if (trailDetails) {
		descriptionComponent = (
			<Col className={styles.iconWrap}>
				<Popover
					placement="left"
					content={description}
					title="Remarks"
					trigger="click"
					visible={showRemarks}
					onVisibleChange={() => setShowRemarks((showRemarks) => !showRemarks)}
				>
					<Button type="text" icon={showRemarks ? <InfoCircleFilled /> : <InfoCircleOutlined />} />
				</Popover>
			</Col>
		);
	}

	if (parseInt(trailDetails.availableQuantityChange) !== 0) {
		return (
			<Timeline.Item>
				<span className={styles.timelineText}>
					{trailDetails.remarks?.split(',')[0]}
					{descriptionComponent}
				</span>
			</Timeline.Item>
		);
	}
	return null;
};
