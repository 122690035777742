import { Tooltip } from 'antd';
import React from 'react';
import styles from './index.module.scss';

export const inventoryColumns = ({ sortField, sortOrder, clickInventoryId }) => [
	{
		title: 'WSIN',
		dataIndex: 'wsin',
		key: 'wsin',
		width: 150,
		fixed: 'left',
		render: (v, inventory) => (
			<div
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					clickInventoryId(inventory.id);
				}}
				className={styles.skuText}
			>
				<Tooltip placement="top" title={inventory?.name}>
					<span>{inventory.wsin}</span>
				</Tooltip>
			</div>
		)
	},
	{
		title: 'Inbound',
		children: [
			{
				title: 'Inbound Accepted',
				dataIndex: 'inboundAccepted',
				key: 'id',
				width: 150
			},
			{
				title: 'Inbound Rejected',
				dataIndex: 'inboundRejected',
				key: 'id',
				width: 150
			}
		]
	},
	{
		title: 'Outbound',
		children: [
			{
				title: 'Outbound Delivered',
				dataIndex: 'outboundDelivered',
				key: 'id',
				width: 150
			},
			{
				title: 'Outbound Out',
				dataIndex: 'outboundOut',
				key: 'id',
				width: 150
			}
		]
	},
	{
		title: 'Return',
		children: [
			{
				title: 'Return In',
				dataIndex: 'returnedIn',
				key: 'id',
				width: 150
			},
			{
				title: 'Return Accepted',
				dataIndex: 'returnAccepted',
				key: 'id',
				width: 150
			},
			{
				title: 'Return Missing',
				dataIndex: 'returnMissing',
				key: 'id',
				width: 150
			},
			{
				title: 'Return Damaged',
				dataIndex: 'returnDamaged',
				key: 'id',
				width: 150
			}
		]
	},
	{
		title: 'Adjusted Inventory',
		dataIndex: 'adjustedInventory',
		key: 'id',
		width: 150
	},
	{
		title: 'Blocked Stock',
		dataIndex: 'blockedStock',
		key: 'id',
		width: 150
	},
	{
		title: 'Safe Stock',
		dataIndex: 'safeStock',
		key: 'id',
		width: 150
	},
	{
		title: 'Current Stock',
		dataIndex: 'currentStock',
		key: 'id',
		width: 150,
		fixed: 'right',
		render: (v, inventory) => (
			<div>
				<Tooltip placement="top" title={`Available Stock: ${inventory?.currentStock - inventory?.blockedStock}`}>
					<span>{inventory?.currentStock}</span>
				</Tooltip>
			</div>
		)
	}
];
