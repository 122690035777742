import { Button, Col, notification, Row } from 'antd';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { apiCms } from 'shared/api';
import { getCatalogueById, getWsinForAutoComplete, updateCatalog } from 'shared/api/catalog';
import { Loading } from 'shared/components/Loading';
import errorHandler from 'shared/utils/errorHandler';
import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { getWSINMapFormat } from '../Catalog/constants';
import { FormLayout } from '../FormLayout';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import { CatalogueFormFields } from './FormFields';
import styles from './index.module.scss';

const LazyDynamicInputs = React.lazy(() => import('shared/components/DynamicInputs/DynamicInputs'));

export function CatalogueInfoSlider({ onClose, catalogueId, reloadList, shopType, shouldEdit = true, isAdminView, companyId }) {
	const isMounted = useRef(false);
	const [loading, setLoading] = useState(false);
	const [reloadCatalogue, setReloadCatalogue] = useState(0);
	const [data, setData] = useState(null);
	const [isCatalogueFormVisible, setCatalogueFormVisibility] = useState(false);
	const [showInputs, setShowInputs] = useState(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);
	useEffect(() => {
		fetchCatalogueDetails();
	}, [catalogueId, reloadCatalogue]);

	async function fetchCatalogueDetails() {
		try {
			setLoading(true);
			const { data } = await getCatalogueById(catalogueId, isAdminView);
			if (!isMounted.current) return;
			setData(data.catalogue);
		} catch (error) {
			if (!isMounted.current) return;
			notification.error({
				message: 'Server Error',
				description: 'Please try again after some time.',
				placement: 'topRight'
			});
			setData(null);
		} finally {
			isMounted.current && setLoading(false);
		}
	}

	const onCatalogueDetailsSubmit = async (values) => {
		try {
			await updateCatalog(catalogueId, {
				shopType,
				productId: data.productId,
				variantId: data.variantId,
				productTitle: values.productTitle,
				variantTitle: values.variantTitle,
				category: values.category,
				weight: values.weight,
				weightUnit: 'g',
				active: values.active,
				price: values.price,
				hsnCode: values.hsnCode,
				handle: values.handle,
				productType: values.category,
				dimensions: {
					length: values.length,
					breadth: values.breadth,
					height: values.height
				},
				gstPercentage: { sgst: values.sgst, cgst: values.cgst, igst: values.igst, utgst: values.utgst || values.sgst }
			});
			notification.success({
				message: 'Update Product',
				description: 'updated successfully.',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			setCatalogueFormVisibility(false);
			setReloadCatalogue((prevValue) => prevValue + 1);
			reloadList();
		}
	};

	const handleWsinMap = async (inputs) => {
		try {
			const wsinMap = {};
			inputs.forEach((item) => (wsinMap[item.wsin] = Number(item.quantity)));

			const {
				data: { status, message }
			} = await apiCms.post(
				'/catalogue/map-wsin',
				{
					companyId,
					catalogueId: data.id,
					data: wsinMap
				},
				{ headers: { 'x-source': 'ingress' } }
			);
			if (!status) errorHandler(new Error(message));
			notification.success({
				message: 'WSIN Mapped Successfully',
				description: 'Mapped successfully.',
				placement: 'topRight'
			});
			setReloadCatalogue((prevValue) => prevValue + 1);
			reloadList();
		} catch (error) {
			errorHandler(error);
		}
	};

	return (
		<InfoSlider onClose={onClose}>
			<div className={styles.main}>
				{loading && <Loading loading={true} />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />
				{data ? (
					<Col className={styles.catalogueInfoContainer}>
						<Row className={styles.paddedRow}>
							<Col span={24}>
								{isCatalogueFormVisible ? (
									<div className={styles.editForm}>
										<FormLayout
											formTitle={'Edit Catalog Details'}
											onSave={(values) => onCatalogueDetailsSubmit(values)}
											onClose={() => setCatalogueFormVisibility(false)}
											formFields={CatalogueFormFields}
											initialValues={{
												productTitle: data?.productTitle,
												variantTitle: data?.variantTitle,
												// sku: data?.sku,
												hsnCode: data?.hsnCode,
												category: data?.category,
												handle: data.handle,
												active: data?.active,
												price: data?.price,
												length: data?.dimensions?.length,
												breadth: data?.dimensions?.breadth,
												height: data?.dimensions?.height,
												weight: data?.weight,
												sgst: data?.gstPercentage?.sgst,
												cgst: data?.gstPercentage?.cgst,
												igst: data?.gstPercentage?.igst,
												utgst: data?.gstPercentage?.utgst
											}}
										/>
									</div>
								) : (
									<Row className={styles.row1}>
										<Col span={24}>
											<Row>
												<Col span={18}>
													<span className="title">Product Details</span>
												</Col>
												{shouldEdit && !isAdminView && (
													<Col className={styles.icon} span={6}>
														<Button
															className={styles.button}
															onClick={(e) => {
																e.stopPropagation();
																setCatalogueFormVisibility(true);
															}}
															type="primary"
														>
															Edit
														</Button>
														{/* <EditOutlined onClick={() => setCatalogueFormVisibility(true)} /> */}
													</Col>
												)}
											</Row>
										</Col>
										<Col span={24} className="sub">
											<Row className="row1-row">
												<Col className={styles.col} span={11}>
													<span className="left-text">Serial No:</span>
													<span>{`${data.productId}`}</span>
												</Col>
												<Col className={styles.col} span={11}>
													<span className="left-text">Item Name:</span> <span>{data.productTitle}</span>
												</Col>
											</Row>
											<Row className="row1-row">
												<Col className={styles.col} span={11}>
													<span className="left-text">Item Variant Name:</span> <span>{data.variantTitle}</span>
												</Col>
												<Col className={styles.col} span={11}>
													<span className="left-text">Handle:</span> <span>{data.handle}</span>
												</Col>
											</Row>

											<Row className="row1-row">
												<Col className={styles.col} span={11}>
													<span className="left-text">Sku Id:</span>
													<span>{`${data.sku}`}</span>
												</Col>
												<Col className={styles.col} span={11}>
													<span className="left-text">Category:</span>
													<span>{`${data.category}`}</span>
												</Col>
											</Row>

											<Row className="row1-row">
												<Col className={styles.col} span={11}>
													<span className="left-text">Type:</span>
													<span>{`${data.productType}`}</span>
												</Col>
												<Col className={styles.col} span={11}>
													<span className="left-text">Price:</span>
													<span>{`${data.price}`}</span>
												</Col>
											</Row>

											<Row className="row1-row">
												<Col className={styles.col} span={11}>
													<span className="left-text">LxBxH (cm):</span>
													<span>{`${data?.dimensions?.length}x${data?.dimensions?.breadth}x${data?.dimensions?.height}`}</span>
												</Col>
												<Col className={styles.col} span={11}>
													<span className="left-text">Weight (gm):</span>
													<span>{`${data.weight}`}</span>
												</Col>
											</Row>

											<Row className="row1-row">
												<Col className={styles.col} span={11}>
													<span className="left-text">Stock:</span>
													<span>{`${data.inventoryQuantity}`}</span>
												</Col>
												<Col className={styles.col} span={11}>
													<span className="left-text">Status:</span>
													<span>{`${data.active ? 'Active' : 'InActive'}`}</span>
												</Col>
											</Row>
											<Row className="row1-row">
												<Col className={styles.col} span={22}>
													<span className="left-text">WSIN Map:</span>
													<span>{`${data.wsinMapping ? getWSINMapFormat(data.wsinMapping) : '-'}`}</span>
												</Col>
											</Row>
											{isAdminView && !data.wsinMapping ? (
												<Row className="row1-row">
													<Button type="primary" onClick={() => setShowInputs((prev) => !prev)}>
														Map WSIN
													</Button>
													{showInputs ? (
														<Suspense fallback={<Loading />}>
															<LazyDynamicInputs
																inputFields={[
																	{
																		inputKey: 'wsin',
																		defaultValue: '',
																		inputType: 'text',
																		autoComlete: true,
																		getOptions: getWsinForAutoComplete,
																		placeholder: 'WSIN'
																	},
																	{
																		inputKey: 'quantity',
																		defaultValue: 1,
																		inputType: 'number',
																		autoComlete: false,
																		placeholder: 'QUANTITY',
																		min: 1
																	}
																]}
																companyId={companyId}
																handleSubmit={handleWsinMap}
															/>
														</Suspense>
													) : null}
												</Row>
											) : null}
										</Col>
									</Row>
								)}
							</Col>
						</Row>
					</Col>
				) : (
					!loading && <div className="noDataDefaultComponent">Nothing to do here</div>
				)}
			</div>
		</InfoSlider>
	);
}
