import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Tooltip } from 'antd';
import styles from './index.module.scss';

export const OrderDetails = ({ customStyle, catalogData }) => {
	return (
		<div className={`${styles.orderDetails} ${customStyle}`}>
			<h4 className={styles.heading}>Order Details</h4>

			<Row>
				<Col>
					<Form.Item name="id" label="Order ID" rules={[{ required: true, message: 'Please input Order ID!' }]}>
						<Input placeholder="WY-1012" maxLength={40} />
					</Form.Item>
				</Col>
				<Col style={{ marginLeft: '1rem' }}>
					<Form.Item name="purchaseOrderNumber" label="Purchase Order Number">
						<Input placeholder="Optional" maxLength={40} />
					</Form.Item>
				</Col>
			</Row>

			<Form.List name="items">
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, fieldKey, ...restField }) => (
							<Space key={key} style={{ display: 'flex', marginBottom: 4, columnGap: '3rem' }} align="center">
								<Form.Item
									{...restField}
									name={[name, 'id']}
									label="Product"
									rules={[{ required: true, message: 'Please select product' }]}
								>
									<Select style={{ width: '400px' }} placeholder="Select product" showSearch optionFilterProp="children">
										{catalogData.map(({ id, productTitle, sku }) => {
											return <Select.Option value={id}>{`${productTitle} (${sku})`}</Select.Option>;
										})}
									</Select>
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'quantity']}
									label="Quantity"
									rules={[{ required: true, message: 'Please enter quantity' }]}
								>
									<InputNumber placeholder="3" min={1} />
								</Form.Item>
								<Form.Item
									{...restField}
									name={[name, 'gst']}
									label="GST (%)"
									initialValue={0}
									rules={[{ required: true, message: 'Please enter GST' }]}
								>
									<InputNumber min={0} max={100} />
								</Form.Item>
								<Tooltip title="Defult catalog price will be set">
									<Form.Item
										{...restField}
										name={[name, 'price']}
										label="Price per quantity (₹)"
										initialValue={undefined}
									>
										<InputNumber min={0} placeholder="Optional" />
									</Form.Item>
								</Tooltip>
								<Button danger onClick={() => remove(name)} block icon={<DeleteOutlined />}>
									Delete
								</Button>
							</Space>
						))}
						<Form.Item>
							<Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: '200px' }}>
								Add Product
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
		</div>
	);
};
