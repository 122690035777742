import { ErrorBoundary } from '@sentry/react';
import { Col, notification, Row } from 'antd';
import { downloadAsFile } from 'helper';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import reactDom from 'react-dom';
import { useHistory } from 'react-router-dom';
import { baseApi } from 'shared/api';
import {
	downloadLablesAndMail,
	downloadMoreOrdersAndMail,
	downloadOrdersCSV,
	downloadOrdersPickList,
	getCompanyOrders
} from 'shared/api/orders';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { CatalogueInfoSlider } from 'shared/components/CatalogueInfoSlider';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { OrderInfoSlider } from 'shared/components/OrderInfoSlider';
import { bulkOrderPreviewRoute, MAX_ROWS_EXCEED_ERROR_MSG } from 'shared/components/UploadBulkPreview/constants';
import { AuthContext } from 'shared/contexts/Auth';
import errorHandler from 'shared/utils/errorHandler';
import { EVENT_NAMES } from 'utils/analytics';
import { defaultPageConfig, ORDER_STATUS } from '../../../constants';
import { RequestEmailModal } from '../../../shared/components/EmailRequestModal';
import { NdrCustomerRequests } from '../../../shared/components/NdrCustomerRequests.js/CustomerRequests';
import { UploadBulkModal } from '../../../shared/components/UploadBulkModal';
import amplitude from '../../../shared/utils/Amplitude';
import { envs } from '../../../shared/utils/env';
import { Fallback } from '../ErrorBoundaryFallback';
import { useColumns } from './columns';
import { CommonSideBar } from './CommmonSideBar';
import { defaultFilter, OrderPageOptions, orderPageOptionsWithCommonTable } from './constants';
import { MissedOrders } from './MissedOrders';
import { getFilterFromSessionStorage, sidebarUtil, topFilterUtil } from './utils';
export const orderRoute = '/orders';

const RenderCurrentTabTable = ({ tabIndex, tableType, children }) => {
	if (tableType === 'Orders' && orderPageOptionsWithCommonTable.includes(tabIndex)) {
		return children;
	}
	if (tableType === 'missed-order' && tabIndex === '5') {
		return children;
	}
	if (tableType === 'CustomerRequests' && tabIndex === '6') {
		return children;
	}
	return null;
};

export function Orders() {
	const [isUploadBulkOrderModalVisible, setIsUploadBulkOrderModalVisible] = useState(false);
	const { authState } = useContext(AuthContext);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [data, setData] = useState([]);
	const [currentCount, setCurrentCount] = useState(0);
	const [missedOrderCount, setMissedOrderCount] = useState(0);
	const [counts, seCounts] = useState({});
	const [openOrderInfo, setOpenOrderInfo] = useState(false);
	const [openProductOrderInfo, setOpenProductOrderInfo] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [sidebarMenuItem, setSidebarMenuItem] = useState(sessionStorage.getItem('sidebarMenuItem') || '1');
	const [filter, setFilter] = useState(getFilterFromSessionStorage);
	const [rtsProgress, setRTSProgress] = useState(false);
	const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
	const [IsLabelEmailModalVisible, setIsLabelEmailModalVisible] = useState(false);
	const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
	const [isOrdersLoading, setIsOrdersLoading] = useState(false);
	const [reloadOrders, setReloadOrders] = useState(0);
	const abortControllerRef = useRef(null);
	const history = useHistory();

	const columns = useColumns({
		clickOrderId: setOpenOrderInfo,
		clickProductId: setOpenProductOrderInfo,
		sortField: pageConfig.sortField,
		sortOrder: pageConfig.sortOrder
	});
	const [progress, setProgress] = useState(0);
	const memoizedProgress = useMemo(() => progress, [progress]);
	/**
	 * @param taskId - You get this from the response after bulk RTS
	 * @description This function is called after bulk rts with the generated taskId.
	 * It calls the queue track api with the help of setInterval in a interval of 1.5s and checks for the status,
	 * as the status is succeeded the interval is cleared,the batch id is removed from local storage and a success notification in thrown.
	 * Since then the progress is updated and is shown to the user. The batch id is stored in local storage to preserve the data even after hard-reload.
	 */
	const trackBulkRTS = useCallback(
		(taskId) => {
			localStorage.setItem(`BULK_RTS_BATCH_ID_${authState.profile.id}`, taskId);
			setRTSProgress(true);
			const interval = setInterval(async () => {
				try {
					const response = await baseApi.get(`queue/rts-queue/${taskId}`);
					if (response.data.progress === 100) {
						clearInterval(interval);
						localStorage.removeItem(`BULK_RTS_BATCH_ID_${authState.profile.id}`);
						reactDom.unstable_batchedUpdates(() => {
							setRTSProgress(false);
							setProgress(0);
						});

						notification.success({ message: 'Ready To Ship Success' });
					} else {
						setProgress((prev) => (prev > response.data.progress.toFixed(0) ? prev : response.data.progress.toFixed(0)));
					}
				} catch (error) {
					clearInterval(interval);
					reactDom.unstable_batchedUpdates(() => {
						setRTSProgress(false);
						setProgress(0);
					});
					localStorage.removeItem(`BULK_RTS_BATCH_ID_${authState.profile.id}`);
				}
			}, 1000);
		},
		[progress]
	);

	useEffect(() => {
		const taskIdFromLocalStorage = localStorage.getItem(`BULK_RTS_BATCH_ID_${authState.profile.id}`);
		if (taskIdFromLocalStorage) {
			setRTSProgress(true);
			trackBulkRTS(taskIdFromLocalStorage);
		}
	}, []);

	const sidebarConfig = useMemo(() => sidebarUtil({ counts, currentCount, missedOrderCount }), [counts, currentCount, missedOrderCount]);
	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				counts,
				filter,
				setFilter,
				pageConfig,
				setPageConfigDefault,
				onDownloadOrders,
				onDownloadOrderPickList,
				sidebarMenuItem,
				selectedRowKeys,
				setPageConfig,
				trackBulkRTS,
				rtsProgress,
				setIsLabelEmailModalVisible
			}),
		[counts, filter, pageConfig, sidebarMenuItem, selectedRowKeys, rtsProgress]
	);

	useEffect(() => {
		setSelectedRowKeys([]);
		// setting expiry time in 6h form now
		const rangeFilter = {
			expiresAt: new Date(new Date().getTime() + 21600000),
			filter: filter
		};
		sessionStorage.setItem('rangeFilter', JSON.stringify(rangeFilter));
	}, [filter]);

	useEffect(() => {
		switch (sidebarMenuItem) {
			case '1':
				// we should not set default filters because filter might be coming from session
				// storage (set initially in usestate).. overwriting default filter to handle falsy value of filter obj
				setFilter(() => ({ ...defaultFilter }));
				break;
			case '2':
				setFilter((filter) => ({ ...filter, status: [ORDER_STATUS.UNSERVICEABLE] }));
				break;
			case '3':
				setFilter((filter) => ({ ...filter, status: [ORDER_STATUS.UNDELIVERED, ORDER_STATUS.RTO_UNDELIVERED] }));
				break;
			case '4':
				setFilter((filter) => ({
					...filter,
					status: [
						ORDER_STATUS.RTO_IN_TRANSIT,
						ORDER_STATUS.RTO_DELIVERED,
						ORDER_STATUS.RTO_OUT_FOR_DELIVERY,
						ORDER_STATUS.RTO_PROCESSED,
						ORDER_STATUS.DTO_IN_TRANSIT,
						ORDER_STATUS.DTO_DELIVERED,
						ORDER_STATUS.DTO_OUT_FOR_DELIVERY,
						ORDER_STATUS.DTO_PROCESSED
					]
				}));
				break;
			default:
				break;
		}
	}, [sidebarMenuItem]);

	useEffect(() => {
		async function getAllOrders() {
			try {
				setIsOrdersLoading(true);
				const { data: ordersData } = await getCompanyOrders(pageConfig, filter, abortControllerRef);
				if (ordersData.status) {
					reactDom.unstable_batchedUpdates(() => {
						setData(ordersData.order);
						seCounts(ordersData.count || {});
						setCurrentCount(ordersData.currentCount || 0);
						setMissedOrderCount(ordersData.missedOrderCount || 0);
						setIsOrdersLoading(false);
					});
					abortControllerRef.current = null;
				} else {
					throw new Error(ordersData.message || 'Failed to get orders');
				}
			} catch (error) {
				if (error.message === 'canceled') return;
				setIsOrdersLoading(false);
				notification.error({
					message: 'Error',
					description: error.message,
					placement: 'topRight'
				});
			}
		}
		getAllOrders();
		return () => abortControllerRef.current && abortControllerRef.current.abort();
	}, [pageConfig, filter, reloadOrders]);

	function handleTableChange(_pagination, _filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}
	async function onDownloadOrders(data) {
		amplitude.getInstance().logEvent(EVENT_NAMES.DOWNLOAD_ORDER_EXCEL);
		try {
			if (filter?.status && counts[filter.status] === 0) {
				throw new Error('There are no orders available for the selected filter');
			}

			const csvFilter = { ...filter, appType: envs.appType };
			const shouldEmailOpen = await downloadOrdersCSV(csvFilter, data);
			setIsEmailModalVisible(shouldEmailOpen);
		} catch (err) {
			errorHandler(err);
		}
	}

	async function onDownloadOrderPickList() {
		try {
			setIsOrdersLoading(true);

			if (!(selectedRowKeys && selectedRowKeys.length)) {
				throw new Error('Please select at least one order to generate pick list excel');
			}

			let orderPickListData = await downloadOrdersPickList(selectedRowKeys);
			downloadAsFile({ data: orderPickListData.data, fileName: `PickList`, fileType: 'xlsx' });
		} catch (err) {
			errorHandler(err);
		} finally {
			setIsOrdersLoading(false);
		}
	}

	const onOrdersFormSubmit = async (values) => {
		try {
			setIsDownloadButtonLoading(true);
			const csvFilter = { ...filter, appType: envs.appType };
			await downloadMoreOrdersAndMail(csvFilter, values.email);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsEmailModalVisible(false);
		}
	};

	const onLabelFormSubmit = async (values) => {
		try {
			setIsDownloadButtonLoading(true);
			await downloadLablesAndMail(selectedRowKeys, values.email);
		} catch (e) {
		} finally {
			setIsDownloadButtonLoading(false);
			setIsLabelEmailModalVisible(false);
		}
	};

	useEffect(() => {
		let tabValue;
		tabValue = Object.keys(OrderPageOptions).find((key) => OrderPageOptions[key] == sidebarMenuItem);
		history.push({
			pathname: '/orders',
			search: `?tab=${tabValue}`
		});
		sessionStorage.setItem('sidebarMenuItem', String(sidebarMenuItem));
	}, [sidebarMenuItem]);

	return (
		<Row className="h-100 p-0">
			<RequestEmailModal
				title="Download Orders"
				visible={isEmailModalVisible}
				onCancel={() => {
					setIsEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onOrdersFormSubmit}
				instructionMessage="Requested data contains too many rows, please enter your email address to receive download url."
				loading={isDownloadButtonLoading}
			/>

			<RequestEmailModal
				title="Download Labels"
				visible={IsLabelEmailModalVisible}
				onCancel={() => {
					setIsLabelEmailModalVisible(false);
				}}
				footer={[]}
				width={500}
				onFinish={onLabelFormSubmit}
				instructionMessage="Requested data contains too many order, please enter your email address to receive url."
				loading={isDownloadButtonLoading}
			/>

			<UploadBulkModal
				instructionMessage={
					<ul className={'instructions'}>
						<li>Download the excel template by clicking "Download Sample" button below</li>
						<li>Edit file with order data and upload </li>
						<br />
						Note: {MAX_ROWS_EXCEED_ERROR_MSG}
					</ul>
				}
				templatePath={process.env.REACT_APP_EXCEL_ORDER_TEMPLATE}
				previewPath={bulkOrderPreviewRoute}
				modalTitle="Bulk Order Upload"
				isModalVisible={isUploadBulkOrderModalVisible}
				setIsModalVisible={setIsUploadBulkOrderModalVisible}
			/>
			<CommonSideBar
				sidebarMenuItem={sidebarMenuItem}
				setSidebarMenuItem={setSidebarMenuItem}
				isCommonOrder={true}
				sidebarConfig={sidebarConfig}
				setIsUploadBulkOrderModalVisible={setIsUploadBulkOrderModalVisible}
			/>

			<RenderCurrentTabTable tabIndex={sidebarMenuItem} tableType="Orders">
				<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
					<Col sm={24} lg={20} className="main">
						<TopFilterComponent {...topFilterConfig} memoizedProgress={memoizedProgress} />
						<div className="innerDiv">
							<div className="div w-100 h-100">
								<CustomTablePagination
									columns={columns}
									data={data}
									showSorterTooltip={true}
									{...pageConfig}
									onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
									total={currentCount}
									onChange={handleTableChange}
									loading={isOrdersLoading}
									selectedRowKeys={selectedRowKeys}
									setSelectedRowKeys={setSelectedRowKeys}
									emptyTableMessage={'Please integrate sales channel or create a manual order!'}
								/>
								{openProductOrderInfo && (
									<CatalogueInfoSlider catalogueId={openProductOrderInfo} onClose={() => setOpenProductOrderInfo(null)} />
								)}
								{openOrderInfo && (
									<OrderInfoSlider
										orderId={openOrderInfo}
										setReloadOrders={setReloadOrders}
										onClose={() => setOpenOrderInfo(null)}
									/>
								)}
							</div>
						</div>
					</Col>
				</ErrorBoundary>
			</RenderCurrentTabTable>
			<RenderCurrentTabTable tabIndex={sidebarMenuItem} tableType={'missed-order'}>
				<MissedOrders />
			</RenderCurrentTabTable>

			<RenderCurrentTabTable tabIndex={sidebarMenuItem} tableType="CustomerRequests">
				<NdrCustomerRequests isAdmin={false} />
			</RenderCurrentTabTable>
		</Row>
	);
}
