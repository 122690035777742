import React from 'react';
import { Button, Col, Row, Space } from 'antd';
import { DatePicker } from 'shared/components/DatePickers';
import styles from './index.module.scss';
import Row4ActionButton from './Row4ActionButton';

export function TopFilterComponent(props) {
	const { row1, row2, row3, row4, row5, row1children = [], shouldShowRow5 = false, memoizedProgress } = props;

	return (
		<div className={`${styles.main}`}>
			{row2 && (
				<Row justify="space-between" className={styles.row1}>
					<Col className={styles.date}>
						{row1 && (
							<div className={styles.center}>
								{row1.map(({ label, onChange, type, value, ...rest }, i) => (
									<Space direction="vertical" className={i !== 0 ? 'ml-3' : ''} key={i}>
										<label className={styles.filterByDateTitle}>{label}</label>
										{type === 'range' ? (
											<DatePicker.RangePicker onChange={onChange} {...rest} />
										) : (
											<DatePicker mode="date" onChange={onChange} {...rest} />
										)}
									</Space>
								))}

								{row1children.map((children) => children)}
							</div>
						)}
					</Col>
					<Col className={styles.download}>{row2.map((item) => item)}</Col>
				</Row>
			)}

			{row3 && (
				<Row justify="space-between" className={styles.row2}>
					<Col span={12}>
						<Space size={22}>
							{row3.map((item) => (
								<Button
									size="large"
									type={item.selected ? 'primary' : ''}
									shape="round"
									key={item.key}
									onClick={() => item.onClick(item.key)}
								>
									{item.label}
								</Button>
							))}
						</Space>
					</Col>
				</Row>
			)}

			{row4 && (
				<Row justify="space-between" className={`${styles.row3}`}>
					<Col span={12}>
						<Space size={22}>
							{row4.map((item, index) => {
								if (!item) {
									return null;
								}
								if (item.isNotButton) {
									return item.render(memoizedProgress);
								} else {
									return <Row4ActionButton key={index} item={item} />;
								}
							})}
						</Space>
					</Col>
				</Row>
			)}

			{row5 && shouldShowRow5 && <Row className={styles.row4}>{row5}</Row>}
		</div>
	);
}
