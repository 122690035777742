import axios from 'axios';
import { envs } from 'shared/utils/env';
import { initAxiosInterceptor } from './axios';
const { umsBaseUrl, omsBaseUrl, cmsBaseUrl, wmsBaseUrl, hmsBaseUrl, openBaseUrl, nmsBaseUrl } = envs;

export const apiUms = axios.create({
	baseURL: umsBaseUrl,
	withCredentials: true
});

export const apiOms = axios.create({
	baseURL: omsBaseUrl,
	withCredentials: true
});

export const apiCms = axios.create({
	baseURL: cmsBaseUrl,
	withCredentials: true
});

export const apiWms = axios.create({
	baseURL: wmsBaseUrl,
	withCredentials: true
});

export const apiHms = axios.create({
	baseURL: hmsBaseUrl,
	withCredentials: true
});

export const apiNms = axios.create({
	baseURL: nmsBaseUrl,
	withCredentials: true
});

export const apiNmsOpen = axios.create({
	baseURL: nmsBaseUrl,
	withCredentials: false
});

export const baseApi = axios.create({
	baseURL: openBaseUrl,
	withCredentials: true
});

/**
 * adds appType request header in requests
 */
initAxiosInterceptor([apiOms, apiUms, apiWms, apiCms, apiHms, baseApi, apiNms, apiNmsOpen]);
