import { decimalRegexValidator } from '../utils/regex';

export const cleanObject = function (obj, args) {
	for (var propName in obj) {
		if (args.includes(obj[propName])) {
			delete obj[propName];
		}
	}
	return obj;
};

export const moveArrayElement = (array, oldIndex, newIndex) => {
	if (newIndex >= array.length) {
		newIndex = array.length - 1;
	}
	array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
	return array;
};

export function downloadAsCsv(csvContent, name = 'my_data.csv') {
	var encodedUri = encodeURI(csvContent);
	var link = document.createElement('a');
	link.setAttribute('href', encodedUri);
	link.setAttribute('download', name);
	document.body.appendChild(link); // Required for FF
	link.click();
}

export const isValidNumber = (number) => {
	if (isNaN(Number(number))) {
		return {
			isValid: false,
			reason: 'Please provide a valid number'
		};
	}

	return { isValid: true };
};

export const isValidPhoneNumber = (phoneNumber) => {
	const { isValid, reason } = isValidNumber(phoneNumber);

	if (!isValid)
		return {
			isValid: false,
			reason
		};

	if (String(phoneNumber).length !== 10) {
		return {
			isValid: false,
			reason: 'Please provide a 10 digit number'
		};
	}

	return { isValid: true };
};

export const checkIsEmailValid = (value) => {
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const isValidEmail = emailRegex.test(String(value).toLowerCase());

	if (!isValidEmail) {
		return { isValid: false, reason: 'Email is invalid' };
	}

	return { isValid: true };
};

export const antDesignDecimalValueValidator = () => ({
	validator: async (_, value) => {
		if (!value) return;

		if (value < 0) throw new Error('Please input value greater than 0!');
		if (value > 100) throw new Error('Please input value less than 100!');

		const isValidDecimalNumber = decimalRegexValidator.test(value);
		if (!isValidDecimalNumber) throw new Error('Please input a decimal number (upto 2 places only)!');

		return;
	}
});

export const downloadAsFile = ({ data, fileName = 'download', fileType }) => {
	try {
		let downloadUrl;

		switch (fileType) {
			case 'zip':
				downloadUrl = window.URL.createObjectURL(new Blob([data]));
				break;

			case 'pdf':
				downloadUrl = `data:application/pdf;base64,${data}`;
				break;

			case 'xlsx':
				downloadUrl = window.URL.createObjectURL(new Blob([data]));
				break;

			default:
				throw new Error(`File type ${fileType} not found!`);
		}

		var URL = window.URL || window.webkitURL;
		var newDownloadUrl = URL.createObjectURL(data);
		window.open(newDownloadUrl, '_blank');

		// const link = document.createElement('a');
		// link.href = downloadUrl;
		// link.setAttribute('download', `${fileName}.${fileType}`);
		// document.body.appendChild(link);
		// link.click();
		// link.remove();
	} catch (error) {
		URL.revokeObjectURL(newDownloadUrl);
		throw error;
	}
};

export const isNumberAndNotZero = () => ({
	validator: async (_, value) => {
		if (!value) return;

		if (value < 0) throw new Error('Weight cannot be less than 0');
		if (value % 1 !== 0) throw new Error('Weight needs to be a whole number'); // remove this after weight is converted from 'int' to 'double' in the backend
		if (parseFloat(value) < 0.01) throw new Error('Weight cannot be 0');

		const isValidDecimalNumber = decimalRegexValidator.test(value);
		if (!isValidDecimalNumber) throw new Error('Please input a decimal number (upto 2 places only)!');

		return;
	}
});

export const antDesignValidator = {
	number: {
		validator: async (_, value) => {
			if (isNaN(Number(value))) {
				return Promise.reject('Please provide a number');
			}

			return Promise.resolve();
		}
	},
	positiveNumber: {
		validator: async (_, value) => {
			await antDesignValidator.number.validator(_, value);
			if (value < 0) {
				return Promise.reject('Please provide a positive number');
			}
			return Promise.resolve();
		}
	},
	phoneNumber: {
		validator: async (_, value) => {
			if (!value) return Promise.resolve();

			const { isValid, reason } = isValidPhoneNumber(value);
			if (isValid) return Promise.resolve();

			return Promise.reject(new Error(reason));
		}
	},
	pincode: {
		validator: async (_, value) => {
			if (!value) return Promise.resolve();

			const { isValid, reason } = isValidNumber(value);

			if (isValid) {
				if (String(value).length !== 6) {
					throw new Error('Pincode must be of 6 digits');
				}
				return Promise.resolve();
			} else {
				return Promise.reject(new Error(reason));
			}
		}
	}
};
