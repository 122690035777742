import { Checkbox, Col, notification, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { updatePreferenceDetailAdmin } from '../../api/profile';
import errorHandler from '../../utils/errorHandler';
import styles from './index.module.scss';
import { statusUpdateOptions, defaultWaModule, EWaModule } from './constants';

export const WhatsappPreference = ({ profile, companyId, isAdmin, revalidate, setLoading }) => {
	const [waModule, setWaModule] = useState(defaultWaModule);

	useEffect(() => {
		if (profile) {
			const waModules = profile.waModules;
			if (waModules) {
				setWaModule(JSON.parse(profile.waModules));
			} else {
				setWaModule(defaultWaModule);
			}
		}
	}, [profile]);

	const isSwitchChecked = (waModuleName) => {
		switch (waModuleName) {
			case EWaModule.NDR:
				return waModule[EWaModule.NDR]?.isActive ? true : false;

			case EWaModule.WA_NOTIFICATON:
				return waModule[EWaModule.WA_NOTIFICATON]?.isActive ? true : false;

			default:
				return false;
		}
	};

	const handlePreferenceChange = async (moduleName, updatedValue) => {
		try {
			setLoading(true);
			const updatedWaModule = { ...waModule, [moduleName]: { ...waModule[moduleName], ...updatedValue } };
			setWaModule(updatedWaModule);
			await updatePreferenceDetailAdmin({ companyId, profileType: 'waModules', profileValue: updatedWaModule });
			notification.success({
				message: 'Success',
				description: 'Preferences updated successfully',
				placement: 'topRight'
			});
		} catch (error) {
			errorHandler(error);
		} finally {
			revalidate();
			setLoading(false);
		}
	};

	return (
		<div className={styles.row2}>
			<Typography.Title level={4}>Notification Preferences</Typography.Title>
			<Typography.Paragraph type="warning">*Please contact admin or sales team to activate this feature</Typography.Paragraph>
			<Row className={styles.preferenceRow}>
				<Col span={12}>
					<Typography.Paragraph>Send Whatsapp message to customer when delivery attempt fails</Typography.Paragraph>
					<Typography.Paragraph type="secondary" className={styles.secondaryText}>
						A link of a form will be sent to customer. Using this link cusomter can request our delivery partner to reschedule
						delivery or update contact details(phone number, address).
					</Typography.Paragraph>
				</Col>
				<Col span={12}>
					<Switch
						checkedChildren="ON"
						disabled={!isAdmin}
						unCheckedChildren="OFF"
						checked={isSwitchChecked(EWaModule.NDR)}
						onChange={(updatedStatus) => handlePreferenceChange(EWaModule.NDR, { isActive: updatedStatus })}
					/>
				</Col>

				<Col span={12}>
					<Typography.Paragraph>Send whatsapp messages on order status updates </Typography.Paragraph>
					{waModule[EWaModule.WA_NOTIFICATON].isActive && (
						<>
							<Typography.Paragraph type="secondary" className={styles.secondaryText}>
								Order status update will be sent to customer on following status
							</Typography.Paragraph>

							<Checkbox.Group
								options={statusUpdateOptions}
								value={waModule[EWaModule.WA_NOTIFICATON].triggerStatus}
								onChange={(data) => handlePreferenceChange(EWaModule.WA_NOTIFICATON, { triggerStatus: data })}
								disabled={!isAdmin}
							/>
						</>
					)}
				</Col>
				<Col span={12}>
					<Switch
						checkedChildren="ON"
						disabled={!isAdmin}
						unCheckedChildren="OFF"
						checked={isSwitchChecked(EWaModule.WA_NOTIFICATON)}
						onChange={(updatedStatus) => handlePreferenceChange(EWaModule.WA_NOTIFICATON, { isActive: updatedStatus })}
					/>
				</Col>
			</Row>
		</div>
	);
};
