import { DownloadOutlined } from '@ant-design/icons';
import { Badge, Button, Col, notification, Select } from 'antd';
import { MAX_DOWNLOAD_LABEL_LIMIT, searchOptions } from 'app/AppSeller/Orders/constants';
import dayjs from 'dayjs';
import { downloadAsFile } from 'helper';
import { apiOms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { orderStatus } from 'shared/utils/orderStatus';
import { EVENT_NAMES } from 'utils/analytics';
import { ORDER_STATUS } from '../../../constants';
import { DebouncedCompactSearch } from '../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import amplitude from '../../../shared/utils/Amplitude';
import styles from './index.module.scss';
const { Option } = Select;

const calculateAllCount = (counts) => {
	let allCount = 0;
	const ALL_COUNT_FIELDS = [
		ORDER_STATUS.PLACED,
		ORDER_STATUS.READY_TO_SHIP,
		ORDER_STATUS.IN_TRANSIT,
		ORDER_STATUS.OUT_FOR_DELIVERY,
		ORDER_STATUS.DELIVERED,
		ORDER_STATUS.CANCELLED
	];

	ALL_COUNT_FIELDS.forEach((field) => (allCount += +counts[field] || 0));
	return allCount;
};

export const sidebarUtil = ({ count }) => ({
	sideBarMenu: [
		{
			key: '1',
			label: `All  (${count})`,
			icon: 'allSvgIcon'
		}
	]
});

const showOrderStatusFilters = (sidebarMenuItem) => (sidebarMenuItem === '5' ? false : true);

const showClusterStatusFilters = (sidebarMenuItem) => (sidebarMenuItem === '5' ? false : true);

export const topFilterUtil = ({
	onDownloadOrders,
	onDownloadOrderPickList,
	filter,
	setFilter,
	counts,
	pageConfig,
	setPageConfigDefault,
	sidebarMenuItem,
	selectedRowKeys,
	getAllOrders,
	clusterData,
	setIsLabelEmailModalVisible
}) => {
	const handleStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};

	const handleClusterChange = (cluster) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, cluster }));
	};

	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((v) => ({ ...v, startDate, endDate }));
	};

	const bulkDownload = async (type) => {
		amplitude.getInstance().logEvent(`clicked on ${type}`);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			if (type === 'label' && selectedRowKeys.length > MAX_DOWNLOAD_LABEL_LIMIT) {
				setIsLabelEmailModalVisible(true);
			} else {
				const res = await apiOms.post(`/download/${type}`, { orderId: selectedRowKeys }, { responseType: 'blob' });
				downloadAsFile({ data: res.data, fileName: `${type}s`, fileType: 'pdf' });
			}
		} catch (error) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Unable to find ${type}`;
			}

			errorHandler(error);
		}
	};

	const bulkReadyToShip = async (ids) => {
		amplitude.getInstance().logEvent(EVENT_NAMES.MARK_READY_SHIP);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');

			const {
				data: { status, message }
			} = await apiOms.get(`/bulkreadytoship/?orderids=${selectedRowKeys.join(',')}`);

			if (!status) {
				notification.error({
					message: 'Failed',
					description: message,
					placement: 'topRight'
				});
				return;
			}

			getAllOrders(pageConfig, filter);
			notification.success({
				message: 'Success',
				description: message,
				placement: 'topRight'
			});
		} catch (error) {
			if (error?.response?.data?.type === 'application/json') {
				error.message = `Something went wrong!`;
			}

			errorHandler(error);
		}
	};

	const onDownloadShippingManifest = async (val) => {
		amplitude.getInstance().logEvent(`Clicked on ${val}`);
		try {
			if (!selectedRowKeys.length) throw new Error('Select atleast 1 order!');
			const { data } = await apiOms.get(`/download/shipping-manifest?orderIds=${selectedRowKeys.join(',')}`, {
				responseType: 'blob'
			});
			// create an object URL from the Blob
			var URL = window.URL || window.webkitURL;
			var downloadUrl = URL.createObjectURL(data);
			window.open(downloadUrl, '_blank');
		} catch (error) {
			errorHandler(error);
			URL.revokeObjectURL(downloadUrl);
		}
	};

	return {
		row1: [
			{
				label: 'FILTER BY DATE',
				onChange: (dates, dateStrings) => setDate(...dateStrings),
				type: 'range',
				allowClear: false,
				defaultValue: [dayjs(filter.startDate), dayjs(filter.endDate)],
				value: [dayjs(filter.startDate), dayjs(filter.endDate)],
				ranges: {
					Today: [dayjs(), dayjs()],
					'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
					'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
					'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
					'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
				}
			}
		],
		row1children: [
			<Col>
				<div className={styles.searchContainer}>
					<DebouncedCompactSearch
						defaultSearchOptionType={filter.searchOptionType}
						searchOptions={searchOptions}
						debounceTime={700}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
						selectStyle={{ minWidth: '150px' }}
						inputStyle={{ width: '150px' }}
					>
						<label>Search By:</label>
					</DebouncedCompactSearch>
				</div>
			</Col>,
			// For Status
			showOrderStatusFilters(sidebarMenuItem) && (
				<div className={styles.orderStatusFilter}>
					<label>Status:</label>
					<Select
						mode="multiple"
						placeholder="Select status"
						maxTagCount="responsive"
						style={{ width: '100%' }}
						allowClear
						value={filter.status}
						onChange={handleStatusChange}
					>
						{Object.values(ORDER_STATUS).map((orderStatusVar) => {
							const count = counts[orderStatusVar] || 0;
							return (
								<Option key={orderStatusVar} disabled={!count}>
									{orderStatus[orderStatusVar]} ({count})
								</Option>
							);
						})}
					</Select>
				</div>
			),
			// For Cluster
			showClusterStatusFilters(sidebarMenuItem) && (
				<div className={styles.orderStatusFilter}>
					<label>Clusters:</label>
					<Select
						placeholder="Select Cluster"
						style={{ width: '100%' }}
						allowClear
						value={filter.cluster}
						onChange={handleClusterChange}
					>
						{clusterData.map((clusterItem) => {
							return (
								<Option key={clusterItem.clusterId}>
									{clusterItem.clusterName} ({clusterItem.clusterPincode})
								</Option>
							);
						})}
					</Select>
				</div>
			)
		],
		row2: [
			<Button type="primary" icon={<DownloadOutlined />} size="large" onClick={onDownloadOrders}>
				Download
			</Button>
		],
		row4: [
			{
				key: '1',
				label: 'Print Label',
				onClick: () => bulkDownload('label'),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '2',
				label: 'Print Invoice',
				onClick: () => bulkDownload('invoice'),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '3',
				label: 'Mark Ready To Ship',
				onClick: () => bulkReadyToShip(selectedRowKeys),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '4',
				label: 'Download PickList',
				onClick: () => onDownloadOrderPickList('Download PickList'),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '5',
				label: 'Download Shipping Manifest',
				onClick: () => onDownloadShippingManifest('Download PickList'),
				isActive: selectedRowKeys.length > 0
			},
			{
				key: '6',
				isNotButton: true,
				render: () =>
					selectedRowKeys.length !== 0 && (
						<Badge size="default" count={`${selectedRowKeys.length} selected`} style={{ backgroundColor: '#6c757d' }} />
					)
			}
		]
	};
};
