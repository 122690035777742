import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useColumns } from './missedColumns';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { getCompanyMissedOrders, syncMissedOrderForExternalStore } from 'shared/api/orders';
import { Col } from 'antd';
import { ErrorBoundary } from '@sentry/react';
import { Fallback } from '../ErrorBoundaryFallback';
import errorHandler from 'shared/utils/errorHandler';
import { displayNotification } from 'shared/utils/notification';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { getMissedFilterFromSessionStorage, topFilterUtil } from './utils';
import { defaultPageConfig } from '../../../constants';

export const orderRoute = '/orders';

export function MissedOrders() {
	const isMounted = useRef(false);
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [orderData, setOrderData] = useState([]);
	const [dictionary, setDictionary] = useState(null);
	const [currentCount, setCurrentCount] = useState(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState(getMissedFilterFromSessionStorage);
	const [reloadList, setReloadList] = useState(0);
	const [search, setSearch] = useState('');

	useEffect(() => {
		// setting expiry time in 6h form now
		const rangeMissedFilter = {
			expiresAt: new Date(new Date().getTime() + 21600000),
			filter: filter
		};

		sessionStorage.setItem('rangeMissedFilter', JSON.stringify(rangeMissedFilter));
	}, [filter]);

	/**
	 * Sync function
	 */
	const syncMissedOrder = async (order) => {
		try {
			setLoading(true);
			const { data } = await syncMissedOrderForExternalStore(order.externalOrderId, order.shopType, order.id);
			if (data && data.isSynced) {
				displayNotification('Sync Order', 'SuccessFully Synced', 'success');
			}

			if (data && !data.isSynced) {
				displayNotification('Sync Order', 'Could not synced');
			}

			getAllOrders();
		} catch (err) {
			errorHandler(err);
		} finally {
			setReloadList((prev) => prev + 1);
			setLoading(false);
		}
	};

	const columns = useColumns({
		dictionary,
		syncMissedOrder,
		sortField: pageConfig.sortField,
		sortOrder: pageConfig.sortOrder
	});

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const getAllOrders = useCallback(async () => {
		try {
			const { data } = await getCompanyMissedOrders(pageConfig, filter);
			if (!isMounted.current) return;
			setOrderData(data.orders);
			setCurrentCount(data.totalCount || 0);
			setDictionary(data.dictionary);
			if (data.message) {
				displayNotification('Error', data.message);
			}
		} catch (err) {
			if (!isMounted.current) return;
			setOrderData([]);
		}
		isMounted.current && setLoading(false);
	}, [pageConfig, filter]);

	useEffect(() => {
		setLoading(true);
		getAllOrders();
	}, [getAllOrders, reloadList]);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				search,
				setSearch,
				pageConfig,
				setPageConfigDefault,
				selectedRowKeys,
				setPageConfig,
				getAllOrders,
				isMissedOrder: true
			}),
		[filter, pageConfig, selectedRowKeys, search, getAllOrders]
	);

	function handleTableChange(_pagination, _filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'id',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	return (
		<ErrorBoundary fallback={<Fallback fallBackMessage="Sorry, Failed to load data" />}>
			<Col sm={24} lg={20} className="main">
				<div className="pt-3 bg-white">
					<TopFilterComponent {...topFilterConfig} />
				</div>
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							columns={columns}
							data={orderData}
							showSorterTooltip={true}
							{...pageConfig}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							total={currentCount}
							onChange={handleTableChange}
							loading={loading}
							selectedRowKeys={selectedRowKeys}
							setSelectedRowKeys={setSelectedRowKeys}
						/>
					</div>
				</div>
			</Col>
		</ErrorBoundary>
	);
}
