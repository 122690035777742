import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { message } from 'antd';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { moveArrayElement } from 'helper';
import errorHandler from 'shared/utils/errorHandler';
import { apiUms } from 'shared/api';
import { DeliveryPartnerCard } from './components/DeliveryPartnerCard';
import { deliveryPartnerFields, deliveryPartnersWithBody } from './constants';

export const ThirdPartyLogisticPreferences = () => {
	const [deliveryPartners, setDeliveryPartners] = useState([]);
	const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const onDragEnd = ({ source, destination }) => {
		if (!destination) return;

		const updatedArray = [...deliveryPartners];
		moveArrayElement(updatedArray, source.index, destination.index);
		setDeliveryPartners(updatedArray);
	};

	const handleUpdateField = (fieldName, fieldvalue, updateDeliveryPartnerName) => {
		setDeliveryPartners((deliveryPartners) => {
			const updatedDeliveryPartners = deliveryPartners.map((deliveryPartner) => {
				if (deliveryPartner.name === updateDeliveryPartnerName) {
					deliveryPartner = {
						...deliveryPartner,
						[fieldName]: fieldvalue
					};
				}

				return deliveryPartner;
			});

			return updatedDeliveryPartners;
		});
	};

	const fetchDeliveryPartners = async () => {
		try {
			const {
				data: { responseBody }
			} = await apiUms.get('/ums/profile/deliveryPartners');
			const { deliveryPartners } = responseBody;

			setDeliveryPartners(deliveryPartners);
		} catch (error) {
			errorHandler(error);
		}
		setIsLoading(false);
	};

	const validateDeliveryPartnerBody = (deliveryPartner) => {
		for (const deliveryPartnerField of deliveryPartnerFields[deliveryPartner.name]) {
			if (!deliveryPartner[deliveryPartnerField.name]) {
				throw new Error(`Please input ${deliveryPartnerField.label} in ${deliveryPartner.name}`);
			}
		}
	};

	const validateDeliveryPartners = (deliveryPartners) => {
		for (const deliveryPartner of deliveryPartners) {
			if (deliveryPartner.status && deliveryPartnersWithBody.includes(deliveryPartner.name.toLowerCase())) {
				validateDeliveryPartnerBody(deliveryPartner);
			}
		}
	};

	const handleSaveChanges = async () => {
		try {
			setIsSaveButtonLoading(true);
			validateDeliveryPartners(deliveryPartners);

			deliveryPartners.forEach((deliveryPartner, index) => (deliveryPartner.priority = index + 1));
			await apiUms.put('/ums/profile/deliveryPartners', { partners: deliveryPartners });
			message.success('Logistic preferences updated successfully');
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsSaveButtonLoading(false);
		}
	};

	useEffect(() => {
		fetchDeliveryPartners();
	}, []);

	useEffect(() => {
		if (!isLoading) handleSaveChanges();
	}, [deliveryPartners]);

	return (
		<div className={styles.thirdPartyLogisticContainer}>
			<div>
				<h4>Reorder cards to prioritise 3PL Partners </h4>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided) => (
							<ul {...provided.droppableProps} ref={provided.innerRef} className={styles.preferenceCardList}>
								{deliveryPartners.map((deliveryPartnerDetails, index) => {
									return (
										<Draggable
											key={deliveryPartnerDetails.name}
											draggableId={deliveryPartnerDetails.name}
											index={index}
										>
											{(provided) => (
												<DeliveryPartnerCard
													provided={provided}
													deliveryPartnerDetails={{ ...deliveryPartnerDetails, priority: index + 1 }}
													handleUpdateField={handleUpdateField}
													isDisabled={isSaveButtonLoading}
												/>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</div>
	);
};
