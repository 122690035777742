import { Input, Form, Row, Col, Select } from 'antd';
import { indianStates } from 'constants/indianStates';
import { isValidNumber, isValidPhoneNumber } from '../../../../../../helper';
import styles from './index.module.scss';

export const ShippingAddress = ({ customStyle }) => {
	return (
		<div className={`${styles.shippingAddress} ${customStyle}`}>
			<h4 className={styles.heading}>Shipping Details</h4>

			<h5 className={styles.subHeading}>Contact Person</h5>

			<Row gutter={24}>
				<Col span={8}>
					<Form.Item label="Name" name={['address', 'name']} rules={[{ required: true, message: 'Please input Name' }]}>
						<Input placeholder="John Wick" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Phone (10 digits)"
						name={['address', 'phone']}
						rules={[
							{ required: true, message: 'Please input Phone Number' },
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();

									const { isValid, reason } = isValidPhoneNumber(value);
									if (isValid) return Promise.resolve();

									return Promise.reject(new Error(reason));
								}
							})
						]}
					>
						<Input placeholder="9900097000" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Email" name={['address', 'email']} rules={[{ required: true, message: 'Please input Email' }]}>
						<Input placeholder="john.wick@mail.com" />
					</Form.Item>
				</Col>
			</Row>

			<h5 className={styles.subHeading}>Address</h5>

			<Row gutter={24}>
				<Col span={8}>
					<Form.Item
						label="Address Line 1"
						name={['address', 'address1']}
						rules={[{ required: true, message: 'Please input Address Line 1' }]}
					>
						<Input placeholder="12/33 Abc Street" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Address Line 2"
						name={['address', 'address2']}
						rules={[{ required: true, message: 'Please input Address Line 2' }]}
					>
						<Input placeholder="Karol Bagh" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={8}>
					<Form.Item label="City" name={['address', 'city']} rules={[{ required: true, message: 'Please input City' }]}>
						<Input placeholder="Delhi" />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="State" name={['address', 'state']} rules={[{ required: true, message: 'Please input State' }]}>
						<Select>
							{indianStates.map(({ label, value }) => {
								return <Select.Option value={value}>{label}</Select.Option>;
							})}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Pincode"
						name={['address', 'zip']}
						rules={[
							{ required: true, message: 'Please input Pincode' },
							{ len: 6, message: 'Pincode must contain 6 digits' },
							() => ({
								validator(_, value) {
									if (!value) return Promise.resolve();

									const { isValid, reason } = isValidNumber(value);

									if (isValid) return Promise.resolve();

									return Promise.reject(new Error(reason));
								}
							})
						]}
					>
						<Input placeholder="600011" />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};
