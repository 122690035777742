import { Tag } from 'antd';

export const columns = () => [
	{
		title: 'Customer Name',
		dataIndex: 'customerName',
		render: (text) => <div className="text-center">{text}</div>,
		width: 150
	},
	{
		title: 'Customer Email',
		dataIndex: 'customerEmail',
		render: (text) => <div className="text-center">{text === '' ? '-' : text}</div>,
		width: 150
	},
	{
		title: 'Customer Phone',
		dataIndex: 'customerPhone',
		render: (text) => <div className="text-center">{text === '' ? '-' : text}</div>,
		width: 150
	},
	{
		title: 'Recency',
		dataIndex: 'recency',
		render: (text) => <div className="text-center">{text}</div>,
		width: 150
	},
	{
		title: 'Frequency',
		dataIndex: 'frequency',
		render: (text) => <div className="text-center">{text}</div>,
		width: 150
	},
	{
		title: 'Monetary',
		dataIndex: 'monetary',
		render: (text) => <div className="text-center">{text}</div>,
		width: 150
	},
	{
		title: 'Badge',
		dataIndex: 'rfmBadge',
		render: (text) => <div className="text-center">{getTag(text)}</div>,
		width: 150
	}
];

const getTag = (text) => {
	let res;
	switch (text) {
		case 'Gold':
			res = <Tag color="#ffd700">Gold</Tag>;
			break;
		case 'Silver':
			res = <Tag color="#BBC2CC">Silver</Tag>;
			break;
		case 'Bronze':
			res = <Tag color="#cd7f32">Bronze</Tag>;
			break;
		default:
			break;
	}
	return res;
};
