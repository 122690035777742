import { apiOms } from '../../../../shared/api';

export const populateProductItems = (items, catalogData) => {
	const updateProductObject = ({ id, gst, quantity }) => {
		const catalog = catalogData.find((catalog) => catalog.id === id);

		const updatedProductObject = {
			name: catalog.productTitle,
			variantId: catalog.variantId,
			productId: catalog.productId,
			quantity,
			weight: catalog.weight,
			sku: catalog.sku,
			price: catalog.price,
			gstRate: gst,
			discount: 0,
			catalogueId: id
		};

		return updatedProductObject;
	};

	const populatedProductItems = items.map(updateProductObject);
	return populatedProductItems;
};

export const fetchAddresses = (address) => {
	const { name, phone, email, address1, address2, city, state, zip } = address;

	const shippingAddress = {
		firstName: name.split(' ')[0] || '',
		address1,
		phone,
		city,
		zip,
		province: state,
		country: 'India',
		lastName: name.split(' ')[1] || '',
		address2,
		company: '',
		latitude: 0,
		longitude: 0,
		name,
		countryCode: 'IN',
		provinceCode: ''
	};

	const billingAddress = {
		...shippingAddress
	};

	const customer = {
		firstName: name.split(' ')[0] || '',
		lastName: name.split(' ')[1] || '',
		phone,
		address: {
			firstName: name.split(' ')[0] || '',
			lastName: name.split(' ')[1] || '',
			phone,
			address1,
			address2,
			city,
			state,
			country: 'India',
			pincode: zip,
			company: ''
		}
	};

	return { customer, shippingAddress, billingAddress };
};

export const createOrder = (formData) => {
	return apiOms.post('/order', formData);
};
