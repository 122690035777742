import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row } from 'antd';
import { DriverInfoSlider } from 'shared/components/DriverInfoSlider';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { userColumns } from '../utils';
import { defaultPageConfig } from '../../../../constants';
import { TopFilterComponent } from 'shared/components/AppLayout/TopFilterComponent';
import { defaultFilter, topFilterUtil } from './utils';
import { getDrivers } from 'shared/api/lightning';
import errorHandler from 'shared/utils/errorHandler';

export function Drivers({ clusters }) {
	const [openDriverInfo, setOpenDriverInfo] = useState(false);
	const columns = userColumns({ isDriver: true, onClick: setOpenDriverInfo });
	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const [filter, setFilter] = useState(defaultFilter);
	const [drivers, setDrivers] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const topFilterConfig = useMemo(
		() =>
			topFilterUtil({
				filter,
				setFilter,
				clusters,
				setPageConfigDefault
			}),
		[filter, pageConfig, clusters]
	);

	function setPageConfigDefault() {
		setPageConfig((v) => ({ ...v, ...defaultPageConfig }));
	}

	async function getAllDrivers(pageConfiguration, filters) {
		try {
			setLoading(true);
			const { data } = await getDrivers(pageConfiguration, filters);
			if (data.status) {
				setDrivers(data?.driver);
				setCount(data.count);
			} else {
				throw new Error('Could not get drivers');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	function handleTableChange(pagination, filters, sorter) {
		setPageConfig((v) => ({
			...v,
			sortField: sorter.order ? sorter.field : 'createdAt',
			sortOrder: sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}));
	}

	useEffect(() => {
		getAllDrivers(pageConfig, filter);
	}, [pageConfig, filter]);

	return (
		<Row className="h-100 p-0">
			<Col span={24} className="main">
				<TopFilterComponent {...topFilterConfig} />
				<div className="innerDiv">
					<div className="div w-100 h-100">
						<CustomTablePagination
							data={drivers}
							columns={columns}
							shouldRowSelection={false}
							total={count}
							showSorterTooltip={true}
							{...pageConfig}
							onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
							onChange={handleTableChange}
							loading={loading}
						/>
					</div>
				</div>
			</Col>
			{openDriverInfo && <DriverInfoSlider driverId={openDriverInfo} onClose={() => setOpenDriverInfo(null)} />}
		</Row>
	);
}
