import { apiUms, apiWms } from './index';
import { cleanObject } from 'helper';

function getCompanies({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter) {
	return apiUms.post('/ums/profile/companies', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
}

function getWherehouses({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter) {
	return apiUms.post('/ums/profile/wherehouses', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
}

function getAllWherehouses({ current = 1, pageSize = 10, sortField = 'createdAt', sortOrder = 'DESC' }, filter) {
	return apiWms.post('/warehouse/listAllWarehouses', {
		where: cleanObject({ ...filter }, ['', null, undefined]),
		pagination: {
			records: pageSize,
			pageNumber: current
		},
		sortBy: {
			order: sortField,
			by: sortOrder
		}
	});
}
export { getCompanies, getWherehouses, getAllWherehouses };
