import { Form, Input, InputNumber, Select } from 'antd';
import React from 'react';

const PackageDetails = () => {
	return (
		<>
			<Form.Item
				label="Package Name"
				name="name"
				required
				rules={[
					{
						required: true,
						message: 'The name is required.'
					}
				]}
			>
				<Input placeholder="Enter Package Name" />
			</Form.Item>
			<Form.Item label="Unique Package Code" name="code" required>
				<Input placeholder="Enter Package Name" />
			</Form.Item>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Form.Item
					label="Length (in cm)"
					name="packageLength"
					required
					rules={[
						{
							type: 'integer',
							min: 0,
							message: 'Please input a valid length.'
						},
						{
							required: true,
							message: 'Length is required.'
						}
					]}
				>
					<InputNumber placeholder="Enter Package Length" />
				</Form.Item>
				&#10005;
				<Form.Item
					label="Width (in cm)"
					name="packageWidth"
					required
					rules={[
						{
							type: 'integer',
							min: 0,
							message: 'Please input a valid width.'
						},
						{
							required: true,
							message: 'Width is required.'
						}
					]}
				>
					<InputNumber placeholder="Enter Package Width" />
				</Form.Item>
				&#10005;
				<Form.Item
					label="Height (in cm)"
					name="packageHeight"
					required
					rules={[
						{
							type: 'integer',
							min: 0,
							message: 'Please input a valid height.'
						},
						{
							required: true,
							message: 'Height is required.'
						}
					]}
				>
					<InputNumber placeholder="Enter Package Height" />
				</Form.Item>
			</div>
			<Form.Item
				label="Weight"
				name="packageWeight"
				required
				rules={[
					{
						type: 'integer',
						min: 0,
						message: 'Please input a valid weight.'
					},
					{
						required: true,
						message: 'Weight is required.'
					}
				]}
			>
				<InputNumber placeholder="Enter Package Weight (in gm)" style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item label="Default" name="default" initialValue="0">
				<Select>
					<Select.Option value="1">Yes</Select.Option>
					<Select.Option value="0">No</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item label="Active" name="active" initialValue="1">
				<Select defaultValue="1">
					<Select.Option value="1">Yes</Select.Option>
					<Select.Option value="0">No</Select.Option>
				</Select>
			</Form.Item>
		</>
	);
};

export default PackageDetails;
