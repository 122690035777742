import React, { useContext, useState } from 'react';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import styles from './index.module.scss';
import headerLogoImg from 'shared/images/header_logo.svg';
import { AuthContext } from 'shared/contexts/Auth';
import { Loading } from 'shared/components/Loading';
import { envs } from 'shared/utils/env';
import { getOptionsForDropdown } from 'app/AppWms/utils';
import { Select, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { EVENT_NAMES } from 'utils/analytics';
import amplitude from '../../../utils/Amplitude';
import { setSidebarMenuItemFromSessionStorageToInitial } from '../../../../app/AppSeller/Orders/utils';
const { appType } = envs;
const { Option } = Select;

export function Header(props) {
	const { currentRoute = '', topBar = [] } = props;
	const { authState, authActions } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const options = getOptionsForDropdown() || [];
	const [hamburg, setHamburg] = useState(false);
	async function logout() {
		amplitude.getInstance().logEvent(EVENT_NAMES.LOGOUT_BUTTON);
		try {
			setLoading(true);
			await setSidebarMenuItemFromSessionStorageToInitial();
			await authActions.logout();
		} catch (err) {
			notification.error({
				message: 'Error!',
				description: err,
				placement: 'topRight'
			});
		} finally {
			setLoading(false);
		}
	}

	function handleChange(value) {
		authActions.selectedCompanyId(value);
	}

	function handleHamburg() {
		setHamburg(!hamburg);
	}
	function handleAmplitude(route) {
		amplitude.getInstance().logEvent(`Clicked on route button ${route}`);
	}

	return (
		<>
			{loading && <Loading loading={true} />}
			<Drawer
				bodyStyle={{ padding: 0 }}
				title="Wherehouse"
				placement={'right'}
				closable={true}
				onClose={() => setHamburg(false)}
				visible={hamburg}
				width="65%"
			>
				<nav className={`navbar navbar-expand-lg navbar-dark ${styles.navbarDrawer}`}>
					<Row>
						<Col id={styles.sidebarResponsivness}>
							<div className="navbar-nav">
								{topBar.map(({ route, key, label }) => {
									return (
										<div key={key} className={`nav-item ${isEqual(route, currentRoute) ? 'active' : ''}`}>
											<Link to={route} className="nav-link">
												{label}
											</Link>
										</div>
									);
								})}
							</div>
							<div className={styles.logoutButtonWithResponsivness}>
								<Link onClick={logout}>Logout</Link>
							</div>
						</Col>
					</Row>
				</nav>
			</Drawer>
			<nav className={`navbar navbar-expand-lg navbar-dark ${styles.navbar}`}>
				<Row className="h-100 p-0 w-100">
					<Col span={4}>
						<Link className="navbar-brand" to="/">
							<img src={headerLogoImg} alt="logo" />
						</Link>
					</Col>
					<MenuOutlined onClick={handleHamburg} style={{ color: 'white', fontSize: '25px' }} id={styles.hamburgForNavbar} />
					<Col span={20} className={styles.navbarForLargeScreen}>
						<ul className="navbar-nav">
							{topBar.map(({ route, key, label }) => {
								return (
									<li key={key} className={`nav-item ${isEqual(route, currentRoute) ? 'active' : ''}`}>
										<Link to={route} onClick={() => handleAmplitude(route)} className="nav-link">
											{label}
										</Link>
									</li>
								);
							})}
							<li key="signout" className="nav-item">
								<div className="end_elements">
									{appType === 'AppWms' ? (
										<Select
											mode="multiple"
											allowClear
											maxTagCount="responsive"
											style={{ width: '15rem' }}
											placeholder="Please select company"
											onChange={handleChange}
											value={authState.selectedCompanyIds}
										>
											{/* {options} */}
											{authState.companyData.map((data) => (
												<Option key={data.companyId}>{data.name}</Option>
											))}
										</Select>
									) : null}

									<Link onClick={logout} className="nav-link">
										Logout
									</Link>
								</div>
							</li>
						</ul>
					</Col>
				</Row>
			</nav>
		</>
	);
}
