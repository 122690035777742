import { Col, Modal, Row, Statistic, Table, Timeline } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { journeyStatusColor, REVERSE_JOURNEY_STATUS_MAPPING } from '../../../app/AppAdmin/Lightning/constants';
import { getJourney } from '../../api/lightning';
import { sortByDate } from '../../utils/date';
import errorHandler from '../../utils/errorHandler';
import { Loading } from '../Loading';
import { HMSTrail } from '../OrderInfoSlider/components/OrderTrail/HMSOrderTrail';
import { columns } from './columns';
import { getJourneyStatsMapper } from './utils';

export default function JourneyInfo({ journeyID, showJourneyInfo }) {
	const [loading, setLoading] = useState(true);
	const [journey, setJourney] = useState({});
	const journeyStats = useMemo(() => getJourneyStatsMapper(journey), [journey]);

	function handleClose() {
		showJourneyInfo(false);
	}

	async function getSingleJourney({ journeyID }) {
		try {
			setLoading(true);
			const { data } = await getJourney({ journeyID: journeyID });

			if (data.status) {
				setJourney(data.data);
			} else {
				throw new Error(data.message || 'Could not get trip');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		getSingleJourney({ journeyID });
	}, [journeyID]);

	return loading ? (
		<Loading loading={true} />
	) : (
		<Modal
			style={{
				padding: 0,
				margin: 'auto'
			}}
			bodyStyle={{
				margin: '0',
				height: '80vh',
				overflowY: 'scroll'
			}}
			width="100%"
			visible={true}
			maskClosable={true}
			keyboard={true}
			footer={null}
			getContainer={false}
			onCancel={handleClose}
			title={`Journey: ${REVERSE_JOURNEY_STATUS_MAPPING[journey?.status]}`}
		>
			<Col span={24} style={{ margin: '0 2rem' }}>
				<Row justify="space-between">
					<div>Driver: {journey?.driver?.name}</div>
					<div>Phone: {journey?.driver?.phone}</div>
					<div>
						Status:{' '}
						<span
							style={{ color: `${journeyStatusColor[REVERSE_JOURNEY_STATUS_MAPPING[journey?.status]]}`, fontWeight: 'bold' }}
						>
							{REVERSE_JOURNEY_STATUS_MAPPING[journey?.status]?.replace('_', ' ')}
						</span>
					</div>
					<div>
						Date: <span>{new Date(journey.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', hour12: true })}</span>
					</div>
				</Row>
				<hr />
				<Row justify="space-between">
					{journeyStats.map(({ title, value, valueStyle, prefix }) => (
						<Statistic title={title} value={value} valueStyle={valueStyle} prefix={prefix} />
					))}
				</Row>
				<Row style={{ margin: '3rem 0' }}>
					<Col span={16}>
						<Table pagination={false} columns={columns} dataSource={journey?.journeyOrders} />
					</Col>
					<Col span={8} style={{ padding: '0 2rem' }}>
						{sortByDate({ data: journey?.trails, fieldName: 'createdAt' }).map((trailDetails) => (
							<Timeline>
								<HMSTrail trailDetails={trailDetails} />
							</Timeline>
						))}
					</Col>
				</Row>
			</Col>
		</Modal>
	);
}
