import { Button, Col, Row } from 'antd';
import { defaultPageConfig } from '../../../constants';
import { useState, useEffect, useMemo, useContext } from 'react';
import { apiOms } from 'shared/api';
import { Sidebar } from 'shared/components/AppLayout/Sidebar';
import { CustomTablePagination } from 'shared/components/CustomTablePagination';
import { AuthContext } from 'shared/contexts/Auth';
import { columns } from './columns';
import { badgeData, downloadCustomersCSV, sidebarUtil } from './utils';
import errorHandler from 'shared/utils/errorHandler';
import BadgeCard from './components/BadgeCard';
import { SideDrawer } from 'shared/components/SideDrawer/SideDrawer';
import { Insights } from '../Insights/Insights';
import { QueryClient, QueryClientProvider } from 'react-query';
import reactDom from 'react-dom';

export const overviewRoute = '/insights';
const queryClient = new QueryClient();

const Overview = () => {
	const [sidebarMenuItem, setSidebarMenuItem] = useState('1');
	const [data, setData] = useState();
	const [filteredData] = useState();
	const [selectedRowKeys, setSelectedRowKeys] = useState();
	const [total, setTotal] = useState();
	const [loading, setLoading] = useState(false);
	const [count, setCount] = useState();
	const [filter, setFilter] = useState();
	const { authState } = useContext(AuthContext);

	const [pageConfig, setPageConfig] = useState(defaultPageConfig);
	const sidebarConfig = useMemo(() => sidebarUtil(), []);
	const columnsConfig = useMemo(() => columns(), []);

	const getRFMData = (filter) => {
		if (!filter.where.rfmBadge) delete filter['where'];

		apiOms
			.post(`/rfm`, filter)
			.then(({ data }) => {
				reactDom.unstable_batchedUpdates(() => {
					setTotal(data.total);
					setData(data.data);
					setCount(data.count);
				});
			})
			.catch((err) => {
				reactDom.unstable_batchedUpdates(() => {
					errorHandler(err);
					setData([]);
					setLoading(false);
				});
			})
			.finally(() => {
				reactDom.unstable_batchedUpdates(() => {
					setLoading(false);
				});
			});
	};

	useEffect(() => {
		setLoading(true);
		getRFMData({
			where: { rfmBadge: filter },
			pagination: {
				records: pageConfig.pageSize,
				pageNumber: pageConfig.current
			}
		});
	}, [pageConfig, filter]);

	async function onDownloadCustomers() {
		try {
			reactDom.unstable_batchedUpdates(() => {
				setLoading(true);
			});
			await downloadCustomersCSV({
				companyId: authState.profile.id,
				rfmBadge: filter
			});
		} catch (error) {
			reactDom.unstable_batchedUpdates(() => {
				errorHandler(error);
				setLoading(false);
			});
		} finally {
			reactDom.unstable_batchedUpdates(() => {
				setLoading(false);
			});
		}
	}

	return (
		<Row className="h-100 p-0">
			<SideDrawer placement="left">
				{
					<Sidebar
						sideBarMenu={sidebarConfig.sideBarMenu}
						selectedMenu={sidebarMenuItem}
						onMenuItemSelect={setSidebarMenuItem}
						bottomButtons={
							<>
								<Button type="primary" size="large" onClick={onDownloadCustomers}>
									Download Customers
								</Button>
							</>
						}
					/>
				}
			</SideDrawer>
			{sidebarMenuItem === '1' ? (
				<QueryClientProvider client={queryClient}>
					<Insights />
				</QueryClientProvider>
			) : (
				<Col sm={24} lg={20} className="main">
					<div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '2rem' }}>
						<BadgeCard badgeData={badgeData(count, setFilter, filter)} />
					</div>
					<div className="topFilter">{/* <TopFilterComponent {...topFilterConfig} /> */}</div>
					<div className="innerDiv">
						<div className="div w-100 h-100">
							<CustomTablePagination
								columns={columnsConfig}
								data={filteredData ? filteredData : data}
								{...pageConfig}
								onChangePage={(current, pageSize) => setPageConfig((v) => ({ ...v, current, pageSize }))}
								total={total}
								loading={loading}
								selectedRowKeys={selectedRowKeys}
								setSelectedRowKeys={setSelectedRowKeys}
							/>
						</div>
					</div>
				</Col>
			)}
		</Row>
	);
};

export default Overview;
