import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import readXlsxFile from 'read-excel-file';
import { apiCms, apiHms, apiOms, apiWms } from 'shared/api';
import errorHandler from 'shared/utils/errorHandler';
import { SHOP_TYPE } from '../../../constants';
import { Loading } from '../Loading';
import { BulkTable } from './components/BulkTable';
import { ErrorComponent } from './components/ErrorComponentPreview';
import { SuccessComponent } from './components/SuccessComponentPreview';
import { columnAlphabetToFieldNameMap, MAX_ROWS_ALLOWED, MAX_ROWS_EXCEED_ERROR_MSG } from './constants';
import styles from './index.module.scss';
import { sanitizeData, sanitizeExcelData, validateData } from './utils';
const { confirm } = Modal;

export const BulkPreview = (props) => {
	const history = useHistory();
	const [tableRows, setTableRows] = useState(null);
	const [tableColumns, setTableColumns] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isExcelPreviewLoading, setIsExcelPreviewLoading] = useState(false);
	const [showSuccessComponent, setShowSuccessComponent] = useState(false);
	const [showErrorComponent, setShowErrorComponent] = useState(false);
	const [errorsArray, setShowErrorsArray] = useState([]);
	const [successArray, setShowSuccessArray] = useState([]);
	const routeName = history.location.pathname.split('/')[2] === 'catalogues' ? '/catalogs' : '/';
	/**
	 * Read Excel File and set ColumnData and rwsData for table
	 */
	const readFile = async () => {
		try {
			if (!props.location.state) {
				return history.push('/');
			}

			const file = props.location.state.file;
			setIsExcelPreviewLoading(true);
			const excelData = await readXlsxFile(file);
			setIsExcelPreviewLoading(false);
			let { tableRows, tableColumns } = sanitizeExcelData(excelData, history.location.pathname.split('/')[2]);

			if (tableRows.length > MAX_ROWS_ALLOWED) {
				throw new Error(MAX_ROWS_EXCEED_ERROR_MSG);
			}

			setTableRows(tableRows);
			setTableColumns(tableColumns);
		} catch (error) {
			errorHandler(error);
			history.push('/');
		} finally {
			setIsExcelPreviewLoading(false);
		}
	};

	useEffect(() => {
		readFile();
	}, []);

	/**
	 * On Submit of excel data
	 */
	const handleOnSubmitClick = async () => {
		try {
			setIsLoading(true);
			const { validatedData, doesErrorExists } = validateData(tableRows, history.location.pathname.split('/')[2]);

			if (doesErrorExists) {
				setTableRows(validatedData);
				throw new Error('Please clear all errors');
			}

			const sanitizedData = sanitizeData(validatedData, history.location.pathname.split('/')[2]);

			let responseData;
			if (history.location.pathname.split('/')[2] === 'catalogues') {
				const {
					data: { response }
				} = await apiCms.post('/catalogue/bulk', { catalogues: sanitizedData, shopType: SHOP_TYPE.MANUAL }, { timeout: 600000 });
				responseData = response;
			} else if (history.location.pathname.split('/')[2] === 'orders') {
				const {
					data: { response }
				} = await apiOms.post('/order/bulk', { orders: sanitizedData }, { timeout: 600000 });
				responseData = response;
			} else if (history.location.pathname.split('/')[2] === 'inventory') {
				const {
					data: { response }
				} = await apiWms.post(
					'/inventory/bulk',
					{ inventory: sanitizedData, companyIds: props.location.state.companyId },
					{ timeout: 600000 }
				);
				responseData = response;
			} else if (history.location.pathname.split('/')[2] === 'wsin-upload') {
				const {
					data: { response }
				} = await apiWms.post(
					'/wsin/bulk/generate',
					{
						names: sanitizedData,
						companyId: props.location.state.companyId
					},
					{ headers: { 'x-source': 'ingress' } }
				);
				responseData = response;
			} else if (history.location.pathname.split('/')[2] === 'wsin-map') {
				const {
					data: { response }
				} = await apiCms.post(
					'/catalogue/bulk/map-wsin',
					{
						companyId: props.location.state.companyId,
						data: sanitizedData
					},
					{ headers: { 'x-source': 'ingress' } }
				);
				responseData = response;
			} else if (history.location.pathname.split('/')[2] === 'lightning-orders') {
				const {
					data: { response }
				} = await apiHms.post('/lightning/orders/bulk', { orders: sanitizedData });
				responseData = response;
			}

			const { errorArray, executeErrors, executeSuccess } = responseData;

			if (!errorArray.length) {
				if (!executeErrors.length) return setShowSuccessComponent(true);

				// executeErrors has values so display it
				setShowErrorsArray(executeErrors ? executeErrors : []);
				setShowSuccessArray(executeSuccess ? executeSuccess : []);
				setShowErrorComponent(true);
			}

			for (const errorObject of errorArray) {
				const { row, col, reason, value } = errorObject;
				const fieldName = columnAlphabetToFieldNameMap[history.location.pathname.split('/')[2]][col];

				validatedData[row][fieldName] = { value, errorMessage: reason };
			}

			setTableRows(validatedData);

			throw new Error('Please fix errors to proceed.');
		} catch (error) {
			console.log('!!!!!Error in submit!!!!!', error);
			errorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	const showCancelConfirmation = () => {
		confirm({
			title: `Are you sure you want to cancel bulk ${history.location.pathname.split('/')[2]} upload?`,
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'No',
			onOk() {
				if (history.location.pathname.split('/')[2] === 'catalogues') {
					history.push('/catalogs');
				} else if (history.location.pathname.split('/')[2] === 'orders') {
					history.push('/');
				} else if (history.location.pathname.split('/')[2] === 'inventory') {
					history.push('/inventory');
				} else if (history.location.pathname.split('/')[2] === 'wsin-upload') {
					history.push('/admin/brands');
				} else if (history.location.pathname.split('/')[2] === 'wsin-map') {
					history.push('/admin/brands');
				} else if (history.location.pathname.split('/')[2] === 'lightning-orders') {
					history.push('/orders');
				}
			}
		});
	};

	if (isExcelPreviewLoading) {
		return <Loading loading={true} text={'This may take a while'} />;
	}

	return (
		<div className={styles.bulkPreviewPage}>
			{showSuccessComponent ? (
				<SuccessComponent previewConfigName={history.location.pathname.split('/')[2]} routeName={routeName} />
			) : showErrorComponent ? (
				<ErrorComponent
					previewConfigName={history.location.pathname.split('/')[2]}
					routeName={routeName}
					errorsArray={errorsArray}
					successArray={successArray}
				/>
			) : (
				tableRows &&
				tableColumns && (
					<Fragment>
						<div className={styles.header}>
							<h5>{`Preview ${history.location.pathname.split('/')[2]} before submitting`}</h5>
							<div className={styles.actionButtons}>
								<Button className={styles.cancelButton} onClick={showCancelConfirmation}>
									Cancel
								</Button>

								<Button type="primary" onClick={handleOnSubmitClick} loading={isLoading}>
									Submit
								</Button>
							</div>
						</div>

						<div className={styles.bulkTable}>
							<BulkTable
								previewConfigName={history.location.pathname.split('/')[2]}
								columns={tableColumns}
								dataSource={tableRows}
								setTableRows={setTableRows}
							/>
						</div>
					</Fragment>
				)
			)}
		</div>
	);
};
