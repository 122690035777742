import dayjs from 'dayjs';

export const searchOptions = [
	{ value: 'shopOrderId', label: 'Order ID' },
	{ value: 'deliveryAwb', label: 'AWB' },
	{ value: 'deliveryPartnerId', label: 'Delivery Partner' },
	{ value: 'paymentMode', label: 'Payment Mode' },
	{ value: 'shopType', label: 'Shop Type' },
	{ value: 'shippingFirstName', label: 'Customer name' },
	{ value: 'shippingPhone', label: 'Customer phone' },
	{ value: 'shippingState', label: 'Delivery State' },
	{ value: 'shippingPincode', label: 'Delivery Pincode' },
	{ value: 'pickupPincode', label: 'Pickup Pincode' },
	{ value: 'pickupState', label: 'Pickup State' }
];

export const searchOptionsForMissedOrder = [
	{ value: 'shopOrderId', label: 'Order ID' },
	{ value: 'shopType', label: 'Shop Type' }
];

export const defaultFilter = {
	startDate: dayjs().subtract(1, 'month'),
	endDate: dayjs(),
	searchOptionType: searchOptions[0].value
};

export const MAX_DOWNLOAD_ORDER_LIMIT = 1000;

export const MAX_DOWNLOAD_LABEL_LIMIT = 30;
export const OrderPageOptions = {
	All: 1,
	Unservicable: 2,
	NDRs: 3,
	returns: 4,
	'missed-order': 5,
	'ndr-customer-requests': 6
};

export const orderPageOptionsWithCommonTable = ['1', '2', '3', '4'];
