import styles from './index.module.scss';
//@ts-ignore
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useState } from 'react';
import { scaleQuantile } from 'd3-scale';
import { COLOR_RANGE, DEFAULT_COLOR, geographyStyle, INDIA_TOPO_JSON } from '../utils/mapUtils';
import ReactTooltip from 'react-tooltip';
import { Gradient } from './Gradient';

interface IInsightsMapData {
	mapData: { id: string; state: string; value: number }[];
}

export const IndiaMap = ({ mapData }: IInsightsMapData) => {
	const [tooltipContent, setTooltipContent] = useState('');

	const gradientData = {
		fromColor: COLOR_RANGE[0],
		toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
		min: 0,
		max: mapData.reduce((max, item) => (item.value > max ? item.value : max), 0)
	};

	const colorScale = scaleQuantile()
		.domain(mapData.map((d) => d.value))
		.range(COLOR_RANGE as any);

	const onMouseEnter = (geo: any, current = { value: 'NA' }) => {
		return () => {
			setTooltipContent(`${geo.properties.name}: ${current.value}`);
		};
	};

	const onMouseLeave = () => {
		setTooltipContent('');
	};

	const PROJECTION_CONFIG = {
		scale: 350,
		center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
	};

	return (
		<div className={styles.indiaMap}>
			<div className={styles.topHeading}>
				<p>State wise count of orders</p>
			</div>
			<Gradient data={gradientData} />
			<ReactTooltip>{tooltipContent}</ReactTooltip>
			<ComposableMap
				style={{ width: '100%', height: 'auto' }}
				projectionConfig={PROJECTION_CONFIG}
				projection="geoMercator"
				width={300}
				height={250}
				data-tip=""
			>
				<Geographies geography={INDIA_TOPO_JSON}>
					{({ geographies }: any) =>
						geographies.map((geo: any) => {
							const current = mapData.find((s) => s.id === geo.id);
							return (
								<Geography
									key={geo.rsmKey}
									geography={geo}
									fill={current ? colorScale(current.value) : DEFAULT_COLOR}
									style={geographyStyle}
									onMouseEnter={onMouseEnter(geo, current as any)}
									onMouseLeave={onMouseLeave}
								/>
							);
						})
					}
				</Geographies>
			</ComposableMap>
			<p className={styles.last30Days}>Last 30 Days</p>
		</div>
	);
};
