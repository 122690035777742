import { Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'shared/contexts/Auth';
import { defaultPageConfig, SHOP_TYPE } from '../../../constants';
import { apiWms } from '../../../shared/api/';
import { DebouncedCompactSearch } from '../../../shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { searchOptions } from './constants';
import styles from './utils.module.scss';

export const useSidebarMenu = () => {
	const [sideBarMenu, setSidebarMenu] = useState([]);
	const { authState } = useContext(AuthContext);

	const fetchSidebarMenu = async () => {
		try {
			const {
				data: { catalogueShopTypes = [] }
			} = await apiWms.post(`/catalogue/shop-types/company/`, {
				companyIds: authState.selectedCompanyIds
			});

			const manualShopExists = catalogueShopTypes.find(({ shopType }) => shopType === SHOP_TYPE.MANUAL);

			if (!manualShopExists) {
				catalogueShopTypes.push({ shopType: SHOP_TYPE.MANUAL, count: 0 });
			}

			const sideBarMenu = catalogueShopTypes.map(({ shopType, count = 0 }) => ({
				key: shopType,
				label: `${shopType} (${count})`,
				icon: 'allSvgIcon'
			}));

			setSidebarMenu(sideBarMenu);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchSidebarMenu();
	}, [authState.selectedCompanyIds]);

	return sideBarMenu;
};

export const topBarConfig = (sidebarMenuItem, setSearch, onDownloadCatalogue, setPageConfig, filter, setFilter) => {
	return {
		row5: [
			<div className={styles.topFilter}>
				<div className={styles.topLeftFilter}>
					<div>
						<DebouncedCompactSearch
							setFilter={setFilter}
							setDefault={() => setPageConfig(defaultPageConfig)}
							defaultSearchOptionType={filter.searchOptionType}
							searchOptions={searchOptions}
							selectStyle={{ minWidth: '120px' }}
							inputStyle={{ width: '200px' }}
						>
							<label>Search By:</label>
						</DebouncedCompactSearch>
					</div>
				</div>

				<div>
					<Button className={styles.buttonRightMargin} type="primary" onClick={() => onDownloadCatalogue(sidebarMenuItem)}>
						Download
					</Button>
				</div>
			</div>
		],
		shouldShowRow5: true
	};
};
