export const commonTrackingLayout = {
	bg: '#DEDDE6',
	sc: '#22dabb',
	pc: '#03045e',
	hl: {},
	links: [],
	hf: 'white'
};

export const primaryColor = Object.freeze({
	first: '#4700d8',
	second: 'black',
	third: '#F9D923',
	fourth: '#E04D01'
});

export const secondaryColor = Object.freeze({
	first: '#251D3A',
	second: '#A85CF9',
	third: '#F55353',
	fourth: '#9900F0'
});

export const backgroundColor = Object.freeze({
	first: '#FFF6EA',
	second: '#F4FCD9',
	third: '#E9E5D6',
	fourth: '#F7F7F7'
});

export const headerLinkColor = Object.freeze({
	first: '#1A1A40',
	second: '#000000',
	third: '#9B0000',
	fourth: '#fff'
});
