import { brandsRoute } from './Brands';
import { companyRoute } from './Companies';
import { lightningRoute } from './Lightning';
import { ndrRoute } from './Ndr';
import { WherehouseRoute } from './Wherehouses';

export const topBar = [
	{
		key: '1',
		route: companyRoute,
		label: 'Companies'
	},
	{
		key: '2',
		route: WherehouseRoute,
		label: 'Wherehouses'
	},

	{
		key: '3',
		route: lightningRoute,
		label: 'Lightning'
	},
	{
		key: '4',
		route: brandsRoute,
		label: 'Brands'
	},
	{
		key: '4',
		route: ndrRoute,
		label: 'NDR'
	}
];
