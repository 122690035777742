export const searchOptions = [
	{ value: 'rfmBadge', label: 'Badge' },
	{ value: 'customerPhone', label: 'Customer Phone' },
	{ value: 'customerEmail', label: 'Customer Email' }
];

export const RFM_BADGE = Object.freeze({
	GOLD: 'Gold',
	SILVER: 'Silver',
	BRONZE: 'Bronze'
});

export const defaultFilter = {
	searchOptionType: searchOptions[0].value,
	searchValue: ''
};
