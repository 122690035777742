import Icon from '@ant-design/icons';
import { Button, Col, Input, message, Modal, notification, Popconfirm, Row, Select, Timeline, Tooltip, Typography } from 'antd';
import { antDesignValidator } from 'helper';
import { groupBy } from 'lodash';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { apiOms } from 'shared/api';
import { fetchOrderInfo, getPincodeDetails, setReadyToShip, updateOrderCustomerAddress } from 'shared/api/orders';
import { Loading } from 'shared/components/Loading';
import envelopeIcon from 'shared/svgs/envelope';
import phoneIcon from 'shared/svgs/phone';
import { sortByDate } from 'shared/utils/date';
import errorHandler from 'shared/utils/errorHandler';
import { getTrackingUrl } from 'utils/delivery/getTrackingUrl';
import { FulfillmentProviders, hyperLocalPartners, ORDER_STATUS, SHOP_TYPE } from '../../../constants';
import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { FormLayout } from '../FormLayout';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import { PrintInvoiceDialog } from '../PrintInvoiceDialog';
import { PrintLabelDialog } from '../PrintLabelDialog';
import OrderActions from './components/OrderActions/OrderActions';
import { OrderTrail } from './components/OrderTrail';
import { OrderWeightChange } from './components/OrderWeightChange/OrderWeightChange';
import { ReassignWarehouse } from './components/ReassignWarehouse/ReassignWarehouse';
import styles from './index.module.scss';
const GoogleMapComponent = React.lazy(() => import('../GoogleMap/GoogleMap'));

export function OrderInfoSlider({
	setReloadOrders,
	onClose,
	orderId,
	companyId,
	showCancelButton = false,
	reloadList = function () {
		return;
	},
	showReassignWarehouse = false,
	showUpdateWeight = false,
	warehouses = [],
	isAdminPanel = false
}) {
	const isMounted = useRef(false);
	const [loading, setLoading] = useState(false);
	const [isReadyToShipButtonLoading, setIsReadyToShipButtonLoading] = useState(false);
	const [reloadOrder, setReloadOrder] = useState(0);
	const [data, setData] = useState(null);
	const [isDeliveredButtonLoading, setIsDeliveredButtonLoading] = useState(false);
	const [isCancelOrderButtonLoading, setIsCancelOrderButtonLoading] = useState(false);
	const [isCustomerFormVisible, setCustomerFormVisibility] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [reverseTrail, setReverseTrail] = useState([]);
	const [forwardTrail, setForwardTrail] = useState([]);
	const [shippingPackageCodes, setShippingPackageCodes] = useState([]);
	const [collapsableForwardTrail, setCollapseableForwardTrail] = useState([]);
	const [collapsableReverseTrail, setCollapseableReverseTrail] = useState([]);
	const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		fetchOrderDetails();
		fetchShippingPackageType();
	}, [orderId, reloadOrder]);

	useEffect(() => {
		if (data) {
			getTrail();
		}
	}, [data]);

	useEffect(() => {
		if (forwardTrail.length) {
			const sortedDate = sortByDate({ data: forwardTrail, fieldName: 'createdAt' });
			const updatedTrail = Object.values(groupBy(sortedDate, 'orderStatus'));
			setCollapseableForwardTrail(updatedTrail);
		}
		if (reverseTrail.length) {
			const sortedDate = sortByDate({ data: reverseTrail, fieldName: 'createdAt' });
			const updatedTrail = Object.values(groupBy(sortedDate, 'orderStatus'));
			setCollapseableReverseTrail(updatedTrail);
		}
	}, [forwardTrail, reverseTrail]);

	function getTrail() {
		setReverseTrail(data.trails.filter((trail) => trail.return === true));
		setForwardTrail(data.trails.filter((trail) => trail.return === false));
	}
	async function fetchOrderDetails() {
		try {
			setLoading(true);
			// For wms panel companyId will not be null
			const { data } = await fetchOrderInfo(orderId, companyId);
			if (!isMounted.current) return;
			setData(data.order);
		} catch (error) {
			if (!isMounted.current) return;
			notification.error({
				message: 'Server Error',
				description: 'Please try again after some time.',
				placement: 'topRight'
			});
			/**
			 * this should be undefined as by default it is null,
			 * and we need to differentiate between initial and
			 * no data found state
			 */
			setData(undefined);
		} finally {
			isMounted.current && setLoading(false);
		}
	}
	async function fetchShippingPackageType() {
		try {
			// For wms panel companyId will not be null
			const {
				data: { data }
			} = await apiOms.get(`/shipping-packages`);
			setShippingPackageCodes(data.map((item) => item.code));
		} catch (error) {
			if (!isMounted.current) return;
			notification.error({
				message: 'Server Error',
				description: 'Please try again after some time.',
				placement: 'topRight'
			});
			setShippingPackageCodes(null);
		}
	}
	async function onClickReadyToShip() {
		try {
			setIsReadyToShipButtonLoading(true);
			await setReadyToShip(orderId);
			if (!isMounted.current) return;
			notification.success({
				message: 'Order Updated',
				description: data.message,
				placement: 'topRight'
			});
			setReloadOrder((v) => v + 1);
		} catch (err) {
			if (!isMounted.current) return;
			errorHandler(err);
		} finally {
			isMounted.current && setIsReadyToShipButtonLoading(false);
			fetchOrderDetails();
		}
	}

	const markOrderAsDelivered = async () => {
		try {
			await apiOms.patch(`/orders/${orderId}/status`, { status: ORDER_STATUS.DELIVERED });
			setReloadOrder((v) => v + 1);
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsDeliveredButtonLoading(false);
			fetchOrderDetails();
		}
	};

	const showCancelOrderButton = () => {
		const { status, shopType } = data;
		return (
			Boolean(status === ORDER_STATUS.PLACED && [SHOP_TYPE.MANUAL, SHOP_TYPE.MANUAL_FBW].includes(shopType)) ||
			(showCancelButton &&
				[
					ORDER_STATUS.PLACED,
					ORDER_STATUS.PROCESSED,
					ORDER_STATUS.READY_TO_SHIP,
					ORDER_STATUS.SHIPPED,
					ORDER_STATUS.IN_TRANSIT,
					ORDER_STATUS.OUT_FOR_DELIVERY
				].includes(status))
		);
	};

	const handleCancelOrder = async () => {
		try {
			setIsCancelOrderButtonLoading(true);
			const queryParams = showCancelButton ? `?companyId=${companyId}` : '';
			await apiOms.patch(`/orders/${orderId}/cancel${queryParams}`);
			setReloadOrder((prevValue) => prevValue + 1);
			reloadList();
		} catch (error) {
			errorHandler(error);
		} finally {
			setIsCancelOrderButtonLoading(false);
			fetchOrderDetails();
		}
	};

	const onCustomerDetailsSubmit = async (values) => {
		try {
			await updateOrderCustomerAddress(data.id, values);
			notification.success({
				message: 'Update Order',
				description: 'updated successfully.',
				placement: 'topRight'
			});
		} catch (error) {
			console.log('!!!!!!Error printed here for onCustomerDetailsSubmit!!!!!!!!!', error);
			errorHandler(error);
		} finally {
			setCustomerFormVisibility(false);
			fetchOrderDetails();
		}
	};

	const onReturnItem = async () => {
		try {
			for (let item of data.items) {
				if (item.returnQuantity > item.quantity) {
					errorHandler(new Error('Return quantity cannot be greater than actual'));
					return;
				}
			}
			data.returnOrderItems = data.items.filter((item) => item.returnQuantity > 0);
			if (data.returnOrderItems.length === 0) {
				errorHandler(new Error('Return quantity cannot be zero'));
				return;
			}
			setLoading(true);
			const res = await apiOms.post('/schedule_dto', data);

			notification.success({ message: res.data.message });
			fetchOrderDetails();
			setIsModalVisible(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);

			errorHandler(error);
		}
	};

	const cancelReturn = async () => {
		try {
			setLoading(true);

			const response = await apiOms.post('/cancel-return', {
				splitOrderId: data.id,
				provider: data.returnPartnerId,
				awb: data.returnAwb
			});
			if (response.data.status === false) notification.error({ message: response.data.message });
			fetchOrderDetails();
			notification.success({ message: response.data.message });
			setLoading(false);
		} catch (error) {
			setLoading(false);

			errorHandler(error);
		}
	};

	const onPincodeChange = async ({ target: { value } }, form) => {
		try {
			if (String(value).length !== 6) {
				return;
			}
			let {
				data: { warehousePinDetails }
			} = await getPincodeDetails(value);

			if (warehousePinDetails && warehousePinDetails.length) {
				// automatically populate city and state based on pincode
				form.setFieldsValue({
					city: warehousePinDetails[0]['district'].replace(/\"/g, '') || warehousePinDetails[0]['District'].replace(/\"/g, ''),
					state: warehousePinDetails[0]['state'].replace(/\"/g, '') || warehousePinDetails[0]['State'].replace(/\"/g, '')
				});
				return;
			}
			return;
		} catch (error) {
			errorHandler(error);
		}
	};

	const updateOrderPackage = async (orderId, selectedValue) => {
		try {
			setLoading(true);
			const { data } = await apiOms.patch(`/order-shipping-packages`, { orderId, selectedValue });
			if (data.status) {
				message.success('Shipping package successfully changed');
			}
		} catch (error) {
			message.error('Server Error');
		} finally {
			setLoading(false);
		}
	};

	const onCancelOrder = async () => {
		try {
			setIsCancelOrderLoading(true);
			const { data: response } = await apiOms.patch(`/orders/${orderId}/cancel`);
			if (response.status) {
				message.success('Shipping package successfully changed');
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setIsCancelOrderLoading(false);
		}
	};

	const handleUnBlockInventory = async () => {
		try {
			setIsCancelOrderLoading(true);
			const { data: response } = await apiOms.patch(`/orders/unblock-inventory/${orderId}`);
			if (response.status) {
				message.success('Inventory unblocked');
			}
		} catch (err) {
			errorHandler(err);
		} finally {
			setReloadOrder((e) => e + 1);
			setIsCancelOrderLoading(false);
		}
	};

	return (
		<InfoSlider onClose={onClose}>
			<div className={styles.main} style={reverseTrail?.length === 0 ? { width: '24rem' } : { width: '28rem' }}>
				{loading && <Loading loading={loading} />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />
				{data ? (
					<Col className={styles.orderInfoContainer}>
						<Row className={styles.paddedRow}>
							<Col span={24}>
								<Row className={styles.row1}>
									<Col span={24}>
										<span className="title">{`Order ${data.shopOrderId || data.externalOrderId}`}</span>
										{data.ewayBill ? <div>E-WayBill: {data.ewayBill}</div> : ''}
									</Col>

									{data?.status === 'PLACED' ? null : (
										<Row>
											<Col span={24}>
												<span className="sub">
													AWB:{' '}
													<a
														target="_blank"
														rel="noreferrer"
														href={getTrackingUrl(FulfillmentProviders.WHEREHOUSE_LIGHTNING, data.deliveryAwb)}
													>
														{data.deliveryAwb}
													</a>
												</span>
											</Col>
										</Row>
									)}
									{data.returnAwb ? (
										<Col span={24}>
											<span className="sub">
												Return AWB:-{' '}
												<a
													target="_blank"
													rel="noreferrer"
													href={getTrackingUrl(data.returnPartnerId, data.returnAwb)}
												>
													{data.returnAwb}
												</a>
											</span>
										</Col>
									) : null}
								</Row>

								<OrderActions orderId={orderId} setReloadOrders={setReloadOrders} />

								<div>
									<Row className={styles.row4}>
										{showUpdateWeight && data.status === ORDER_STATUS.PLACED && <OrderWeightChange data={data} />}
										{showReassignWarehouse && data.status === ORDER_STATUS.PLACED && (
											<ReassignWarehouse
												warehouses={warehouses}
												order={data}
												reloadList={reloadList}
												reloadOrder={setReloadOrder}
											/>
										)}
										<Row className={styles.return_row}>
											<span className="title">Product Details</span>
											{data.status === ORDER_STATUS.DELIVERED || data.status === ORDER_STATUS.DTO_CANCELLED ? (
												<Button size="small" type="primary dashed" onClick={() => setIsModalVisible(true)}>
													Schedule Return
												</Button>
											) : data.status === ORDER_STATUS.DTO_PLACED ? (
												<Popconfirm
													title="Are you sure to cancel this return?"
													onConfirm={cancelReturn}
													onCancel={() => null}
													okText="Yes"
													cancelText="No"
												>
													<Button size="small" type="primary dashed">
														Cancel Return
													</Button>
												</Popconfirm>
											) : null}
											<Modal
												title="Return Items"
												visible={isModalVisible}
												onCancel={() => setIsModalVisible(false)}
												footer={[
													<Button key="submit" type="primary" loading={loading} onClick={onReturnItem}>
														Submit
													</Button>
												]}
											>
												<table style={{ width: '100%' }}>
													<tr>
														<th>Title</th>
														<th>Qty to return</th>
													</tr>
													{data.items.map((item) => (
														<tr>
															<td>{item.title}</td>
															<td>
																<Input
																	type="number"
																	name={item.sku}
																	suffix={` / ${item.quantity}`}
																	defaultValue={0}
																	onChange={(e) => {
																		item.returnQuantity = Number(e.target.value) || 0;
																	}}
																/>
															</td>
														</tr>
													))}
												</table>
											</Modal>
										</Row>
										<Col span={24} className="sub">
											{data.items.map((v) => (
												<Row className="row4-row" key={v.id}>
													<Col span={24}>{`${v.title} (${v.quantity})`}</Col>
													<Col span={24}>
														<Row>
															<Col span={12}>
																<span>{`Rs ${parseFloat(v.finalPrice).toFixed(2)}`}</span>
															</Col>
															<Col span={12}>
																<span>{v.quantity}</span>
															</Col>
														</Row>
													</Col>
												</Row>
											))}
										</Col>
									</Row>

									{isCustomerFormVisible ? (
										<FormLayout
											formTitle={'Edit Customer Details'}
											onSave={(values) => onCustomerDetailsSubmit(values)}
											onClose={() => setCustomerFormVisibility(false)}
											formFields={[
												{
													name: 'firstName',
													id: 'firstName',
													label: 'First Name',
													required: true,
													placeholder: 'Enter First Name'
												},
												{
													name: 'lastName',
													id: 'lastName',
													label: 'Last Name',
													required: true,
													placeholder: 'Enter Last Name'
												},
												{
													name: 'email',
													id: 'email',
													label: 'Email',
													required: true,
													placeholder: 'Enter Email'
												},
												{
													name: 'phone',
													id: 'phone',
													label: 'Phone',
													required: true,
													placeholder: 'Enter Phone',
													rules: [antDesignValidator.phoneNumber]
												},
												{
													name: 'addressLine1',
													id: 'addressLine1',
													label: 'Address Line 1',
													required: true,
													placeholder: 'Enter Address Line 1'
												},
												{
													name: 'addressLine2',
													id: 'addressLine2',
													label: 'Address Line 2',
													required: true,
													placeholder: 'Enter Address Line 2'
												},
												{
													name: 'pincode',
													id: 'pincode',
													label: 'Pincode',
													required: true,
													placeholder: 'Enter Pincode',
													rules: [antDesignValidator.pincode],
													onInputChange: (value, form) => {
														onPincodeChange(value, form);
													}
												},
												{
													name: 'city',
													id: 'city',
													label: 'City',
													required: true,
													placeholder: 'Enter City'
												},
												{
													name: 'state',
													id: 'state',
													label: 'State',
													required: true,
													placeholder: 'Enter State'
												}
											]}
											initialValues={{
												firstName: data.customerAddress?.firstName,
												lastName: data.customerAddress?.lastName,
												email: data?.shippingEmail,
												phone: data?.shippingPhone || data?.shippingAddress?.phone || data?.customerAddress?.phone,
												addressLine1: data.customerAddress?.addressLine1,
												addressLine2: data.customerAddress?.addressLine2,
												city: data.customerAddress?.city,
												state: data.customerAddress?.state,
												pincode: data.customerAddress?.pincode
											}}
										/>
									) : (
										<>
											<Row className={styles.row4}>
												<Col span={24}>
													<Row>
														<Col span={18}>
															<span className="title">Customer Details</span>
														</Col>
														{(data.status === ORDER_STATUS.PLACED ||
															data.status === ORDER_STATUS.UNSERVICEABLE) && (
															<Col className={styles.icon} span={6}>
																<Button
																	type="primary"
																	size="small"
																	style={{ width: '60%' }}
																	onClick={(e) => {
																		e.stopPropagation();
																		setCustomerFormVisibility(true);
																	}}
																>
																	Edit
																</Button>
															</Col>
														)}
													</Row>
												</Col>
												<Col span={24} className="sub">
													<Row className="row4-row">
														<Col span={12}>
															<span>{`${data.customerAddress.firstName} ${data.customerAddress.lastName}`}</span>
														</Col>
														<Col span={12} className="right-text ellipsis">
															<Icon component={() => phoneIcon} />{' '}
															<span>
																{data?.shippingPhone ||
																	data?.shippingAddress?.phone ||
																	data?.customerAddress?.phone}
															</span>
														</Col>
													</Row>
													<Row className="row4-row">
														<Col span={12}>
															<span>{`${data.customerAddress.addressLine1}, ${data.customerAddress.addressLine2}, ${data.customerAddress.city}`}</span>
															<br />
															<span>Pincode: {data.customerAddress.pincode}</span>
														</Col>
														<Col span={12} className="right-text ellipsis">
															<Icon component={() => envelopeIcon} />{' '}
															<Tooltip placement="topRight" title={data?.shippingEmail || '--'}>
																<span>{data?.shippingEmail || <span className="ml-2">-</span>}</span>
															</Tooltip>
														</Col>
													</Row>
												</Col>
											</Row>

											<Row className={styles.row4}>
												<Col span={24}>
													<Row>
														<Col span={24}>
															<span className="title">Shipping Package Type</span>
														</Col>
														{data.status === ORDER_STATUS.PLACED ? (
															<Col className={styles.icon} span={6}>
																<Select
																	defaultValue={data.shippingPackageCode}
																	onChange={(selectedCode) => updateOrderPackage(data.id, selectedCode)}
																>
																	{shippingPackageCodes.map((code) => (
																		<Select.Option key={code}>{code}</Select.Option>
																	))}
																</Select>
															</Col>
														) : (
															<Col span={12} className="sub">
																<h6>{data.shippingPackageCode}</h6>
															</Col>
														)}
													</Row>
												</Col>
											</Row>
										</>
									)}
									{hyperLocalPartners.some((localData) => localData === data.deliveryPartnerId) && (
										<Suspense fallback={<div>Loading.....</div>}>
											<GoogleMapComponent orderId={orderId} />
										</Suspense>
									)}
								</div>

								<Row className={styles.row3}>
									{data.status === ORDER_STATUS.PLACED && (
										<Col span={24} className="mt-3">
											<Button
												type="primary"
												size="middle"
												shape="round"
												onClick={onClickReadyToShip}
												loading={isReadyToShipButtonLoading}
											>
												READY TO SHIP
											</Button>
										</Col>
									)}

									{[ORDER_STATUS.READY_TO_SHIP, ORDER_STATUS.OUT_FOR_DELIVERY, ORDER_STATUS.IN_TRANSIT].includes(
										data.status
									) &&
										data.deliveryPartnerId === FulfillmentProviders.CLICKPOST && (
											<Col span={24} className="mt-3">
												<Button
													type="danger"
													size="middle"
													shape="round"
													onClick={onCancelOrder}
													loading={isCancelOrderLoading}
												>
													CANCEL ORDER
												</Button>
											</Col>
										)}
									{showCancelOrderButton() && isAdminPanel && (
										<Col span={24} className="mt-3">
											<Popconfirm
												title="Are you sure you want to unblock Inventory?"
												okText="Yes"
												cancelText="No"
												onConfirm={handleUnBlockInventory}
											>
												<Button type="danger" size="middle" shape="round" loading={isCancelOrderLoading}>
													Unblock Inventry
												</Button>
											</Popconfirm>
										</Col>
									)}

									{data.status === ORDER_STATUS.READY_TO_SHIP && data.shopType === SHOP_TYPE.MANUAL_FBW && (
										<Col span={24} className="mt-3">
											<Button
												type="primary"
												size="middle"
												shape="round"
												onClick={markOrderAsDelivered}
												loading={isDeliveredButtonLoading}
											>
												Mark as Delivered
											</Button>
										</Col>
									)}

									{showCancelOrderButton() && (
										<Col span={24} className="mt-3">
											<Popconfirm
												title="Are you sure you want to cancel this order?"
												okText="Yes"
												cancelText="No"
												onConfirm={handleCancelOrder}
											>
												<Button size="middle" shape="round" type="text" danger loading={isCancelOrderButtonLoading}>
													Cancel Order
												</Button>
											</Popconfirm>
										</Col>
									)}
								</Row>

								<Typography.Title level={4}>Timeline</Typography.Title>
								<Row className={styles.row2}>
									<Col span={12}>
										{collapsableForwardTrail.map((trailDetails) => (
											<Timeline>
												<OrderTrail
													key={trailDetails[0].id}
													trailDetails={trailDetails[0]}
													fetchOrderDetails={fetchOrderDetails}
													showInfoIcon={false}
													data={trailDetails}
												/>
											</Timeline>
										))}
									</Col>
									<Col span={12}>
										{collapsableReverseTrail?.length > 0 &&
											collapsableReverseTrail.map((trailDetails) => (
												<Timeline>
													<OrderTrail
														key={trailDetails[0].id}
														trailDetails={trailDetails[0]}
														fetchOrderDetails={fetchOrderDetails}
														showInfoIcon={false}
														data={trailDetails}
													/>
												</Timeline>
											))}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className={styles.row5}>
							<Col span={12}>
								<PrintLabelDialog orderDetails={data} />
							</Col>
							<Col span={12}>
								<PrintInvoiceDialog orderDetails={data} />
							</Col>
						</Row>
					</Col>
				) : (
					data === undefined && <div className="noDataDefaultComponent">Nothing to do here</div>
				)}
			</div>
		</InfoSlider>
	);
}
