import { Col, Select } from 'antd';
import dayjs from 'dayjs';
import { DebouncedCompactSearch } from 'shared/components/DebouncedCompactSearch/DebouncedCompactSearch';
import { JOURNEY_STATUS } from '../constants';
import { searchOptions } from './constants';
import styles from './index.module.scss';

export function topFilterUtil({ filter, setFilter, setPageConfigDefault }) {
	const setDate = (startDate, endDate) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, startDate, endDate }));
	};

	const handleTripStatusChange = (status) => {
		setPageConfigDefault();
		setFilter((prevFilter) => ({ ...prevFilter, status }));
	};

	return {
		row1: [
			{
				label: 'FILTER BY DATE',
				onChange: (_dates, dateStrings) => setDate(...dateStrings),
				type: 'range',
				allowClear: false,
				value: [dayjs(filter.startDate), dayjs(filter.endDate)],
				defaultValue: [dayjs(filter.startDate), dayjs(filter.endDate)],
				ranges: {
					Today: [dayjs(), dayjs()],
					'Last 1 Week': [dayjs().subtract(1, 'w'), dayjs()],
					'Last 1 Month': [dayjs().subtract(1, 'M'), dayjs()],
					'Last 3 Months': [dayjs().subtract(3, 'M'), dayjs()],
					'Last 1 Year': [dayjs().subtract(1, 'y'), dayjs()]
				}
			}
		],
		row1children: [
			<Col>
				<div className={styles.searchContainer}>
					<DebouncedCompactSearch
						defaultSearchOptionType={filter.searchOptionType}
						searchOptions={searchOptions}
						setFilter={setFilter}
						setDefault={setPageConfigDefault}
						selectStyle={{ minWidth: '150px' }}
					>
						<label>Search By:</label>
					</DebouncedCompactSearch>
				</div>
			</Col>,
			<div className={styles.orderStatusFilter}>
				<label>Status:</label>
				<Select
					mode="multiple"
					placeholder="Select Order Status"
					maxTagCount="responsive"
					style={{ width: '100%' }}
					allowClear
					value={filter.status}
					onChange={handleTripStatusChange}
				>
					{Object.keys(JOURNEY_STATUS).map((tripStatus) => {
						return <Select.Option key={tripStatus}>{tripStatus}</Select.Option>;
					})}
				</Select>
			</div>
		],
		row2: []
	};
}
