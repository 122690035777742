import { Button, Col, Form, notification, Row, Tabs, Timeline } from 'antd';
import { antDesignValidator } from 'helper';
import React, { useEffect, useState } from 'react';
import { addInventory, adjustInventory, getInventoryDetail } from 'shared/api/inventory';
import { Loading } from 'shared/components/Loading';
import { sortByDate } from 'shared/utils/date';
import errorHandler from 'shared/utils/errorHandler';
import { ReactComponent as CloseIcon } from '../../svgs/closeIcon.svg';
import { FormLayout } from '../FormLayout';
import { InfoSlider } from '../InfoSlider/InfoSlider';
import { InventoryTrail } from './components/inventoryTrail';
import styles from './index.module.scss';

const { TabPane } = Tabs;

export function InventoryInfoSlider({ onClose, inventoryId, reloadList }) {
	const [loading, setLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [reloadInventoryDetail, setReloadInventoryDetail] = useState(0);
	const [inventoryData, setInventoryData] = useState(null);
	const [inventoryTrailData, setInventoryTrailData] = useState(null);
	const [actionName, setActionName] = useState(null);
	const [totalInbound, setTotalInbound] = useState(0);

	async function handleAddInventory(values) {
		try {
			setButtonLoading(true);
			const { data } = await addInventory({
				inboundAccepted: values.inboundAccepted,
				inboundRejected: values.inboundRejected,
				remark: values.remark,
				inventoryId: inventoryId
			});
			if (data.status) {
				notification.success({
					message: 'Inventory added',
					description: data.message,
					placement: 'topRight'
				});
				setReloadInventoryDetail((value) => value + 1);
				reloadList();
			} else {
				throw new Error(data.message || 'Could not add inventory');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setButtonLoading(false);
			setTotalInbound(0);
		}
	}

	async function handleAdjustInventory(values) {
		try {
			setButtonLoading(true);
			if (actionName === 'Remove') {
				values.quantity = String(Number(-Number(values.quantity)));
			}

			const { data } = await adjustInventory({
				quantity: values.quantity,
				remark: values.remark,
				inventoryId: inventoryId
			});
			if (data.status) {
				notification.success({
					message: 'Inventory adjusted',
					description: data.message,
					placement: 'topRight'
				});
				setReloadInventoryDetail((value) => value + 1);
				reloadList();
			} else {
				throw new Error(data.message || 'Could not adjust inventory');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setButtonLoading(false);
		}
	}

	useEffect(() => {
		async function fetchInventoryDetails() {
			try {
				setLoading(true);
				const { data } = await getInventoryDetail(inventoryId);
				setInventoryData({ wsin: data?.wsin });
				setInventoryTrailData(data?.trail);
			} catch (error) {
				errorHandler(error);
				setInventoryData(null);
				setInventoryTrailData(null);
			} finally {
				setLoading(false);
			}
		}

		fetchInventoryDetails();
	}, [inventoryId, reloadInventoryDetail]);

	return (
		<InfoSlider onClose={onClose}>
			<div className={`${styles.main} scrollable`}>
				{loading && <Loading />}
				<CloseIcon title="Close" className="closeBtn" onClick={onClose} />
				{true ? (
					<Col className={styles.inventoryInfoContainer}>
						<Row className={styles.paddedRow}>
							<Col span={24}>
								<Row className={styles.row1}>
									<Col span={24}>
										<div className={styles.updateForm}>
											<div className={styles.title}>{inventoryData?.wsin}</div>
											<Tabs defaultActiveKey="1" type="card">
												<TabPane tab="Inventory Inbound" key="1">
													<FormLayout
														formTitle={'Inventory Inbound'}
														onSave={handleAddInventory}
														isClose={false}
														formFields={[
															{
																fieldType: 'custom',
																component: <h6>Total Inbound: {totalInbound === 0 ? '' : totalInbound}</h6>
															},
															{
																name: 'inboundAccepted',
																id: 'inboundAccepted',
																label: 'Inbound Accepted',
																rules: [
																	{
																		required: true,
																		message: '👆 Inbound accepted is empty'
																	},
																	antDesignValidator.positiveNumber
																],
																placeholder: 'Enter Inbound accepted',
																onInputChange: ({ target: { value } }, form) => {
																	setTotalInbound(
																		Number(form.getFieldValue('inboundAccepted') || 0) +
																			Number(form.getFieldValue('inboundRejected') || 0)
																	);
																}
															},
															{
																name: 'inboundRejected',
																id: 'inboundRejected',
																label: 'Inbound Rejected',
																rules: [
																	{
																		required: true,
																		message: '👆 Inbound rejected is empty'
																	},
																	antDesignValidator.positiveNumber
																],
																placeholder: 'Enter Inbound rejected',
																onInputChange: async ({ target: { value } }, form) => {
																	setTotalInbound(
																		Number(form.getFieldValue('inboundAccepted') || 0) +
																			Number(form.getFieldValue('inboundRejected') || 0)
																	);
																}
															},
															{
																name: 'remark',
																id: 'remark',
																label: 'Remark',
																rules: [
																	{
																		required: true,
																		message: 'Remark is empty'
																	}
																],
																placeholder: 'Enter remark'
															}
														]}
														customSubmitComponent={
															<Form.Item>
																<Button
																	type="primary"
																	shape="round"
																	size="large"
																	htmlType="submit"
																	loading={buttonLoading}
																>
																	Add
																</Button>
															</Form.Item>
														}
													/>
												</TabPane>
												<TabPane tab="Inventory adjustment" key="2">
													<FormLayout
														formTitle={'Inventory Adjustment'}
														onSave={handleAdjustInventory}
														isClose={false}
														onClose={() => {}}
														formFields={[
															{
																name: 'quantity',
																id: 'quantity',
																label: 'Quantity',
																rules: [
																	{
																		required: true,
																		message: '👆 Quantity is empty'
																	},
																	antDesignValidator.positiveNumber
																],
																placeholder: 'Enter Quantity'
															},
															{
																name: 'remark',
																id: 'remark',
																label: 'Reason',
																rules: [
																	{
																		required: true,
																		message: '👆 Reason is empty'
																	}
																],
																placeholder: 'Enter reason'
															}
														]}
														customSubmitComponent={
															<Form.Item className={styles.btn}>
																<Button
																	type="primary"
																	shape="round"
																	size="large"
																	htmlType="submit"
																	loading={buttonLoading}
																	style={{ marginRight: '1rem' }}
																	onClick={() => setActionName('Add')}
																>
																	Add
																</Button>
																<Button
																	danger
																	type="default"
																	shape="round"
																	size="large"
																	htmlType="submit"
																	loading={buttonLoading}
																	onClick={() => setActionName('Remove')}
																>
																	Remove
																</Button>
															</Form.Item>
														}
													/>
												</TabPane>
											</Tabs>
										</div>
									</Col>
								</Row>
								<Row className={styles.row2}>
									<Col span={24}>
										<span className="title">Inventory Trail</span>
									</Col>
								</Row>
								{inventoryTrailData && inventoryTrailData.length > 0 ? (
									<Row className={styles.row3}>
										<Col span={24}>
											<Timeline className={styles.timeline}>
												{sortByDate({
													data: inventoryTrailData,
													fieldName: 'createdAt',
													sortFilter: 'DESC'
												}).map((trailDetails, index) => (
													<InventoryTrail trailDetails={trailDetails} key={index} />
												))}
											</Timeline>
										</Col>
									</Row>
								) : (
									<div className={styles.noInventoryText}>Nothing to do here</div>
								)}
							</Col>
						</Row>
					</Col>
				) : (
					!loading && <div className={styles.noDefaultText}>Nothing to do here</div>
				)}
			</div>
		</InfoSlider>
	);
}
